import { MediaType } from "shared-types";

export function getMediaTypeByMimeType(mimeType: string) {
  const mimeTypeLower = mimeType.split("/")[0].toLowerCase();

  switch (mimeTypeLower) {
    case MediaType.Video.toLowerCase():
      return MediaType.Video;
    case MediaType.Audio.toLowerCase():
      return MediaType.Audio;
    default:
      return MediaType.Video;
  }
}
