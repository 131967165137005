import React from "react";
import cx from "classnames";
import { useField } from "formik";

import "./CheckboxFormik.scss";

interface ISingleCheckboxProps {
  name: string;
  valueChecked: any;
  valueUnchecked: any;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelPostFix?: number;
  annex?: JSX.Element;
  onCheck?: (checked: boolean) => void;
  halfChecked?: boolean;
  white?: boolean;
}

// used when you want a checkbox to act like a radio button with just 2 values, on or off
export const SingleCheckbox: React.FC<ISingleCheckboxProps> = ({
  name,
  valueChecked,
  valueUnchecked,
  className,
  disabled,
  label,
  labelPostFix,
  annex,
  onCheck,
  halfChecked,
  white,
}) => {
  const [field, meta, helpers] = useField(name);
  const invalid = Boolean(meta.error) && meta.touched;
  const checked = field.value === valueChecked;

  React.useEffect(() => {
    if (onCheck) {
      onCheck(Boolean(checked));
    }
  }, [checked]);

  const boxClassName = cx("c-checkbox-formik__box", {
    "c-checkbox-formik__box--disabled": disabled,
    "c-checkbox-formik__box--half-checked": halfChecked,
    "c-checkbox-formik__box--white": white,
    "c-checkbox-formik__box--error": invalid,
  });
  const labelClassName = cx("c-checkbox-formik__label", {
    "c-checkbox-formik__label--disabled": disabled,
  });
  const labelPostfixClassName = cx("c-checkbox-formik__label-postfix", {
    "c-checkbox-formik__label-postfix--disabled": disabled,
  });

  return (
    <div
      className={cx("c-checkbox-formik", className, {
        "c-checkbox-formik--disabled": disabled,
      })}
    >
      <div className="c-checkbox-formik__wrapper">
        <input
          type="checkbox"
          {...field}
          checked={checked}
          onChange={(e) => {
            const { checked } = e.target;
            helpers.setValue(checked ? valueChecked : valueUnchecked);
          }}
        />
        <div className={boxClassName} />
        {label && (
          <label className={labelClassName} htmlFor={field.name}>
            {label}
          </label>
        )}
        {labelPostFix !== undefined && (
          <div className={labelPostfixClassName}>({labelPostFix})</div>
        )}
      </div>
      {annex && <div className="c-checkbox-formik__annex">{annex}</div>}
    </div>
  );
};
