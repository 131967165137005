import React, { ChangeEvent, FC, useContext, useEffect, useRef } from "react";
import { useTranslation } from "i18n";
import { BillingContext, ToggleSwitch } from "../../../../../../../index";
import cx from "classnames";

interface Props {
  isChecked?: boolean;
  onCheck: (isChecked: boolean) => void;
}

const MembershipHeader: FC<Props> = ({ isChecked, onCheck }) => {
  const { isHiddenPeriodToggle } = useContext(BillingContext);
  const ref = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) =>
        e.target.classList.toggle(
          "shadow-[0px_4px_4px_0px_#1c1c1c40]",
          e.intersectionRatio < 1
        ),
      { threshold: [1] }
    );

    if (ref?.current && !isHiddenPeriodToggle) observer.observe(ref?.current);
  }, [ref?.current, isHiddenPeriodToggle]);

  const onToggleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    onCheck(event.target.checked);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      ref={ref}
      className={cx(
        "flex items-center justify-center md:justify-between px-5 py-[10px] mb-[10px] bg-white",
        {
          "sticky top-[-1px] z-50": !isHiddenPeriodToggle,
        }
      )}
    >
      <h2 className="hidden md:block text-[26px] font-bold">
        {t("common:billing.section.membership", { position: "1." })}
      </h2>
      {!isHiddenPeriodToggle && (
        <div className="flex items-center gap-2">
          <div className="font-semibold text-color-neutral-one text-[1.1rem]">
            {t("common:billing.payMonthly")}
          </div>
          <ToggleSwitch checked={isChecked} onChange={onToggleSwitch} />
          <div className="flex items-center gap-[4px] font-semibold text-[1.1rem]">
            {t("common:billing.payYearly")}
            <div className="px-[4px] px-[2px] text-green-grass bg-green-pale text-[0.78rem] rounded-[2px] h-[22px] leading-[22px]">
              {t("common:billing.bestDeal")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipHeader;
