import { HALManager, SettingManager, UserManager } from "core";
import diff from "json-patch-gen";
import { authorizationService } from "shared-auth";
import { ISortArrayConfig, sortArray } from "shared/utils/array";
import {
  HttpOperationError,
  InvalidOperationError,
} from "shared/utils/exceptions";
import { JsonPatchOrderGeneratorWrapper, ObjectPath } from "shared/utils/http";

import {
  BasicInfo,
  BasicInfoLocation,
  FacetListItem,
  IEmail,
  IProfileViewResponse,
  Measurement,
  MeasurementTypes,
  Profile,
  Settings,
  WhoAmI,
} from "models";
import { HttpClient } from "./HttpClient";
import LocationService from "./LocationService";
import { getApiUrl, getPortalApiUrl } from "./UrlBuilder";

export class ProfileService {
  private httpClient: HttpClient = new HttpClient();

  public async whoAmI() {
    const urlRequest = getApiUrl("/whoami");

    const response = await this.httpClient.get<WhoAmI>(urlRequest);

    return response.object;
  }

  public async verifySignInSession() {
    const urlRequest = getPortalApiUrl("account/whoami");

    const {
      object: { AllowLogin },
    } = await this.httpClient.get<any>(urlRequest, { credentials: "include" });

    return Boolean(AllowLogin);
  }

  public async getProfile() {
    const urlRequest = getApiUrl(HALManager.getProfileUrl());

    const response = await this.httpClient.get<Profile>(urlRequest);

    return response.object;
  }

  public async getProfileByViewPin(viewPin: string) {
    const urlRequest = getApiUrl(HALManager.getProfileByViewPinUrl(viewPin));
    const accessToken = authorizationService.getAuthorizationHeader();

    const authHeaders = accessToken
      ? { Authorization: accessToken }
      : undefined;

    try {
      const { object: profile } =
        await this.httpClient.get<IProfileViewResponse>(urlRequest, {
          headers: authHeaders,
        });

      return profile;
    } catch (error) {
      throw new InvalidOperationError(
        "Profile was not found",
        { viewPin },
        error
      );
    }
  }

  public async shareProfile(email: IEmail) {
    const urlRequest = getApiUrl(HALManager.shareProfile(UserManager.userId));
    const requestParams = { body: JSON.stringify(email) };

    try {
      await this.httpClient.post(urlRequest, requestParams);
    } catch (error) {
      throw new InvalidOperationError(
        "Sending profile email failed",
        { email },
        error
      );
    }
  }

  public async getSettings() {
    const urlRequest = getApiUrl(HALManager.getSettingsUrl(UserManager.userId));

    const response = await this.httpClient.get<Settings>(urlRequest);

    return response.object;
  }

  public async patchSettings(settingDiff: any) {
    const urlRequest = getApiUrl(
      HALManager.patchSettingsUrl(UserManager.userId)
    );

    const requestParams = { body: JSON.stringify(settingDiff) };

    const response = await this.httpClient.patch<Settings>(
      urlRequest,
      requestParams
    );

    return response.object;
  }

  public async getBasicInfo(language: string) {
    const urlRequest = getApiUrl(
      HALManager.getBasicInfoUrl(UserManager.userId)
    );
    let basicInfo: BasicInfo;

    try {
      const { object } = await this.httpClient.get<BasicInfo>(urlRequest);
      basicInfo = object;
    } catch (error) {
      throw new HttpOperationError(
        urlRequest,
        "Getting basic info has been failed",
        { language },
        error
      );
    }

    return this.localizeBasicInfo(basicInfo, language);
  }

  public async localizeBasicInfo(basicInfo: BasicInfo, language: string) {
    basicInfo.locations = sortArray(basicInfo.locations, [
      { expression: (location) => location.position || 0, order: "asc" },
    ]);

    basicInfo.facets = sortArray(basicInfo.facets, [
      { expression: (facet) => facet.position || 0, order: "asc" },
    ]).map((facet, index) => ({ ...facet, position: index }));

    const googlePlaceIds = basicInfo.locations.map(
      (location) => location.googlePlaceId
    );

    let updatedLocations: Omit<BasicInfoLocation, "position">[] = [];

    try {
      if (googlePlaceIds.length > 0) {
        updatedLocations = await LocationService.getLocationsByGooglePlaceIds(
          googlePlaceIds,
          language
        );
      }
    } catch (error) {
      throw new InvalidOperationError(
        "Getting location has been failed failed",
        { language },
        error
      );
    }

    basicInfo.locations = updatedLocations.map((updatedLocation, index) => ({
      ...updatedLocation,
      position: basicInfo.locations[index].position,
    }));

    return basicInfo;
  }

  public async patchBasicInfo(
    originalModel: BasicInfo,
    updatedModel: BasicInfo
  ) {
    const difference = this.getBasicInfoDiff(originalModel, updatedModel);

    let updateBasicInfo: BasicInfo;

    if (difference.length > 0) {
      const urlRequest = getApiUrl(
        HALManager.patchBasicInfoUrl(UserManager.userId)
      );
      const requestParams = { body: JSON.stringify(difference) };

      try {
        const response = await this.httpClient.patch<BasicInfo>(
          urlRequest,
          requestParams
        );
        updateBasicInfo = response.object;
      } catch (error) {
        throw new HttpOperationError(
          urlRequest,
          "Basic info Patching has been failed",
          { difference },
          error
        );
      }
    } else {
      updateBasicInfo = originalModel;
    }

    return updateBasicInfo;
  }

  public async updateSettings(updateSettings: (data: Settings) => any) {
    const settings = await this.getSettings();
    const difference = diff(settings, {
      ...settings,
      ...updateSettings(settings),
    });

    if (difference.length > 0) {
      SettingManager.settings = await this.patchSettings(difference);
    }
  }

  public getBasicInfoDiff(originalModel: BasicInfo, updatedModel: BasicInfo) {
    if (!updatedModel || !originalModel) {
      return [];
    }

    const facetsSortSetting: ISortArrayConfig<FacetListItem>[] = [
      {
        expression: (facet) => facet.facetId,
        order: "asc",
      },
    ];

    const measurementsSortSetting: ISortArrayConfig<Measurement>[] = [
      {
        expression: (measurement) =>
          Object.keys(MeasurementTypes).indexOf(measurement.measurementType),
        order: "asc",
      },
    ];

    const locationsSortSetting: ISortArrayConfig<BasicInfoLocation>[] = [
      {
        expression: (location) => location.position,
        order: "asc",
      },
    ];

    updatedModel.facets = sortArray(updatedModel.facets, facetsSortSetting);
    originalModel.facets = sortArray(originalModel.facets, facetsSortSetting);

    updatedModel.measurements = sortArray(
      updatedModel.measurements,
      measurementsSortSetting
    );
    originalModel.measurements = sortArray(
      originalModel.measurements,
      measurementsSortSetting
    );

    updatedModel.locations = sortArray(
      updatedModel.locations,
      locationsSortSetting
    );
    originalModel.locations = sortArray(
      originalModel.locations,
      locationsSortSetting
    );

    const patchWrapper = new JsonPatchOrderGeneratorWrapper();
    const identificationOptions = [
      {
        equals: (left: Measurement, right: Measurement) =>
          left.measurementType === right.measurementType,
        path: ObjectPath.from(["measurements"]),
      },
      {
        equals: (left: FacetListItem, right: FacetListItem) =>
          left.facetId === right.facetId,
        path: ObjectPath.from(["facets"]),
      },
      {
        equals: (left: BasicInfoLocation, right: BasicInfoLocation) =>
          left.googlePlaceId === right.googlePlaceId,
        path: ObjectPath.from(["locations"]),
      },
    ];

    return patchWrapper.generate(
      originalModel,
      updatedModel,
      identificationOptions
    );
  }
}

export default new ProfileService();
