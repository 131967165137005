import React from "react";
import { ScrollManager } from "./ScrollManager";

export interface IWithScrollManagerInjectedProps {
  scrollManager: ScrollManager;
}

export interface IWithScrollManagerProps
  extends IWithScrollManagerInjectedProps {
  scrollingContainerId: string;
}

type HocProps<TProps extends object> = Pick<
  TProps,
  Exclude<keyof TProps, keyof IWithScrollManagerInjectedProps>
>;

export default function withScrollManager<
  TProps extends IWithScrollManagerProps
>(
  WrappedComponent: React.ComponentType<TProps>
): React.ComponentType<HocProps<TProps>> {
  return class extends React.Component<HocProps<TProps>> {
    public render() {
      return (
        <WrappedComponent
          {...(this.props as TProps)}
          scrollManager={this.scrollManager}
        />
      );
    }

    public scrollManager = new ScrollManager(this.props.scrollingContainerId);
  };
}
