import React from "react";

import "./ProgressBar.scss";

export interface IProgressBarProps {
  percent: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ percent }) => {
  return (
    <div className="c-progress-bar">
      <div
        className="c-progress-bar__filler"
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};
