import React from "react";
import cx from "classnames";

import "./Checkbox.scss";

export interface IControllableCheckboxProps {
  name: string;
  value: any;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelPostFix?: number;
  annex?: JSX.Element;
  onCheck?: (checked: boolean) => void;
  checked?: boolean;
  halfChecked?: boolean;
  white?: boolean;
  invalid?: boolean;
}

export const ControllableCheckbox: React.FC<IControllableCheckboxProps> = ({
  name,
  value,
  checked,
  className,
  disabled,
  label,
  labelPostFix,
  annex,
  onCheck,
  halfChecked,
  white,
  invalid,
}) => {
  const boxClassName = cx("c-checkbox-formik__box", {
    "c-checkbox-formik__box--disabled": disabled,
    "c-checkbox-formik__box--half-checked": halfChecked,
    "c-checkbox-formik__box--white": white,
    "c-checkbox-formik__box--error": invalid,
  });
  const labelClassName = cx("c-checkbox-formik__label", {
    "c-checkbox-formik__label--disabled": disabled,
  });
  const labelPostfixClassName = cx("c-checkbox-formik__label-postfix", {
    "c-checkbox-formik__label-postfix--disabled": disabled,
  });

  return (
    <div
      className={cx("c-checkbox-formik", className, {
        "c-checkbox-formik--disabled": disabled,
      })}
    >
      <div className="c-checkbox-formik__wrapper">
        <input
          value={value}
          type="checkbox"
          checked={checked}
          name={name}
          onChange={(e) => onCheck?.(e.currentTarget.checked)}
        />
        <div className={boxClassName} />
        {label && (
          <label className={labelClassName} htmlFor={name}>
            {label}
          </label>
        )}
        {labelPostFix !== undefined && (
          <div className={labelPostfixClassName}>({labelPostFix})</div>
        )}
      </div>
      {annex && <div className="c-checkbox-formik__annex">{annex}</div>}
    </div>
  );
};
