import authorizationService from "../services/authorization/authorizationService";
import { UserAttributes } from "../services/authorization";

// meaning: if identity provider === "ipcheck" then isCastingBBC
// has to be understandable way, fe new role,
const ipCheck = "ipcheck";

export function checkIsCastingBBC() {
  return (
    authorizationService.getUserAttribute(UserAttributes.IdentityProvider) ===
    ipCheck
  );
}
