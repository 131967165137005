import { number } from "yup";
import { i18n } from "i18n";

export default function playingAgeValidationSchema(fieldName: string) {
  const direction = fieldName.toLowerCase().includes("from") ? "From" : "To";
  return {
    [fieldName]: number()
      .nullable()
      .required(
        i18n.t("common:validation.error.entryRequired", {
          fieldName: i18n.t(`common:label.playingAge${direction}`),
        })
      ),
  };
}
