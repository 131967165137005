const generateQueryParams = (
  queryAuthParams: Record<string, any> | undefined,
  url: string,
  additionalParams?: Record<string, any>
) => {
  if (queryAuthParams) {
    const {
      onlineApplicationId: id,
      recurlyAccountId: accountId,
      ...qparams
    } = queryAuthParams;
    const qs = new URLSearchParams({
      id,
      accountId,
      ...additionalParams,
      ...qparams,
    });
    return `${url}?${qs}`;
  }

  return url;
};

export default generateQueryParams;
