export const viewSuggestions = {
  "ViewSuggestions.PerformerCard": "performer-card",
  "ViewSuggestions.sections": "role-suggestion-sections",
  "ViewSuggestions.PerformerCard.checkbox": "perfomer-card-checkbox",
  "ViewSuggestions.PerformerCard.showreel": "perform-card-showreel",
  "ViewSuggestions.PerformerCard.audioclip": "perform-card-audioclip",
  "ViewSuggestions.videoplayer": "video-player",
  "ViewSuggestions.sections.markRoleAsCast": "mark-role-as-cast",
  "ViewSuggestions.sections.markAllRolesAsCast": "mark-all-roles-as-cast",
};
