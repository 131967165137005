import { INavLinks } from "../../../Footer/Footer";

export const getFooterLinks = () => ({
  navigationLinks: {
    footerLinks1: [
      {
        href: "/get-in-touch",
        title: "common:nav.links.contact",
      },
      {
        href: "https://www.spotlight.com/join-us/our-partners/",
        title: "common:nav.links.partners",
      },
      {
        href: "https://www.spotlight.com/the-small-print/new-on-spotlight/",
        title: "common:nav.links.newInfo",
      },
      {
        href: "https://www.spotlight.com/join-us/how-we-support-our-industry/",
        title: "common:nav.links.industrySupport",
      },
      {
        href: "https://www.spotlight.com/the-small-print/what-we-offer-our-members/",
        title: "common:nav.links.memberBenefits",
      },
      {
        href: "https://www.spotlight.com/the-small-print/promoting-inclusivity-in-our-industry/",
        title: "common:nav.links.inclusivity",
      },
    ],
    footerLinks2: [
      {
        href: "https://calendar.spotlight.com/",
        title: "common:nav.links.gradPerformanceCalendar",
      },
      {
        href: "https://www.spotlight.com/news-and-advice/the-essentials/the-spotlight-guide-to-basic-video-editing/",
        title: "common:nav.links.showreelEditing",
      },
      {
        href: "https://app.spotlight.com/contacts",
        title: "common:nav.contacts",
      },
      {
        href: "https://status.spotlight.com/",
        title: "common:nav.links.websiteStatus",
      },
    ],
    footerLinks3: [
      {
        href: "https://www.spotlight.com/the-small-print/terms-conditions/",
        title: "common:nav.links.termsConditions",
      },
      {
        href: "https://www.spotlight.com/the-small-print/privacy-policy/",
        title: "common:nav.links.privacyPolicy",
      },
      {
        href: "javascript:CookieScript.instance.show()",
        title: "common:nav.links.cookieSettings",
      },
      {
        href: "https://www.spotlight.com/help-and-faqs/",
        title: "common:nav.links.helpFaqs",
      },
    ],
  } as INavLinks,
  texts: {
    linkListsTitles: [
      "common:nav.links.titles.aboutSpotlight",
      "common:nav.links.titles.spotlightTools",
      "common:nav.links.titles.terms",
    ],
    followCTA: "common:nav.socialMediaLinks.title",
    copyrightAddress: "common:nav.address",
  },
});
