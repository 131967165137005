import { HttpClient } from "./HttpClient";
import { getApiUrl } from "./UrlBuilder";

import {
  GalleryPhotos,
  BasePhoto,
  Photographer,
  Photo,
  FileUploadStatuses,
  FileStatus,
  PhotoUploadAccess,
} from "models";
import { HALManager, SettingManager } from "core";
import {
  InvalidOperationError,
  HttpOperationError,
} from "shared/utils/exceptions";

export class PhotoService {
  private httpClient: HttpClient = new HttpClient();

  public async getGallery() {
    const urlRequest = getApiUrl(HALManager.getGalleryUrl());
    const requestParams = {
      headers: { "Cache-control": "no-cache" },
    };

    const response = await this.httpClient.get<GalleryPhotos>(
      urlRequest,
      requestParams
    );
    SettingManager.eTag = {
      gallery: response.headers.get("etag") || undefined,
    };

    return response.object.photos as Photo[];
  }

  public async getPhotographer(photographerName: string) {
    const urlRequest = getApiUrl(
      HALManager.getPhotographersUrl(photographerName)
    );

    const { object: photographer } =
      await this.httpClient.get<Photographer>(urlRequest);

    return photographer;
  }

  public async getGalleryPhoto(photo: BasePhoto) {
    if (photo._links?.self?.href) {
      const urlRequest = getApiUrl(photo._links.self.href);

      const response = await this.httpClient.get<Photo>(urlRequest);

      return response.object;
    }

    return photo as Photo;
  }

  public async updatePhotoMetadata(updatedPhotoDate: any, photo: Photo) {
    if (!photo._links?.patchPhoto?.href) {
      throw new InvalidOperationError("Photo has no patchPhoto link");
    }

    const urlRequest = getApiUrl(photo._links.patchPhoto.href);
    const requestParams = { body: JSON.stringify(updatedPhotoDate) };

    const response = await this.httpClient.patch<Photo>(
      urlRequest,
      requestParams
    );

    return response.object;
  }

  public async patchGallery(patchDiff: any) {
    const eTag = SettingManager.eTag.gallery || "";

    const urlRequest = getApiUrl(HALManager.updateGalleryUrl());
    const requestParams = {
      body: JSON.stringify(patchDiff),
      headers: { "If-Match": eTag },
    };

    const response = await this.httpClient.patch<GalleryPhotos>(
      urlRequest,
      requestParams
    );
    SettingManager.eTag = {
      gallery: response.headers.get("etag") || undefined,
    };

    return response.object.photos;
  }

  public async getMainPhoto() {
    const urlRequest = getApiUrl(HALManager.getMainPhotoUrl());

    const response = await this.httpClient.get<Photo>(urlRequest);

    return response.object;
  }

  public async makeMainPhoto(photo: Photo) {
    if (!photo._links?.makeMain?.href) {
      throw new InvalidOperationError("Photo has no makeMain link");
    }

    const urlRequest = getApiUrl(photo._links.makeMain.href);

    try {
      const { object: photo } = await this.httpClient.post<Photo>(urlRequest);

      return photo;
    } catch (error) {
      throw new HttpOperationError(
        urlRequest,
        "Make main photo has been failed",
        { photo },
        error
      );
    }
  }

  public async deletePhoto(photo: BasePhoto) {
    if (!photo._links?.self?.href) {
      throw new InvalidOperationError("Photo has no self link");
    }

    const urlRequest = getApiUrl(photo._links.self.href);

    try {
      await this.httpClient.delete(urlRequest);
    } catch (error) {
      throw new HttpOperationError(
        urlRequest,
        "Photo deletion has been failed",
        { photo },
        error
      );
    }
  }

  public async hidePhoto(photo: Photo) {
    if (!photo._links?.self?.href) {
      throw new InvalidOperationError("Photo has no 'self' link");
    }

    const urlRequest = getApiUrl(photo._links.self.href);
    const body = [{ op: "replace", path: "/ishidden", value: photo.isHidden }];
    const requestParams = { body: JSON.stringify(body) };

    const response = await this.httpClient.patch(urlRequest, requestParams);

    return response.object;
  }

  public async savePhoto(photo: Photo) {
    if (!photo._links?.patchPhoto?.href) {
      throw new InvalidOperationError("Photo has no 'patchPhoto' link");
    }

    const { fileName } = await this.createRequestForUploading(photo);

    const body = [{ op: "replace", path: "/filename", value: fileName }];

    const patchPhotoUrl = getApiUrl(photo._links.patchPhoto.href);
    const requestParams = { body: JSON.stringify(body) };

    try {
      const { object: updatedPhoto } = await this.httpClient.patch<Photo>(
        patchPhotoUrl,
        requestParams
      );

      return updatedPhoto;
    } catch (error) {
      throw new InvalidOperationError(
        "Patching photo data has been failed",
        { patchPhotoUrl, requestParams },
        error
      );
    }
  }

  public async uploadPhoto(photo: Photo): Promise<Photo> {
    const fileStatus = await this.createRequestForUploading(photo);

    const body = {
      photographerName: photo.photographer,
      fileName: fileStatus.fileName,
      description: photo.description,
    };

    const urlRequest = getApiUrl(HALManager.getGalleryUrl()); // ???
    const requestParams = { body: JSON.stringify(body) };

    let photoUrl: string | undefined;

    const {
      object: { location },
    } = await this.httpClient.post<any>(urlRequest, requestParams);

    photoUrl = getApiUrl(location);

    const { object: newPhoto } = await this.httpClient.get<Photo>(photoUrl);

    return newPhoto;
  }

  private async createRequestForUploading({
    url,
    id,
  }: Photo): Promise<FileStatus> {
    let blobObject: Blob;

    try {
      blobObject = await this.fetchPhoto(url);
    } catch (error) {
      throw new InvalidOperationError("Invalid photo casting", { id }, error);
    }

    if (blobObject.size == 0) {
      throw new InvalidOperationError(
        "Invalid photo casting. Converted blob size is 0"
      );
    }

    let uploadSession: any;

    const urlRequest = getApiUrl("/profiles/upload/photo/requesturl");

    const { object } =
      await this.httpClient.post<PhotoUploadAccess>(urlRequest);

    uploadSession = object;

    try {
      await this.httpClient.put(uploadSession.uploadFileUrl, {
        body: blobObject,
      });
    } catch (error) {
      const context = {
        uploadUrl: uploadSession.uploadFileUrl,
        blobSize: blobObject.size,
      };
      throw new HttpOperationError(
        urlRequest,
        "Uploading binary object has been failed",
        context,
        error
      );
    }

    return this.checkStatus(uploadSession);
  }

  private async fetchPhoto(url: string): Promise<Blob> {
    const resource = await fetch(url);

    return resource.blob();
  }

  private checkStatus = async (
    model: PhotoUploadAccess
  ): Promise<FileStatus> => {
    const delay: Promise<void> = new Promise<void>((resolve) =>
      setTimeout(() => resolve(), 1000)
    );
    const urlRequest = getApiUrl(model._links.fileUploadStatus.href);

    const { object } = await this.httpClient.get<FileStatus>(urlRequest);

    if (
      object.status === FileUploadStatuses.AwaitingUpload ||
      object.status === FileUploadStatuses.Validating
    ) {
      await delay;

      return this.checkStatus(model);
    } else if (object.status === FileUploadStatuses.Rejected) {
      throw new InvalidOperationError("Photo has been rejected", {
        fileStatus: object.status,
      });
    } else if (object.status === FileUploadStatuses.Accepted) {
      return object;
    }

    throw new InvalidOperationError(
      "Photo processing completed with unknown status",
      { fileStatus: object.status }
    );
  };
}

export default new PhotoService();
