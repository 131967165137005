import {
  getFilmmakersJobsLink,
  getSelftapeRequestsSubNav,
} from "./sharedLinks";
import { IModifiers } from "./getNavHeaderLinks";

export function getGradsNav(modifiers: IModifiers) {
  const { isSelfTapesAgentPerformerEnabled, isFilmmakersPhase4Enabled } =
    modifiers;

  return {
    mainNavigationItems: [
      {
        text: "common:nav.links.contactsListings",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1200/contacts.svg",
        linkUrl:
          "/contacts/?utm_source=leftNav&utm_medium=oldSite&utm_campaign=contactsLaunch",
      },
      {
        text: "common:nav.links.jobsFeed",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2230/search.svg",
        linkUrl: "/jobs/matching-my-filters",
        subNavigationItems: [
          {
            text: "common:nav.links.jobMatches",
            linkUrl: "/jobs/matching-my-filters",
          },
          {
            text: "common:nav.links.paidJobs",
            linkUrl: "/jobs/all-paid",
          },
          {
            text: "common:nav.links.allJobs",
            linkUrl: "/jobs/all-opportunities",
          },
          ...getSelftapeRequestsSubNav(isSelfTapesAgentPerformerEnabled),
          {
            text: "common:nav.links.jobSettings",
            linkUrl: "/settings/",
          },
          ...getFilmmakersJobsLink(isFilmmakersPhase4Enabled),
        ],
      },
      {
        text: "common:nav.links.usersProfile",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1199/clients.svg",
        linkUrl: "/profile/",
        subNavigationItems: [
          {
            text: "common:nav.links.manageAgents",
            linkUrl: "/profile/contacts",
          },
          {
            text: "common:nav.links.viewUsersProfile",
            linkUrl: "/profile/myviewpin/",
          },
          {
            text: "common:nav.links.updateProfile",
            linkUrl: "/profile/",
          },
          {
            text: "common:nav.links.printUsersProfile",
            linkUrl: "/profile?action=print",
          },
          {
            text: "common:nav.links.emailUsersProfile",
            linkUrl: "/profile?action=email",
          },
          {
            text: "common:nav.links.usersProfileLink",
            linkUrl: "/profile/linktoprofile",
          },
        ],
      },
      {
        text: "common:nav.links.manageMedia",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1201/showreel.svg",
        linkUrl: "/profile/photos",
        subNavigationItems: [
          {
            text: "common:nav.links.photos",
            linkUrl: "/profile/photos",
          },
          {
            text: "common:nav.links.video",
            linkUrl: "/profile/media/video",
          },
          {
            text: "common:nav.links.audio",
            linkUrl: "/profile/media/audio",
          },
        ],
      },
      {
        text: "common:nav.links.accountSettings",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1202/sun.svg",
        linkUrl: "/myhome/account-settings",
        subNavigationItems: [
          {
            text: "common:nav.links.usersDetails",
            linkUrl: "/myhome/account-settings/my-details",
          },
          {
            text: "common:nav.links.privacySettings",
            linkUrl: "/artists/privacy.aspx",
          },
        ],
      },
      {
        text: "common:nav.help",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1203/lhnhelp-1.svg",
        linkUrl: "https://www.spotlight.com/help-and-faqs/",
      },
    ],
  };
}
