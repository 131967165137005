/**
 * Stateless input component
 */

import React from "react";
import cx from "classnames";

import {
  ERROR,
  WARNING,
  InputWrapper,
  NOTIFICATION,
} from "../../Atoms/InputsCommon/InputWrapper";
import {
  IBaseInputProps,
  InputSizes,
} from "../../Atoms/InputsCommon/BaseInputProps";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import { omit } from "../../../generics/object-manipulation";

import "./Input.scss";

export interface ITextInputProps
  extends IBaseInputProps<HTMLInputElement, string> {
  type: "text" | "password" | "email";
  minLength?: number;
  maxLength?: number;
  whiteInput?: boolean;
  children?: React.ReactNode;
}

interface INumberInputProps extends IBaseInputProps<HTMLInputElement, number> {
  type: "number";
  max?: number;
  min?: number;
  whiteInput?: boolean;
  children?: React.ReactNode;
}

export type IInputProps = ITextInputProps | INumberInputProps;

export interface ITextInputState {
  blurred: boolean;
}

const EXCLUDED_PROPS = [
  "label",
  "invalid",
  "warned",
  "notifying",
  "validationMessage",
  "onBlur",
  "children",
  "isFormSubmitted",
  "valueChanged",
  "whiteInput",
];

export class Input extends React.Component<IInputProps, ITextInputState> {
  public static defaultProps: Partial<IInputProps> = {
    autoComplete: "off",
    size: InputSizes.Large,
  };
  public readonly state: Readonly<ITextInputState> = {
    blurred: false,
  };

  private inputRef = React.createRef<HTMLInputElement>();

  public render() {
    const { className, id } = this.props;
    const invalidState = this.getInvalidState();
    const inputClassName = cx("c-input", className);

    return (
      <div className={inputClassName}>
        <InputWrapper
          invalid={invalidState}
          validationMesssage={this.props.validationMessage}
        >
          <InputLabel
            label={this.props.label}
            annex={this.props.annex}
            htmlFor={id}
          />
          <div
            className={cx(
              "c-input__container",
              `c-input__container--${this.props.size}`,
              { "c-input--white": this.props.whiteInput || this.props.value }
            )}
          >
            <input
              ref={this.inputRef}
              {...omit(this.props, EXCLUDED_PROPS)}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              data-testid={this.props["data-testid"]}
            />
            {this.props.children}
          </div>
        </InputWrapper>
      </div>
    );
  }

  public focus() {
    const input = this.inputRef.current;
    if (input) {
      input.focus();
      // focus with cursor at end of current text
      input.setSelectionRange(input.value.length, input.value.length);
    }
  }

  public blur = () => this.inputRef.current && this.inputRef.current.blur();

  private getInvalidState = () => {
    const shouldValidate = this.state.blurred || this.props.isFormSubmitted;

    const isError = shouldValidate && this.props.invalid;
    const isWarning = shouldValidate && this.props.warned;
    const isNotification = shouldValidate && this.props.notifying;

    return isError
      ? ERROR
      : isWarning
      ? WARNING
      : isNotification
      ? NOTIFICATION
      : undefined;
  };

  private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onBlur && this.props.onBlur(event);

    this.setState({ blurred: true });
  };

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(event);

    if (this.props.valueChanged) {
      let value: string | number = event.currentTarget.value;

      if (this.props.type === "number") {
        value = parseFloat(value);
        this.props.valueChanged(value);
      } else {
        this.props.valueChanged(value);
      }
    }
  };
}
