import React from "react";
import { AlertMessage, IAlertMessageProps } from "../AlertMessage/AlertMessage";
import { IPopupProps, Popup } from "../Popup/Popup";

export interface IConfirmationPopupTexts {
  title: string;
  description?: string;
  proceed: string;
  cancel: string;
}

export interface IConfirmationPopupDynamicProps {
  texts: IConfirmationPopupTexts;
  titleIcon?: IAlertMessageProps["icon"];
  loading?: boolean;
  handleProceed: () => void;
  proceedTestId?: string;
  handleCancel: () => void;
}

export const ConfirmationPopup: React.FC<IConfirmationPopupDynamicProps> = (
  props
) => {
  const alertMessageProps: IAlertMessageProps = {
    icon: props.titleIcon,
    texts: {
      title: props.texts.title,
      description: props.texts?.description,
    },
    buttons: [
      {
        name: props.texts.proceed,
        click: () => props.handleProceed(),
        loading: props.loading,
        type: "primary",
        "data-testid": props?.proceedTestId,
      },
      {
        name: props.texts.cancel,
        click: () => props.handleCancel(),
        type: "secondary",
      },
    ],
  };

  const popupProps: IPopupProps = {
    width: { lg: 4, md: 6 },
    close: () => props.handleCancel(),
    priority: "high",
    texts: { closePopup: "Close" },
  } as IPopupProps;

  return (
    <Popup {...popupProps}>
      <AlertMessage {...alertMessageProps}>{props.children}</AlertMessage>
    </Popup>
  );
};
