import axios from "axios";
import {
  MediaMetadata,
  MediaObject,
  MediaType,
  TranscodeStatus,
  ViewMedia,
  IAudioModel,
  IVideoModel,
  MediaModel,
  IExternalMedia,
  OutputGroupType,
} from "shared-types";
import config from "../config";

export type ArtistMedia = {
  media: MediaModel[];
  artistName: string;
};

const PROFILE_API_ROOT = config.profileApiUrl;
const TITANIUM_API_ROOT = config.titaniumApiUrl;

const getMediaMetadataByProfileId = async <T>(
  mediaType: MediaType,
  profileId: string
): Promise<T[]> => {
  const { data } = await axios.get<T[]>(
    `${PROFILE_API_ROOT}/profiles/${profileId}/media/view/${mediaType}`
  );

  return data;
};

export const getExternalMediaDataByProfileId = async (
  mediaType: MediaType,
  profileId: string
) => {
  const data = await getMediaMetadataByProfileId<IExternalMedia>(
    mediaType,
    profileId
  );

  if (mediaType === MediaType.Video) {
    return data.map(externalMediaMapToVideoModel);
  }

  if (mediaType === MediaType.Audio) {
    return data.map(externalMediaMapToAudioModel);
  }
};

const getMediaMetadataByArtistReference = async (
  mediaType: MediaType,
  artistRef: string
): Promise<ViewMedia> => {
  const { data } = await axios.get<ViewMedia>(
    `${PROFILE_API_ROOT}/profiles/media/view/${mediaType}/${artistRef}`
  );

  return data;
};

const getMediaObject = async (
  mediaMetadata: MediaMetadata
): Promise<MediaObject> => {
  const { data } = await axios.get<{ value: MediaObject }>(
    `${TITANIUM_API_ROOT}/media/${mediaMetadata.mediaInfoId}`
  );

  return data.value;
};

const reflect = (promise: Promise<any>): Promise<any> =>
  promise.then(
    (value) => ({ value, status: "fulfilled" }),
    (error) => ({ error, status: "rejected" })
  );

export const getMediaDataByArtistRef = async (
  mediaType: MediaType,
  artistRef: string
): Promise<ArtistMedia> => {
  const data = await getMediaMetadataByArtistReference(mediaType, artistRef);
  const mediaMapped = await processMedia(data.media, mediaType);

  return { media: mediaMapped, artistName: data.artistName };
};

export const getMediaData = async (
  mediaType: MediaType,
  profileId: string
): Promise<ArtistMedia> => {
  const mediaMetadata = await getMediaMetadataByProfileId<MediaMetadata>(
    mediaType,
    profileId
  );
  const mediaMapped = await processMedia(mediaMetadata, mediaType);

  return { media: mediaMapped, artistName: "" };
};

const processMedia = async (
  mediaMetadata: MediaMetadata[],
  mediaType: MediaType
): Promise<MediaModel[]> => {
  const sortedMetadata = mediaMetadata.sort(
    (currentMeta, nextMeta) => currentMeta.sortOrder - nextMeta.sortOrder
  );
  const mediaObjectPromises = sortedMetadata.map((mediaMetadata) =>
    getMediaObject(mediaMetadata)
  );
  const mediaObjects: MediaObject[] = await Promise.all(
    mediaObjectPromises.map(reflect)
  )
    .then((allResults) =>
      allResults.filter((result) => result.status === "fulfilled")
    )
    .then((fulfilledResults) => fulfilledResults.map((result) => result.value));
  const completedMediaObjects = mediaObjects.filter(
    ({ transcodeStatus }) => transcodeStatus === TranscodeStatus.Complete
  );

  const mediaMapped = completedMediaObjects.map((mediaObject) => {
    const mediaMeta = mediaMetadata.find(
      ({ mediaInfoId }) => mediaInfoId === mediaObject.id
    );

    return mediaType === MediaType.Video
      ? mapToVideoModel(mediaMeta!, mediaObject)
      : mapToAudioModel(mediaMeta!, mediaObject);
  });

  return mediaMapped;
};

export const mapToVideoModel = (
  mediaMetadata: Pick<MediaMetadata, "id" | "title" | "selectedThumbnail">,
  mediaObject: MediaObject
): IVideoModel => {
  const { durationInMs = 0, thumbnails = [], outputFiles = [] } = mediaObject;
  const sources = outputFiles.map((outputFile) => outputFile.objectUrls[0]);
  const fileTypeOutput = outputFiles.find(
    ({ outputGroupType }) => outputGroupType === OutputGroupType.FILE
  );
  const downloadLink = fileTypeOutput?.objectUrls?.[0] ?? "";

  return {
    id: mediaMetadata.id,
    fileName: mediaMetadata.title,
    duration: durationInMs,
    previewImg: thumbnails[mediaMetadata.selectedThumbnail || 0],
    sources,
    downloadLink,
    shareLink: "",
    poster: thumbnails[0],
  };
};

export const externalMediaMapToVideoModel = (media: IExternalMedia) => ({
  id: media.id,
  fileName: media.title,
  duration: media.durationInMs,
  previewImg: media.thumbnail,
  sources: [media.url],
  downloadLink: "",
  shareLink: "",
  poster: media.thumbnail,
});

export const mapToAudioModel = (
  mediaMetadata: Pick<MediaMetadata, "id" | "title">,
  mediaObject: MediaObject
): IAudioModel => {
  const { durationInMs = 0, outputFiles = [] } = mediaObject;
  const sources = outputFiles.map((outputFile) => outputFile.objectUrls[0]);

  return {
    id: mediaMetadata.id,
    fileName: mediaMetadata.title,
    src: sources[0],
    duration: durationInMs,
  };
};

export const externalMediaMapToAudioModel = (media: IExternalMedia) => ({
  id: media.id,
  fileName: media.title,
  src: media.url,
  duration: media.durationInMs,
});
