import React, { FC, useState } from "react";
import { IAudioModel, IVideoModel, MediaModel, MediaType } from "shared-types";
import { Popup } from "../../Molecules/Popup/Popup";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import "./styles.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  mediaType: MediaType;
  mediaList: MediaModel[];
};

const PopUpMediaViewer: FC<Props> = ({
  mediaType,
  mediaList,
  isOpen,
  onClose,
}) => {
  const [selectedMedia, setSelectedMedia] = useState(mediaList[0]);

  return isOpen ? (
    <Popup
      width={{ lg: 10, md: 12 }}
      close={onClose}
      fullHeight
      className="popup-media-viewer"
      priority="high"
    >
      {mediaType === MediaType.Video && (
        <VideoPlayer
          videoList={mediaList as IVideoModel[]}
          selectedVideo={selectedMedia as IVideoModel}
          selectVideo={(video) => setSelectedMedia(video)}
          hasCloseButton={false}
          autoPlay
        />
      )}
      {mediaType === MediaType.Audio && (
        <AudioPlayer
          currentTrack={selectedMedia as IAudioModel}
          tracks={mediaList as IAudioModel[]}
          selectTrack={(track) => setSelectedMedia(track)}
          isAutoPlay
        />
      )}
    </Popup>
  ) : null;
};

export default PopUpMediaViewer;
