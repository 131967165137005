import React from "react";
import { config } from "config";
import { Button, Helper } from "component-library";
import { i18n } from "i18n";

import {
  GlobalAlertMessage,
  PhotoTool,
  PhotoToolState,
} from "shared/modules/Common";
import { HistoryRecord } from "shared/modules/Common/PhotoTool/HistoryRecord";
import { InvalidOperationError } from "shared/utils/exceptions";

import { Photo } from "models";

import {
  getAlertMessage,
  getHelpAndAdvice,
  IPhotoEditorContent,
} from "./PhotoEditorContent";

import "./PhotoEditor.scss";

export interface IPhotoEditorProps {
  photo?: Photo;
  photoEditorData?: PhotoToolState;
  imageType: string;
  error?: string;
  content: IPhotoEditorContent;
  savePhoto: (data: PhotoToolState) => void;
  cancelEditing: (data?: PhotoToolState) => void;
  errorNotification: (message: GlobalAlertMessage) => void;
  showHelpPopup: (message: GlobalAlertMessage) => void;
}

interface IPhotoToolState {
  occupiedHeight: number;
  minPhotoHeight: number;
  isDimensionsError: boolean;
}

const MIN_PHOTO_HEIGHT = 200;
const CLOUDINARY_MAX_PIXELS = 50000000;

export default class PhotoEditor extends React.Component<
  IPhotoEditorProps,
  IPhotoToolState
> {
  public readonly state: Readonly<IPhotoToolState> = {
    occupiedHeight: 0,
    minPhotoHeight: MIN_PHOTO_HEIGHT,
    isDimensionsError: false,
  };

  private containerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private headerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private buttonsContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private photoToolRef: React.RefObject<PhotoTool> =
    React.createRef<PhotoTool>();

  public render() {
    const cropperSettings = {
      style: {
        minHeight: `${this.state.minPhotoHeight}px`,
        overflow: "hidden",
        maxHeight: `calc(100vh - ${this.state.occupiedHeight}px)`,
        width: "100%",
      },
    };

    const {
      error = null,
      photo = null,
      photoEditorData = null,
      content,
      imageType,
    } = this.props;

    const isSaveButtonDisabled = Boolean(error) || !Boolean(photo && photo.url);

    return (
      <div ref={this.containerRef} className="c-photo-editor">
        <div ref={this.headerRef} className="c-photo-editor__title">
          {content.header}
        </div>
        <div className="c-photo-editor__container">
          <PhotoTool
            ref={this.photoToolRef}
            cropValidation={this.cropAreaValidation}
            error={error}
            validateApply={this.validateApply}
            photo={photo}
            photoToolData={photoEditorData}
            cropperSettings={cropperSettings}
            texts={content.photoTool}
            imageType={imageType}
            preventCrop={this.state.isDimensionsError}
          />
        </div>
        {this.renderEditorTools(content, isSaveButtonDisabled)}
      </div>
    );
  }

  public componentDidMount() {
    window.addEventListener("resize", this.adaptHeight, false);
    this.adaptHeight();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.adaptHeight, false);
  }

  public getPhotoEditorState = () => {
    let photoToolState: PhotoToolState | undefined;

    const photoTool = this.photoToolRef.current;

    if (photoTool && photoTool.isReady) {
      try {
        photoToolState = photoTool.getPhotoToolState() || undefined;
      } catch (error) {
        const editHistory = this.getPhotoToolHistory(photoTool).map(
          ({ toolState, toolId }) => ({ toolState, toolId })
        );

        throw new InvalidOperationError(
          "Getting photo error failed",
          { editHistory },
          error
        );
      }
    }

    return photoToolState;
  };

  private getPhotoToolHistory = (photoTool: PhotoTool): HistoryRecord[] => {
    let editHistory: HistoryRecord[] = [];

    try {
      editHistory = photoTool.getHistoryCopy();
    } catch (error) {
      throw new InvalidOperationError(
        "Getting photo editing history failed",
        error
      );
    }

    return editHistory;
  };

  private renderEditorTools = (
    content: IPhotoEditorContent,
    isSaveButtonDisabled: boolean
  ) => (
    <div ref={this.buttonsContainerRef} className="c-photo-editor__tools">
      <div className="c-photo-editor__editor-bar">
        <div className="g-hidden-sm g-hidden-md">
          <Helper
            {...content.helper}
            click={this.showHelpAndAdvice}
            triggerSize="small"
          />
        </div>
        <div className="c-photo-editor__editor-buttons">
          <div className="c-photo-editor__editor-button g-hidden-sm">
            <Button
              type="secondary"
              text={content.cancelEditingButton}
              onClick={this.cancelEditing}
            />
          </div>
          <div className="c-photo-editor__editor-button">
            <Button
              type="primary"
              disabled={isSaveButtonDisabled}
              text={content.savePhotoButton}
              onClick={this.saveChanges}
            />
          </div>
        </div>
      </div>
    </div>
  );

  private cropAreaValidation = (event: CustomEvent) => {
    const { height: areaHeight, width: areaWidth } = event.detail;
    const { minResolution } = config.photoValidation;

    const isTooManyMegapixels = areaHeight * areaWidth > CLOUDINARY_MAX_PIXELS;
    const isTooFewMegapixels =
      areaWidth <= minResolution.width || areaHeight <= minResolution.height;

    let result = { isValid: true, message: "" };

    if (isTooManyMegapixels) {
      result = {
        isValid: false,
        message: i18n.t(
          "performer:profile.error.photoExceedsMaximumResolution"
        ),
      };
    }

    if (isTooFewMegapixels) {
      result = { isValid: false, message: this.props.content.resolutionError };
    }

    this.setState({
      isDimensionsError: isTooManyMegapixels || isTooFewMegapixels,
    });

    return result;
  };

  private showHelpAndAdvice = () =>
    this.props.showHelpPopup(getHelpAndAdvice(this.props.content.helpAdvice));

  private validateApply = (canvas: HTMLCanvasElement): boolean => {
    const { minResolution } = config.photoValidation;
    const { height: canvasHeight, width: canvasWidth } = canvas;

    const isValidImageResolution =
      canvasWidth >= minResolution.width &&
      canvasHeight >= minResolution.height;

    if (!isValidImageResolution) {
      this.props.errorNotification(getAlertMessage(this.props.content));
    }

    return isValidImageResolution;
  };

  private cancelEditing = () => {
    const { error, cancelEditing } = this.props;

    if (!Boolean(error)) {
      const photoToolState = this.getPhotoEditorState();
      cancelEditing(photoToolState);
    } else {
      cancelEditing();
    }
  };

  private saveChanges = () => {
    if (this.state.isDimensionsError) {
      this.props.errorNotification(
        getAlertMessage({
          resolutionErrorHeader: i18n.t("common:label.error"),
          resolutionError: i18n.t(
            "performer:profile.error.photoDimensionsError"
          ),
          closeErrorButton: "Close",
        })
      );
      return;
    }

    const photoToolState = this.getPhotoEditorState();
    if (photoToolState) {
      this.props.savePhoto(photoToolState);
    }
  };

  private adaptHeight = () => {
    const buttonsContainer = this.buttonsContainerRef.current;
    const header = this.headerRef.current;
    const container = this.containerRef.current;

    if (header && buttonsContainer && container) {
      const containerStyles = window.getComputedStyle(container, null);

      const paddingTop = parseFloat(
        containerStyles.getPropertyValue("padding-top")
      );
      const paddingBottom = parseFloat(
        containerStyles.getPropertyValue("padding-bottom")
      );
      const containerPaddings = paddingTop + paddingBottom;

      const tools = container.getElementsByClassName("c-photo-tool__tools")[0];
      const toolHeight = tools ? tools.clientHeight : 0;
      const occupiedHeight =
        buttonsContainer.offsetHeight +
        header.offsetHeight +
        containerPaddings +
        toolHeight;
      const minPhotoHeight = Math.max(
        window.innerHeight - occupiedHeight,
        MIN_PHOTO_HEIGHT
      );

      if (this.state.occupiedHeight !== occupiedHeight) {
        this.setState({ occupiedHeight, minPhotoHeight });
      } else {
        this.setState({ minPhotoHeight });
      }
    }
  };
}
