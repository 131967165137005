import React, { FC, useContext, useEffect } from "react";
import { useScrollToElement } from "shared-hooks";
import { dataTestIds } from "data-testids";
import { BillingUser } from "translations/src/models/billing";
import { Button } from "../../../../Atoms/Button/Button";
import { BillingContext } from "../../BillingContext";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { JOINING_PAGES_ROOT, USER_ACCOUNT_APP_ROOT } from "../../constants";

type SuccessPageProps = {
  userType?: Exclude<BillingUser, BillingUser.Common>;
  textOverrides?: Record<string, unknown>;
  shouldRenderCTA?: boolean;
};

const joiningUserType = [
  BillingUser.JoiningPerformer,
  BillingUser.JoiningYoungPerformer,
];

const SuccessPage: FC<SuccessPageProps> = ({
  userType,
  textOverrides,
  shouldRenderCTA = true,
}) => {
  const { onPaymentSuccessGoBackAction, goBack, billingUserType } =
    useContext(BillingContext);

  const type = userType || billingUserType;
  const {
    texts: { common: texts },
  } = useBillingTranslations(type);

  const userTexts = {
    ...texts,
    ...(textOverrides?.common || {}),
  };

  const isDirectDebitPurchase =
    history.state?.usr?.isDirectDebit ?? history.state?.state?.isDirectDebit;

  const { ref: containerRef, scrollToElement: scrollToHeaderContainer } =
    useScrollToElement();

  useEffect(() => {
    scrollToHeaderContainer();
  }, []);

  if (isDirectDebitPurchase === undefined) {
    const redirectUrl = joiningUserType.includes(type)
      ? JOINING_PAGES_ROOT
      : USER_ACCOUNT_APP_ROOT;

    location.replace(redirectUrl);
    return null;
  }

  return (
    <div ref={containerRef} className="flex flex-col px-4 pb-4">
      <h1
        className="mt-[30px] font-normal text-[1.4rem] leading-[2.2rem]"
        data-testid={
          dataTestIds.componentLibrary["Organisms.Billing.SuccessPage.heading"]
        }
      >
        {isDirectDebitPurchase
          ? userTexts.directDebitSuccessHeader
          : userTexts.successHeader}
      </h1>
      <p
        className="mt-[10px]"
        dangerouslySetInnerHTML={{
          __html: isDirectDebitPurchase
            ? userTexts.directDebitSuccessBody || ""
            : userTexts.successBody,
        }}
      />
      {shouldRenderCTA && (
        <Button
          type="primary"
          onClick={
            onPaymentSuccessGoBackAction ? onPaymentSuccessGoBackAction : goBack
          }
          text={userTexts.goBackButton}
          className="mt-[60px] max-w-[280px]"
          data-testid={
            dataTestIds.componentLibrary[
              "Organisms.Billing.SuccessPage.backButton"
            ]
          }
        />
      )}
    </div>
  );
};

export default SuccessPage;
