import { SettingManager } from "app/core";
import React from "react";
import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  TranslationLibrary,
  TranslationProvider,
} from "contexts/TranslationContext";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

interface IWithTranslationState {
  isLoaded: boolean;
}

declare type WithTranslationProps = IGlobalContextProps;

export default function withTranslation<P extends WithTranslationProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithTranslationState> {
    public readonly state: Readonly<IWithTranslationState> = {
      isLoaded: false,
    };
    private translation = new TranslationLibrary();

    public render() {
      const { isLoaded } = this.state;

      return (
        <TranslationProvider value={this.translation}>
          {isLoaded && <WrappedComponent {...this.props} />}
        </TranslationProvider>
      );
    }

    public componentDidMount() {
      this.loadLocalization();
      this.props.globalContext.addListener(
        GlobalEventTypes.reloadLocalization,
        this.loadLocalization
      );
    }

    private loadLocalization = async () => {
      const { globalContext } = this.props;

      if (
        this.translation.currentLocalization !== SettingManager.localization
      ) {
        try {
          await this.translation.initLibrary(
            SettingManager.localization || SettingManager.defaultLocalization
          );
        } catch (error) {
          captureExceptionEvent(
            error,
            authorizationService.getDecodedUserToken()
          );
        }

        globalContext.notifyListener(GlobalEventTypes.updateLocalization, {
          library: this.translation,
        });
        this.setState({ isLoaded: true });
      }
    };
  };
}
