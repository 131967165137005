import React from "react";
import { Popup, IAlertMessageProps, AlertMessage } from "component-library";

import { GalleryPhotoView } from "./GalleryPhotoView";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";

import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "components/flows/PopupFlowBaseComponent";

import { Photo } from "models";
import { FLOW_TYPES } from "../FlowFactory/FlowTypes";
import {
  IGalleryPhotoFlowContent,
  getDeleteDialogSettings,
  getEditingPhotoError,
} from "./GalleryPhotoFlowContent";
import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";
import compose from "shared/utils/compose";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

const STEP_NAME = {
  galleryPhoto: "galleryPhoto",
  deletePhoto: "deletePhoto",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export interface IGalleryPhotoFlowProps
  extends IFlowContextProps,
    IGlobalContextProps {
  photoPromise: () => Promise<Photo>;
  content: IGalleryPhotoFlowContent;
}

interface IGalleryPhotoFlowState extends IPopupFlowBaseComponentState {
  photo?: Photo;
  error?: string;
  showContent: boolean;
}

export class GalleryPhotoFlow extends PopupFlowBaseComponent<
  IGalleryPhotoFlowProps,
  IGalleryPhotoFlowState
> {
  public readonly state: Readonly<IGalleryPhotoFlowState> = {
    currentStep: STEP_NAME.galleryPhoto,
    showContent: true,
  };

  private photoPromise: ICancelablePromise;
  private isProcessStarted: boolean = false;

  constructor(props: Readonly<IGalleryPhotoFlowProps>) {
    super(props);

    this.makeFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    const { showContent } = this.state;

    let view: React.ReactNode = null;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  private makeFlowSteps = () => {
    const { content } = this.props;

    this.flowSteps = [
      {
        name: STEP_NAME.galleryPhoto,
        component: this.getGalleryPhotoView,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          {
            closePopup: content.viewGalleryPhoto.close,
          },
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          close: this.closeFlow,
          content: content.viewGalleryPhoto,
          deletePhoto: this.deleteConfirmation,
          savePhoto: this.savePhoto,
          editPhoto: this.editPhoto,
          showHelpPopup: this.showHelpPopup,
        },
      },
      {
        name: STEP_NAME.deletePhoto,
        component: this.getDeleteDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: content.deletePhotoConfirmation.deleteClosePopup },
          this.closeFlow
        ),
        settings: getDeleteDialogSettings({
          content: content.deletePhotoConfirmation,
          deletePhoto: this.deletePhoto,
          backToView: this.backToView,
        }),
      },
    ];
  };

  public async componentDidMount() {
    this.photoPromise = makeCancelable(this.props.photoPromise());

    try {
      const photo = await this.photoPromise.promise;

      this.setState({ photo });
    } catch (error) {
      const { content } = this.props;
      if (!error.isCanceled) {
        this.setState({ error: content.photoLoadingError });
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    }
  }

  public componentWillUnmount() {
    this.photoPromise && this.photoPromise.cancel();
  }

  private closeFlow = () => this.props.flowContext.changeContext("", null);

  private savePhoto = async (photo: Photo, isMakeMainPhoto: boolean) => {
    const { photo: originalPhoto } = this.state;

    if (!this.isProcessStarted && originalPhoto) {
      this.startProcess();

      const { globalContext } = this.props;
      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      const isMakeHidden =
        !isMakeMainPhoto && photo.isHidden !== originalPhoto.isHidden;

      if (isMakeHidden) {
        try {
          await PhotoService.hidePhoto(photo);
          globalContext.notifyListener(GlobalEventTypes.updateGallery);

          this.closeFlow();
        } catch (error) {
          this.finishProcess();
        }
      } else if (isMakeMainPhoto) {
        try {
          await PhotoService.makeMainPhoto(photo);
          globalContext.notifyListener(GlobalEventTypes.updateMainPhoto, {
            showDialog: true,
          });
          globalContext.notifyListener(GlobalEventTypes.changeMainPhoto);
          globalContext.notifyListener(GlobalEventTypes.updateGallery);
          this.closeFlow();
        } catch (error) {
          this.finishProcess();
        }
      } else {
        this.closeFlow();
      }

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        false
      );
    }
  };

  private finishProcess = () => {
    this.props.globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );
    this.setState({ showContent: true });
    this.isProcessStarted = false;
  };

  private startProcess = () => {
    this.setState({ showContent: false });
    this.isProcessStarted = true;
  };

  private deleteConfirmation = () => {
    const { photo } = this.state;
    const { globalContext, content } = this.props;

    if (!this.isProcessStarted && photo && !photo.isPreviouslyMain) {
      this.moveToStep(STEP_NAME.deletePhoto);
    } else if (photo && photo.isPreviouslyMain) {
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        getEditingPhotoError(content.cantDeletePhotoAlert)
      );
    }
  };

  private deletePhoto = async () => {
    const { photo } = this.state;
    const { globalContext } = this.props;

    if (photo && !this.isProcessStarted) {
      this.startProcess();
      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      try {
        await PhotoService.deletePhoto(photo);
        globalContext.notifyListener(GlobalEventTypes.updateGallery, {
          callback: () =>
            globalContext.notifyListener(
              GlobalEventTypes.makeVisibleGlobalSpinner,
              false
            ),
        });
        this.closeFlow();
      } catch (error) {
        this.finishProcess();
      }
    }
  };

  private backToView = () => this.moveToStep(STEP_NAME.galleryPhoto);

  private editPhoto = () => {
    const { photo } = this.state;
    const { flowContext, content, globalContext } = this.props;

    if (photo && !photo.isPreviouslyMain) {
      flowContext.changeContext(FLOW_TYPES.editPhoto, { photo });
    } else {
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        getEditingPhotoError(content.cantDeletePhotoAlert)
      );
    }
  };

  private getGalleryPhotoView = (galleryPhotoProps: any) => (
    <GalleryPhotoView
      {...galleryPhotoProps}
      photo={this.state.photo}
      error={this.state.error}
    />
  );

  private getDeleteDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  private showHelpPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
}

export default compose(withGlobalContext, withFlowContext)(GalleryPhotoFlow);
