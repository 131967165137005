import React, { useEffect, useState } from "react";
import cx from "classnames";
import { v4 as uuid } from "uuid";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "component-library/src/images/svg/icons/upload.svg";
import { getMediaTypeByMimeType } from "shared-utils";
import { Shimmer } from "../../Atoms/Shimmer/Shimmer";
import { FileUploadState, IFileUpload } from "./models";
import {
  ICompleteFileUpload,
  IFileUploaderTexts,
  TitaniumFileUploader,
  uploadService,
} from "./TitaniumFileUploader";
import "./TitaniumUploader.scss";

export interface ITitaniumUploaderTexts {
  dropzoneLabel: string;
  preview: IFileUploaderTexts;
}

export interface ITitaniumUploaderProps {
  texts: ITitaniumUploaderTexts;
  accept?: string;
  multiUpload: boolean;
  invalid?: boolean;
  isDropzoneHidden?: boolean;
  maxUploadLimit?: number;
  onCompleteSuccess?: (props: ICompleteFileUpload) => void;
  onUploadStart?: (fileName?: string) => void;
  onMultipleUploadStart: (fileUploads: IFileUpload[]) => void;
  onFileRemove?: (id: string, callback: () => void) => void;
  preUploadValidation?: (preloadedFile: IFileUpload) => boolean;
  onRejectMultipleFiles?: () => void;
  onRenameFile?: (id: string, name: string) => void;
  isProcessingVisible?: boolean;
}

export const TitaniumUploader: React.FunctionComponent<
  ITitaniumUploaderProps
> = (props) => {
  const {
    onCompleteSuccess,
    onFileRemove,
    onUploadStart,
    multiUpload,
    invalid = false,
    isDropzoneHidden = false,
    accept = "*",
    isProcessingVisible,
  } = props;
  const [fileUploadList, setFileUploadList] = useState<IFileUpload[]>([]);
  const [isLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => uploadService.cancelUpload();
  }, []);

  const handleDropAccepted = async (files: File[]): Promise<void> => {
    const { maxUploadLimit } = props;

    if (
      maxUploadLimit &&
      props.onRejectMultipleFiles &&
      fileUploadList.length + files.length > maxUploadLimit
    ) {
      return props.onRejectMultipleFiles();
    }

    const uList = await Promise.all(
      Array.from(files).map(async (file) => {
        return {
          id: uuid(),
          file,
          mimeType: file.type,
          mediaType: getMediaTypeByMimeType(file.type),
          uploadState: FileUploadState.NotStarted,
          percentComplete: 0,
        };
      })
    );

    if (props.onMultipleUploadStart) {
      props.onMultipleUploadStart(uList);
    }

    const newUploadList = [...fileUploadList, ...uList];
    setFileUploadList(newUploadList);
  };

  const onDrop = (acceptedFiles: File[]) => handleDropAccepted(acceptedFiles);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUploadSuccess = (successProps: ICompleteFileUpload) => {
    if (onCompleteSuccess) {
      onCompleteSuccess(successProps);
    }
  };

  const handleUploadStart = (fileName?: string) => {
    if (onUploadStart) {
      onUploadStart(fileName);
    }
  };

  const handlePreUpload = (preloadedFile: IFileUpload) => {
    if (props.preUploadValidation) {
      return props.preUploadValidation(preloadedFile);
    }

    return true;
  };

  const handleRemoveFile = (id: string) => {
    const updatedFileUploadList = fileUploadList.filter(
      (file) => file.id !== id
    );
    if (onFileRemove) {
      onFileRemove(id, () => {
        setFileUploadList(updatedFileUploadList);
      });
    }
  };

  return (
    <div
      className={cx("c-file-uploader", { "c-file-uploader--error": invalid })}
    >
      {!isDropzoneHidden && isLoading ? (
        <div className="c-file-uploader__dropzone-loading">
          <Shimmer />
        </div>
      ) : (
        <div
          className={cx("c-file-uploader__dropzone", {
            "c-file-uploader__dropzone--error": invalid,
          })}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            {...getInputProps()}
            multiple={multiUpload}
            accept={accept}
            aria-label={props.texts.dropzoneLabel}
          />
          <div className="c-file-uploader__dropzone-text">
            {props.texts.dropzoneLabel} <UploadIcon />
          </div>
        </div>
      )}
      <div
        className={cx("c-file-uploader__files", {
          "c-file-uploader__files--error": invalid && fileUploadList.length,
        })}
      >
        {fileUploadList.length < 1 ? null : (
          <ul>
            {fileUploadList.map((file) => (
              <TitaniumFileUploader
                key={file.id}
                mediaType={getMediaTypeByMimeType(file.mimeType)}
                texts={props.texts.preview}
                preloadedFile={file}
                onComplete={handleUploadSuccess}
                onFileRemove={handleRemoveFile}
                onUploadStart={handleUploadStart}
                onPreUpload={handlePreUpload}
                onRenameFile={props.onRenameFile}
                isProcessingVisible={isProcessingVisible}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
