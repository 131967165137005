import React, { FC, useState, ChangeEvent } from "react";
import { useFormikScrollToError } from "shared-hooks";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import { FieldArray, useField } from "formik";
import { InputLabel } from "../../InputsCommon/InputLabel";
import { Button } from "../../Button/Button";
import "./styles.scss";

export interface Props {
  name: string;
  addText: string;
  label?: string;
  placeholder?: string;
  limit?: number;
  className?: string;
  annex?: JSX.Element;
  validateInput?: (value: string) => string | undefined;
}

const TextInputArrayFormik: FC<Props> = ({
  name,
  addText,
  label,
  placeholder,
  annex,
  limit,
  className,
  validateInput,
}) => {
  const [{ value }, meta] = useField<string[]>(name);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const { containerElementRef, fieldRef } = useFormikScrollToError<
    HTMLInputElement,
    HTMLDivElement
  >(name);

  const inputArrayClassName = cx("input-array", className);
  const invalid =
    (meta.touched && meta.error) || inputError ? ERROR : undefined;
  const limitReached = limit === value.length;

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const validationError = validateInput && validateInput(e.target.value);
    setInputValue(e.target.value);

    if (validationError && !inputError) {
      setInputError(validationError);
      return;
    }

    if (!validationError && inputError) setInputError("");
  };

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => {
        return (
          <div className={inputArrayClassName}>
            <div
              className="input-array__scroll-anchor"
              ref={containerElementRef}
            />
            <InputWrapper
              invalid={invalid}
              validationMesssage={inputError || meta.error}
            >
              <InputLabel label={label} annex={annex} htmlFor={name} />
              <div className="input-array__add">
                <input
                  data-testid={`${dataTestIds.componentLibrary["Atoms.FormikInputs.TextInputArray.input"]}${name}`}
                  className="input-array__add__input"
                  disabled={limitReached}
                  value={inputValue}
                  onChange={onChangeInput}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && inputValue && !inputError) {
                      arrayHelpers.push(inputValue);
                      setInputValue("");
                    }
                  }}
                  placeholder={placeholder}
                  ref={fieldRef}
                />
                <Button
                  className={cx(
                    "p-2 font-semibold text-white bg-color-brand-one w-auto border-none rounded-none",
                    {
                      "bg-grey-light":
                        !inputValue || limitReached || !!inputError,
                    }
                  )}
                  data-testid={`${dataTestIds.componentLibrary["Atoms.FormikInputs.TextInputArray.addButton"]}${name}`}
                  text={addText}
                  disabled={!inputValue || limitReached || !!inputError}
                  onClick={() => {
                    arrayHelpers.push(inputValue);
                    setInputValue("");
                  }}
                />
              </div>
              <div className="input-array__array">
                {value.map((value, index) => {
                  return (
                    <div
                      data-testid={`${dataTestIds.componentLibrary["Atoms.FormikInputs.TextInputArray.record"]}${index}`}
                      key={`${value}-${index}`}
                      className="input-array__array__item"
                    >
                      <div className="input-array__array__item__text">
                        {value}
                      </div>
                      <Button
                        data-testid={`${dataTestIds.componentLibrary["Atoms.FormikInputs.TextInputArray.removeButton"]}${index}`}
                        className="input-array__array__item__button"
                        iconName="icon-delete"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </div>
                  );
                })}
              </div>
            </InputWrapper>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default TextInputArrayFormik;
