import React from "react";

import { IMultiSelectItem, MultiSelectList } from "../MultiSelectList";

const MAX_ACTIVE_LOCATIONS_COUNT = 2;

export interface ILocationContent {
  locationSectionName: string;
  locationSectionDescription: string;
  locationField: string;
  locationPlaceholder: string;
}

export interface ILocationProps {
  model: ILocationsViewModel;
  handleChanges: (model: ILocationsViewModel) => void;
  searchForLocation: (value: string) => Promise<ILocationContract[]>;
  roundLocation: (placeId: string) => Promise<IGoogleLocationContract>;
  content: ILocationContent;
  name?: string;
  annex?: JSX.Element;
  invalid?: boolean;
  validationMessage?: string;
}

export interface ILocationViewModel {
  id: string;
  name: string;
}

export interface ILocationsViewModel {
  locations: ILocationViewModel[];
}

export interface ILocationContract {
  description: string;
  placeId: string;
}

export interface IGoogleLocationContract {
  country: string;
  id: string;
  administrativeAreaLevel1: string;
  administrativeAreaLevel2: string;
}

export default class LocationList extends React.Component<ILocationProps> {
  public render() {
    const {
      content,
      model: { locations },
      name = "location",
    } = this.props;

    const isDisabled = locations.length > MAX_ACTIVE_LOCATIONS_COUNT;
    const selectedLocations =
      this.convertLocationsToAutocompleteItems(locations);

    return (
      <MultiSelectList
        {...this.props}
        type="autocomplete"
        content={{
          label: content.locationField,
          placeholder: content.locationPlaceholder,
        }}
        name={name}
        isDisabled={isDisabled}
        selectedItems={selectedLocations}
        delayLoadTime={300}
        loadData={this.loadData}
        handleChanges={this.handleChanges}
      />
    );
  }

  private readonly convertLocationsToAutocompleteItems = (
    locations: ILocationViewModel[]
  ): IMultiSelectItem<string>[] =>
    locations.map((location) => ({ name: location.name, value: location.id }));

  private readonly handleChanges = async (
    newSelectedList: IMultiSelectItem<string>[]
  ) => {
    const { model } = this.props;
    let currentLocations = [...model.locations];
    let newLocationsList: ILocationViewModel[] = newSelectedList.map(
      (item) => ({ id: item.value, name: item.name })
    );

    let newLocations = newLocationsList.filter(
      (newLocation) =>
        currentLocations.findIndex(
          (oldLocation) => oldLocation.id === newLocation.id
        ) < 0
    );

    if (newLocations.length > 0) {
      for (const newLocation of newLocations) {
        const newRoundedLocation = await this.getRoundedLocation(newLocation);
        const canAddItem =
          currentLocations.findIndex(
            (location) => location.id === newRoundedLocation.id
          ) < 0;
        canAddItem && currentLocations.push(newRoundedLocation);
      }
    } else {
      currentLocations = newLocationsList;
    }

    this.props.handleChanges({ ...model, locations: currentLocations });
  };

  private readonly getRoundedLocation = async (
    location: ILocationViewModel
  ) => {
    const roundedLocation = await this.props.roundLocation(location.id);

    const newItem: ILocationViewModel = {
      name: this.getRoundedLocationName(roundedLocation),
      id: roundedLocation.id,
    };

    return newItem;
  };

  private readonly getRoundedLocationName = (
    location: IGoogleLocationContract
  ) => {
    const nameParts = [
      location.administrativeAreaLevel2,
      location.administrativeAreaLevel1,
      location.country,
    ];

    return nameParts.filter((item) => Boolean(item)).join(", ");
  };

  private readonly loadData = async (value: string) => {
    const locations = await this.props.searchForLocation(value);
    return locations
      .filter(
        (item: ILocationContract) =>
          !this.props.model.locations.some(
            (selectedLocation) => selectedLocation.id === item.placeId
          )
      )
      .map((item: ILocationContract) => ({
        name: item.description,
        value: item.placeId,
      }));
  };
}
