import React, { FC } from "react";
import { useQuery } from "react-query";
import { MediaType } from "shared-types";
import { getExternalMediaDataByProfileId } from "shared-services";
import { PopUpMediaViewer, SplashSpinner } from "../../../index";

interface Props {
  profileId: string;
  mediaType: MediaType;
  onClose: () => void;
}

const ExternalMediaViewer: FC<Props> = ({ profileId, mediaType, onClose }) => {
  const { data, isLoading } = useQuery(
    ["get-performer-external-media", profileId, mediaType],
    async () => {
      return await getExternalMediaDataByProfileId(mediaType, profileId);
    }
  );

  if (isLoading) return <SplashSpinner isOpen />;

  return (
    <PopUpMediaViewer
      isOpen={Boolean(data)}
      mediaType={mediaType}
      mediaList={data || []}
      onClose={onClose}
    />
  );
};

export default ExternalMediaViewer;
