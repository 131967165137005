export const nuLinkBoard = {
  "ProjectPopup.ProjectPopup.submitPopupButton": "submit-button",
  "ProjectPopup.ProjectPopup.cancelPopupButton": "cancel-button",
  "Table.Table.checkbox": "row-checkbox",
  "Table.Table.editButton": "row-edit-button",
  "Table.Table.rowLink": "row-link",
  "Breakdowns.Actions.button": "BUTTON_SPLIT_TRIGGER_TEST_ID",
  "BreakdownDetailsForm.RichTextArea.summary": "breakdown-summary",
  "BreakdownDetailsForm.nextButton": "next-button",
  "BreakdownDetailsForm.backButton": "back-button",
  "BreakdownDetailsForm.saveButton": "save-button",
  "BreakdownRoleForm.RichTextArea.description": "role-description",
  "BreakdownRoleForm.RichTextArea.feesAndFurtherInfo": "feesAndFurtherInfo",
  "BreakdownRoleForm.addAnotherRoleButton": "add-another-role",
  "BreakdownSendOptionsForm.nextButton": "next-button",
  "BreakdownSendOptionsForm.backButton": "back-button",
  "BreakdownSendOptionsForm.saveButton": "save-button",
  "BreakdownSendOptionsForm.expandGlobalList": "global-list",
  "BreakdownSendOptionsForm.expandMyList": "my-list",
  "BreakdownPreviewForm.sendButton": "send-button",
  "BreakdownPreviewForm.backButton": "back-button",
  "BreakdownPreviewForm.saveButton": "save-button",
  "BreakdownView.BreakdownView.projectOverviewExpand":
    "project-overview-expand",
  "BreakdownView.BreakdownView.expandAllRoles": "expand-all-roles",
  "BreakdownView.BreakdownView.collapseAllRoles": "collapse-all-roles",
  "Breakdown.BreakdownSectionTitle.title": "section-title",
  "Breakdown.BreakdownText.text": "breakdown-text",
  "Breakdown.BreakdownRole.anyGender": "Any",
  "Breakdown.BreakdownRole.specificGender": "Specific",
  "Breakdown.BreakdownRole.anyAppearance": "Any",
  "Breakdown.BreakdownRole.specificAppearance": "Specific",
  "BreakdownPreviewForm.formTitle": "preview-form-title",
  "ProjectBreakdown.BreakdownSort.sortDateDropDown": "sort-date-dropdown",
  "ProjectBreakdown.BreakdownFilter.filterStatusDropDown":
    "filter-status-dropdown",
  "ProjectBreakdown.BreakdownTable.cell": "row-cell",
  "ProjectBreakdown.BreakdownTable.titleCell": "title-cell",
  "ProjectBreakdown.BreakdownTable.contentCell": "content-cell",
  "ProjectBreakdown.BreakdownTable.subContentCell": "sub-content-cell",
  "ProjectBreakdown.BreakdownTable.rows": "table-row",
};
