import React from "react";
import { dataTestIds } from "data-testids";
import "./Footer.scss";
import { SocialList } from "../../Molecules/SocialList/SocialList";
import { Copyright } from "../../Molecules/Copyright/Copyright";
import { LinksListWithTitle } from "../../Molecules/LinksListWithTitle/LinksListWithTitle";
import { ILink } from "../../../generics/interfaces";
import { TFunction, useTranslation } from "i18n";

/**
 * Expected form of response from API when getting navigation links.
 * navLinks are actually for the header.
 */
export interface INavLinks {
  footerLinks1: ILink[];
  footerLinks2: ILink[];
  footerLinks3: ILink[];
}

export interface IFooterTexts {
  linkListsTitles: string[];
  followCTA: string;
  copyrightAddress: string;
}

export interface IFooterProps {
  /**
   * Navigation links for the footer and header, preferably /navlinks response from the Charisma API
   */
  navigationLinks: INavLinks;
  /**
   * Text elements to be used throughout the footer
   */
  texts: IFooterTexts;
  /** Optional CSS class for the topmost div */
  className?: string;
}

function mapLinkTextsKeyToTranslation(links: ILink[], t: TFunction) {
  return links.map((link) => ({
    ...link,
    title: t(link.title),
  }));
}

export const Footer: React.FC<IFooterProps> = ({
  navigationLinks,
  texts,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <footer className={`c-spotlight-footer${className ? " " + className : ""}`}>
      <div className="c-spotlight-footer__inner">
        <div className="c-spotlight-footer__navigation g-content-area">
          <div
            className="g-col-lg-3 g-col-md-4"
            data-testid={
              dataTestIds.componentLibrary["Organisms.Footer.footerLinks"]
            }
          >
            <LinksListWithTitle
              links={mapLinkTextsKeyToTranslation(
                navigationLinks.footerLinks1,
                t
              )}
              titleText={t(texts.linkListsTitles[0])}
            />
          </div>
          <div
            className="g-col-lg-3 g-col-md-4"
            data-testid={
              dataTestIds.componentLibrary["Organisms.Footer.footerLinks"]
            }
          >
            <LinksListWithTitle
              links={mapLinkTextsKeyToTranslation(
                navigationLinks.footerLinks2,
                t
              )}
              titleText={t(texts.linkListsTitles[1])}
            />
          </div>
          <div
            className="g-col-lg-3 g-col-md-4"
            data-testid={
              dataTestIds.componentLibrary["Organisms.Footer.footerLinks"]
            }
          >
            <LinksListWithTitle
              links={mapLinkTextsKeyToTranslation(
                navigationLinks.footerLinks3,
                t
              )}
              titleText={t(texts.linkListsTitles[2])}
            />
          </div>
          <div className="g-col-lg-9 g-col-md-8 g-hidden-sm" />
          <div className="g-col-lg-3 g-col-md-4">
            <div className="c-spotlight-footer__social">
              <SocialList followCTA={t(texts.followCTA)} />
            </div>
          </div>
        </div>
      </div>
      <Copyright address={t(texts.copyrightAddress)} />
    </footer>
  );
};

export default Footer;
