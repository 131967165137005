import _map from "lodash/map";
import _sortBy from "lodash/sortBy";
import { IFacet } from "shared-services";

export function joinSortedFacetListNames(
  facetList: IFacet[],
  settings?: { separator: string }
): string {
  const separator = settings?.separator || ", ";

  return _map(_sortBy(facetList, "name"), "name").join(separator);
}
