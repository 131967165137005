import { ICheckboxGroupValue } from "../../CheckboxGroup/CheckboxGroup";
import { IFacet } from "shared-services";
import { FiltersFieldNames } from "../types";
import { IFilterTagProps } from "../../../Atoms/FilterTag/FilterTag";

export interface GetCheckboxFacetsProps {
  name: FiltersFieldNames;
  fieldValue: ICheckboxGroupValue;
  fieldFacets: IFacet[];
  getText: (name: string, tagName?: string) => string;
  getOnClick: (
    name: string,
    fieldValue: ICheckboxGroupValue,
    value: string
  ) => () => void;
}

export function getCheckboxFacetTags({
  fieldValue,
  fieldFacets,
  name,
  getText,
  getOnClick,
}: GetCheckboxFacetsProps) {
  return Object.keys(fieldValue).reduce<IFilterTagProps[]>((acc, value) => {
    if (fieldValue[value]) {
      const tagName = fieldFacets.find(
        (facet) => facet.id === Number(value)
      )?.name;

      return [
        ...acc,
        {
          id: `${name}-${value}`,
          text: getText(name, tagName),
          onClick: getOnClick(name, fieldValue, value),
        },
      ];
    }
    return acc;
  }, []);
}
