import React from "react";
import { useTranslation } from "i18n";
import { MediaType } from "shared-types";
import { ReactComponent as ProcessingIcon } from "component-library/src/images/svg/icons/media-time.svg";
import { SonicWaveImage } from "../../../../index";
import "./ThumbnailVideoViewer.scss";

export interface IThumbnailVideoViewerProps {
  title?: string;
  playback: () => void;
  thumbnailUrl?: string;
  isLoaded: boolean;
  mediaType?: MediaType;
  isProcessingVisible?: boolean;
}

export const ThumbnailViewer: React.FC<IThumbnailVideoViewerProps> = ({
  title,
  playback,
  thumbnailUrl,
  mediaType,
  isLoaded,
  isProcessingVisible = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="c-thumbnail-video-viewer">
      <div className="c-thumbnail-video-viewer__preview">
        {!isLoaded && isProcessingVisible && (
          <div className="c-thumbnail-video-viewer__loading">
            <div className="c-thumbnail-video-viewer__loading-cover">
              <ProcessingIcon />
              {t("common:label.processing")}
            </div>
          </div>
        )}
        {isLoaded && (
          <div className="c-thumbnail-video-viewer__cover" onClick={playback}>
            <img
              src={
                mediaType === MediaType.Video ? thumbnailUrl : SonicWaveImage
              }
              alt={title}
            />
            <span className="icon-playsmall" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ThumbnailViewer;
