import React, { ReactNode, JSX } from "react";
import cx from "classnames";
import { useTranslation, TFunction } from "i18n";
import { dataTestIds } from "data-testids";
import { LinkTarget } from "../../../../generics/types";
import { IconTextLink } from "../../../Atoms/IconTextLink/IconTextLink";
import "./MainNavigationItem.scss";

export interface IMainNavigationItemProps {
  /** The text for the link */
  text: string;
  /** Render label */
  RenderLabel?: (t: TFunction) => JSX.Element;
  /** Render SVG icon */
  svgIconStart?: ReactNode;
  /** Divider */
  divider?: boolean;
  /** URL to go to if the label is used as a link */
  linkUrl: string;
  /** Optional icon source */
  icon?: string;
  /** Handler for interacting with the button via click, Enter or Spacebar keys */
  handleClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
  /** Optional CSS class for the topmost div */
  className?: string;
  /** Active state for link */
  isSelected?: boolean;
  /** Optional link target */
  target?: LinkTarget;
}

export const MainNavigationItem: React.FC<IMainNavigationItemProps> = ({
  text,
  RenderLabel,
  svgIconStart,
  divider,
  linkUrl,
  icon,
  handleClick,
  className,
  isSelected,
  target,
  children,
}) => {
  const { t } = useTranslation();

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (handleClick) {
      handleClick(event, text, linkUrl);
    }
  };

  if (divider) {
    return <div className="h-[1px] w-full bg-grey-medium-3 my-2.5" />;
  }

  return (
    <div
      className={cx(
        "c-main-nav-item",
        {
          "c-main-nav-item__selected": isSelected,
        },
        className
      )}
    >
      <div
        className="c-main-nav-item__container"
        data-testid={
          dataTestIds.componentLibrary[
            "Organisms.NavigationHeader.MainNavigationItem.mainNavigationLink"
          ]
        }
      >
        <IconTextLink
          text={t(text)}
          renderLabel={RenderLabel && RenderLabel(t)}
          svgIconStart={svgIconStart}
          variant="mainnav"
          active={isSelected}
          imageSourceUrl={icon}
          linkUrl={linkUrl}
          target={target}
          handleClick={handleItemClick}
        />
      </div>
      {children && <div className="c-main-nav-item__child">{children}</div>}
    </div>
  );
};

export default MainNavigationItem;
