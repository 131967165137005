import React from "react";
import cx from "classnames";
import { useField } from "formik";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";

import { InputLabel } from "../../InputsCommon/InputLabel";
import "./TextAreaFormik.scss";

const DEFAULT_ROWS = 2;

export interface ITextAreaFormikProps {
  name: string;
  label?: string;
  placeholder?: string;
  annex?: JSX.Element;
  resizeable?: boolean;
  minLength?: number;
  maxLength?: number;
  boldLabel?: boolean;
  dataTestID?: string;
  showWordCountInsteadOfMinLength?: boolean;
  rows?: number;
  inputWrapperClassName?: string;
}

export const TextAreaFormik: React.FC<ITextAreaFormikProps> = ({
  name,
  label,
  placeholder,
  annex,
  resizeable,
  minLength = 0,
  maxLength,
  boldLabel = false,
  dataTestID = "",
  rows = DEFAULT_ROWS,
  showWordCountInsteadOfMinLength = false,
  inputWrapperClassName,
}) => {
  const [field, meta] = useField(name);
  const invalid = meta.touched && meta.error ? ERROR : undefined;

  return (
    <InputWrapper
      invalid={invalid}
      validationMesssage={meta.error}
      className={inputWrapperClassName}
    >
      <InputLabel label={label} annex={annex} htmlFor={name} bold={boldLabel} />
      <textarea
        {...field}
        id={name}
        data-testid={dataTestID}
        className={cx("text-area-formik", {
          "text-area-formik--resize-true": resizeable,
          "text-area-formik--resize-false": !resizeable,
          "text-area-formik--invalid": Boolean(invalid),
          "text-area-formik--with-length": Boolean(maxLength),
          "text-area-formik--custom-rows": rows > DEFAULT_ROWS,
        })}
        minLength={minLength}
        maxLength={maxLength}
        rows={rows}
        placeholder={placeholder}
      />
      {maxLength && (
        <span className="c-input-wrapper__text-length-below">
          {showWordCountInsteadOfMinLength ? field?.value?.length : minLength}/
          {maxLength}
        </span>
      )}
    </InputWrapper>
  );
};
