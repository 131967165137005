import { ILoginPageTranslations } from "../../models/joining-pages/LoginPage";

export const LoginPageTranslations: ILoginPageTranslations = {
  loginDetails: {
    loginPageTitle: "Welcome to the Spotlight Performer Application",
    loginPageTitleYP: "Welcome to the Spotlight Young Performer Application",
    loginPageTitleCD: "Welcome to the Spotlight Casting Director Application",
    loginPageCriteria:
      "To apply to join Spotlight you will need to meet one of the following criteria",
    loginPageCriteriaMainYP: "You will need:",
    loginPageCriteriaPoint1YP: "A headshot of the the Young Performer",
    loginPageCriteriaPoint2YP: "Card details for payment",
    loginPageCardDivider: "OR",
    loginPageCardExperience: `<b>Experience</b> - You must have <b>one</b> featured role with an <a href="#">Equity</a> or equivalent union contract OR <b>two</b> professional performance credits`,
    loginPageCardTraining: `<b>Training</b> - You must have complete at least a year’s professional performance training`,
    loginPageCardRecommendation: `<b>Recommendation</b> - You must have a recommendation from a member of the <a href="#">PMA, CDG, CSA, CDA, CPMA</a>`,
    loginPageProcessTime:
      "The application will take about 30 minutes. You will be able to leave the application and complete it later using the password we will send you via email. ",
    loginPageProcessTimeYP:
      "The application will take about 30 minutes. You will be able to leave the application and complete it later using the password we will send you via email.",
    loginPageProcessTimeCD:
      "The application will take about 30 minutes. You will be able to leave the application and complete it later using the password we will send you via email.",
  },
  loginForm: {
    loginTitle: "Already started your application?",
    loginDescription:
      "If you have already started your application and wish to continue, please enter your email and password",
    loginEmailLabel: "Email address:",
    loginPasswordLabel: "Password:",
    loginContinueButton: "Continue application",
    loginForgotPassword: "Forgotten your password?",
    loginWrongDetailsError: "Invalid email or password. Please try again.",
  },
  signupForm: {
    signupSectionNameHeaderYP: "Young Performer details",
    signupSectionNameHeaderCD: "Casting Director details:",
    signupSectionParentDetailsHeaderYP: "Parent or Guardian details",
    signupWhySLRequireThis: `<a href="#">Why does Spotlight require this?</a> See our <a href="#">Privacy Policy</a>.`,
    signupFirstNameLabel: "First name:",
    signupLastNameLabel: "Second name:",
    signupCheckNameLabel: "Check name availability",
    signupMobileLabel: "Mobile number:",
    signupLandlineLabel: "Landline (optional)",
    signupCompanyNameLabel: "Company name:",
    signupCompanyWebsiteLabel: "Company website:",
    signupYourOccupationLabel: "Your ocupation:",
    signupDOBLabel: "Date of birth:",
    signupCheckNameErrorTitle:
      "This name is already taken. Please try a different one.",
    signupCheckNameErrorDescription:
      "It's essential for all performers to have a unique name - imagine if a casting professional confused you with Keira Knightley or Jude Law! Why not try another name...",
    signupCheckNameSuccessTitle:
      "This exact name is not currently used by another Spotlight performer",
    signupCheckNameSuccessDescription:
      "If you apply now, you may be able to have this name. However, please be aware that there may be a performer already using a similar name but don't worry, we can work with you to find the right name for you.",
    signupEmailLabel: "Email address:",
    signupTermsLabel: `We value the trust placed in us and want to ensure that you understand what membership of Spotlight means and how we will process your information in the course of your membership with us. Please confirm that you have read and accept our <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a>.`,
    signupTermsErrorLabel: "Terms and conditions",
    signupContinueButton: "Continue",
    signupContinueButtonYP: "Continue",
    signupContinueButtonCD: "Continue",
    signupHadSpotlightAccountLabel:
      "Have you ever had a Spotlight account in any capacity?",
    signupMemberOfProfessionalOrgLabel:
      "Are you a member of a professional organisation of casting professionals? e.g. CDG/CSA.",
    signupMemberOfProfessionalOrgYesDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscingelit. Velit congue blandit mauris vel amet. Orcidiam id ipsum blandit elit.",
    signupPleaseSpecifyLabel: "Please specify:",
    signupConfirmMembershipDetails:
      "Please confirm your details registered with this membership.",
    signupMembershipNameLabel: "Name:",
    signupMembershipEmailLabel: "Email address:",
    signupCurrentMembershipNumberLabel: "Membership number (optional):",
    memberTypeLabel: "Member type:",
    signupExistingSpotlightAccEmailLabel:
      "Your previously registered Spotlight email address:",
  },
  forgotPasswordForm: {
    forgotPasswordTitle: "Forgotten password",
    forgotPasswordDescription:
      "Enter your e-mail address and click 'Send Reminder', and we'll send you a reminder of your temporary password.",
    forgotPasswordEmailLabel: "Email address:",
    forgotPasswordContinueButton: "Send reminder",
    forgotPasswordCancelButton: "Cancel ",
    forgotPasswordSending: "Sending",
    forgotPasswordReminderSent: "Reminder sent",
    forgotPasswordReminderDescription: `Please check your email box for your password. If you have any further issues please <a href="#">contact us</a>.`,
    forgotPasswordReminderError:
      "Sorry, we couldn't find your e-mail address. Please try again or contact us to obtain your temporary password.",
    forgotPasswordReminderCancel: "Close",
  },
  signupFormTooltips: {
    signupDOBYPTooltipHeader: "YP DOB Tooltip HEADER",
    signupDOBYPTooltipBody: "YP DOB Tooltip BODY",
    signupYPParentTooltipHeader: "YP Parent / Guardian Tooltip HEADER",
    signupYPParentTooltipBody: "YP Parent / Guardian Tooltip BODY",
    signupYPMobileNumberTooltipHeader: "YP Mobile Number Tooltip HEADER",
    signupYPMobileNumberTooltipBody: "YP Mobile Number Tooltip BODY",
    signupCDEmailTooltipHeader: "Tooltip Header",
    signupCDEmailTooltipBody: "Tooltip Body",
    signupCDCompanyNameTooltipHeader: "Tooltip Header",
    signupCDCompanyNameTooltipBody: "Tooltip Body",
    signupCDCompanyWebsiteTooltipHeader: "Tooltip Header",
    signupCDCompanyWebsiteTooltipBody: "Tooltip Body",
    signupCDProfOrgMembTypeTooltipHeader: "Tooltip Header",
    signupCDProfOrgMembTypeTooltipBody: "Tooltip Body",
    signupCDExistingAccNameTooltipHeader: "Tooltip Header",
    signupCDExistingAccNameTooltipBody: "Tooltip Body",
    signupCDExistingAccMembTypeTooltipHeader: "Tooltip Header",
    signupCDExistingAccMembTypeTooltipBody: "Tooltip Body",
    signupCDExistingAccMembEmailTooltipHeader: "Tooltip Header",
    signupCDExistingAccMembEmailTooltipBody: "Tooltip Body",
    signupWhySLRequireThisTooltipHeader: "Why SL Require This Tooltip Header",
    signupWhySLRequireThisTooltipBody: "Why SL Require This Tooltip Body",
  },
};
