import React from "react";
import { Popup, AlertMessage, IAlertMessageProps } from "component-library";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";

import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo } from "models";
import {
  IChangeMainPhotoViewProps,
  ChangeMainPhotoView,
} from "./ChangeMainPhotoView";
import {
  PopupFlowBaseComponent,
  IPopupFlowBaseComponentState,
} from "../PopupFlowBaseComponent";
import {
  IChangeMainPhotoFlowContent,
  getConfirmationDialogSettings,
} from "./ChangeMainPhotoFlowContent";
import { FLOW_TYPES } from "flows";
import { IErrorNotificationData } from "shared/modules/Common/GlobalAlert";
import compose from "shared/utils/compose";

const STEP_NAME = {
  photoGallery: "photo-gallery",
  confirm: "confirm",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export interface IChangeMainPhotoFlowProps
  extends IFlowContextProps,
    IGlobalContextProps {
  photosPromise: () => Promise<Photo[]>;
  content: IChangeMainPhotoFlowContent;
}

interface IChangeMainPhotoFlowState extends IPopupFlowBaseComponentState {
  photos: Photo[];
  selectedPhoto?: Photo;
  error?: string;
  showContent: boolean;
}

export class ChangeMainPhotoFlow extends PopupFlowBaseComponent<
  IChangeMainPhotoFlowProps,
  IChangeMainPhotoFlowState
> {
  public readonly state: Readonly<IChangeMainPhotoFlowState> = {
    currentStep: STEP_NAME.photoGallery,
    photos: [],
    showContent: true,
  };

  private photosPromise: ICancelablePromise;
  private isProcessStarted = false;

  constructor(props: IChangeMainPhotoFlowProps) {
    super(props);

    this.makeFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    let view: React.ReactNode = null;

    const { showContent } = this.state;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public async componentDidMount() {
    this.photosPromise = makeCancelable(this.props.photosPromise());

    try {
      const photos = await this.photosPromise.promise;
      this.setState({ photos });
    } catch (error) {
      const { content } = this.props;
      if (!error.isCanceled) {
        this.setState({ error: content.photoLoadingError });
      }
    }
  }

  public componentWillUnmount() {
    this.photosPromise && this.photosPromise.cancel();
  }

  private makeFlowSteps = () => {
    const { content } = this.props;

    this.flowSteps = [
      {
        name: STEP_NAME.photoGallery,
        component: this.getPhotoGallery,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          content.photoGalleryTexts,
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          cancel: this.closeFlow,
          content: content.photoGalleryTexts,
          changeMainPhoto: this.changeMainPhoto,
          addPhoto: this.addPhoto,
        },
      },
      {
        name: STEP_NAME.confirm,
        component: this.getConfirmationDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.confirmationDialog,
          this.closeFlow
        ),
        settings: getConfirmationDialogSettings({
          confirmationDialog: content.confirmationDialog,
          save: this.save,
          close: this.closeFlow,
        }),
      },
    ];
  };

  private getPhotoGallery = (mainPhotoViewProps: IChangeMainPhotoViewProps) => (
    <ChangeMainPhotoView
      {...mainPhotoViewProps}
      photos={this.state.photos}
      error={this.state.error}
    />
  );

  private getConfirmationDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  private closeFlow = () => this.props.flowContext.changeContext("", null);

  private save = async () => {
    if (!this.isProcessStarted) {
      this.isProcessStarted = true;

      const { globalContext } = this.props;
      const { selectedPhoto } = this.state;

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      if (selectedPhoto) {
        try {
          this.setState({ showContent: false });
          await PhotoService.makeMainPhoto(selectedPhoto);
          globalContext.notifyListener(GlobalEventTypes.updateMainPhoto, {
            showDialog: true,
          });
          globalContext.notifyListener(GlobalEventTypes.changeMainPhoto);
          globalContext.notifyListener(GlobalEventTypes.updateGallery);
          this.closeFlow();
        } catch (error) {
          this.setState({ showContent: true });
          const errorData: IErrorNotificationData = {
            errorType: error.status,
            error,
          };
          this.props.globalContext.notifyListener(
            GlobalEventTypes.errorNotification,
            errorData
          );
        }
      }

      this.props.globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        false
      );
    }
  };

  private changeMainPhoto = (selectedPhoto: Photo) => {
    this.setState({ selectedPhoto });
    this.moveToStep(STEP_NAME.confirm);
  };

  private addPhoto = (photo: Photo) =>
    this.props.flowContext.changeContext(FLOW_TYPES.uploadingPhoto, { photo });
}

export default compose(withGlobalContext, withFlowContext)(ChangeMainPhotoFlow);
