import React from "react";
import cx from "classnames";
import { useTranslation } from "i18n";
import "./BottomNav.scss";
import { ILink } from "../../../../generics/interfaces";

export interface IBottomNavProps {
  /** Optional css class for the topmost div */
  className?: string;
  /**
   * List of navigation links
   */
  links: ILink[];
}

export const BottomNav: React.FC<IBottomNavProps> = (
  props: IBottomNavProps
) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "c-bottom-menu",
        props.className ? `${props.className}` : ""
      )}
    >
      {props.links.map((link, index) => {
        const title = t(link.title);
        return (
          <a
            className="c-bottom-menu__link"
            key={index}
            href={link.href}
            title={title}
            target={link.href.startsWith("http") ? "_blank" : "_self"}
          >
            {title}
          </a>
        );
      })}
    </div>
  );
};
