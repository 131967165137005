export const shortlists = {
  "Dialog.MoveCopyShortlistPopup.dropDownInput": "DROPDOWN_INPUT_TEST_ID",
  "ShortlistEditTitle.ShortlistEditTitle.input": "SHORTLIST_EDIT_TITLE_TEST_ID",
  "ShortlistPerformerTableWrapper.ShortlistPerformerTableWrapper.editRunningOrderPopup":
    "EDIT_RUNNING_ORDER_POPUP_TEST_ID",
  "ShortlistPerformerTableWrapper.ShortlistPerformerTableWrapper.copyButton":
    "COPY_BUTTON_TEST_ID",
  "ShortlistPerformerTableWrapper.ShortlistPerformerTableWrapper.moveButton":
    "MOVE_BUTTON_TEST_ID",
  "ShortlistPerformerTableWrapper.ShortlistPerformerTableWrapper.removePerformerButton":
    "REMOVE_PERFORMERS_TEST_ID",
  "ShortlistTable.ShortlistTable.row": "SHORTLIST_TABLE_ROW",
  "ShortlistTable.ShortlistTable.rowCheckBox": "SHORTLIST_TABLE_checkbox",
  "ShortlistTable.SplitButton.actions.archive": "archive-shortlist",
  "ShortlistTable.SplitButton.actions.delete": "delete-shortlist",
  "ShortlistTable.SplitButton.actions.restore": "restore-shortlist",
  "ShortlistTable.SplitButton.actions.projectFolders": "project-folders",
  "Shortlist.AlertButton.delete": "ALERT_BUTTON_TEST_ID_STUB-Delete shortlist",
  "Shortlist.WithPagination.contentArea": "CONTENT_AREA_TEST_ID",
};
