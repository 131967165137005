import React, { FC, useState } from "react";
import { BillingUser } from "translations/src/models/billing";
import { BillingContextProvider } from "../../../BillingContext";
import { CheckoutFlow, IAddOns } from "../../../types";
import AddOnPreview from "./components/AddOnPreview";
import AddOnPurchase from "./components/AddOnPurchase";
import { AddOnPurchaseFormRef } from "./types";

export enum AddOnPurchaseStep {
  Preview,
  Checkout,
}

interface Props {
  step: AddOnPurchaseStep;
  purchaseFormRef: AddOnPurchaseFormRef;
  onSuccessCallback: (trackingData?: any) => void;
  data: IAddOns[];
  isLoading: boolean;
  texts?: Record<string, string>;
}

const AddOnPurchaseSteps: FC<Props> = ({
  step,
  purchaseFormRef,
  onSuccessCallback,
  data,
  isLoading,
  texts,
}) => {
  const [addOn, setAddOn] = useState<IAddOns>();

  return (
    <BillingContextProvider
      billingUserType={BillingUser.Performer}
      checkoutFlow={CheckoutFlow.SubscriptionsModals}
      disableDiscountCodeField
      presetExistingPaymentMethod
    >
      {step === AddOnPurchaseStep.Preview && (
        <AddOnPreview
          setAddOn={setAddOn}
          data={data}
          isLoading={isLoading}
          texts={texts}
        />
      )}
      {AddOnPurchaseStep.Checkout === step && addOn && (
        <AddOnPurchase
          addOn={addOn}
          purchaseFormRef={purchaseFormRef}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </BillingContextProvider>
  );
};

export default AddOnPurchaseSteps;
