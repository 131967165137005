import React, { FC, useMemo, useState } from "react";
import _lowerCase from "lodash/lowerCase";
import { useTranslation } from "i18n";
import "./styles.scss";
import {
  CheckboxGroup,
  ICheckboxGroupConfig,
  ICheckboxGroupValue,
} from "../CheckboxGroup/CheckboxGroup";
import {
  FilterMode,
  FilterModeSection,
} from "../../Molecules/FilterModeSection";
import { InputSearchIcon } from "../../Atoms/InputsCommon/InputSearchIcon";
import { InputCleaner } from "../../Atoms/InputsCommon/InputCleaner";
import { Input } from "../../Molecules/Input/Input";

interface Props {
  heritageAndEthnicityConfig: ICheckboxGroupConfig;
  heritageAndEthnicityValues: ICheckboxGroupValue;
  ethnicBackgroundConfig: ICheckboxGroupConfig;
  ethnicBackgroundValue: ICheckboxGroupValue;
  handleEthnicBackground: (value: ICheckboxGroupValue) => void;
  handleHeritageAndEthnicity: (value: ICheckboxGroupValue) => void;
  filterModeDefaultValue: FilterMode;
  handleFilterMode: (mode: FilterMode) => void;
  heritageAndEthnicityInfo: string;
  handleHeritageAndEthnicityInfo: (value: string) => void;
}

const HeritageAndEthnicityFilterControlSection: FC<Props> = ({
  heritageAndEthnicityConfig,
  ethnicBackgroundConfig,
  handleEthnicBackground,
  handleHeritageAndEthnicity,
  ethnicBackgroundValue,
  heritageAndEthnicityValues,
  filterModeDefaultValue,
  handleFilterMode,
  heritageAndEthnicityInfo,
  handleHeritageAndEthnicityInfo,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");

  const ethnicBackgroundOptions = useMemo(
    () =>
      searchValue
        ? ethnicBackgroundConfig.options.filter(({ label }) => {
            return _lowerCase(label).includes(_lowerCase(searchValue));
          })
        : ethnicBackgroundConfig.options,
    [searchValue, ethnicBackgroundConfig]
  );

  const heritageAndEthnicityOptions = useMemo(
    () =>
      searchValue
        ? heritageAndEthnicityConfig.options.filter(({ label }) => {
            return _lowerCase(label).includes(_lowerCase(searchValue));
          })
        : heritageAndEthnicityConfig.options,
    [searchValue, heritageAndEthnicityConfig]
  );

  return (
    <div className="heritage-and-ethnicity-filter-section">
      <FilterModeSection
        translationKey={"heritage.ethnicity.filter.mode"}
        updateFilterMode={handleFilterMode}
        filterModeDefaultValue={filterModeDefaultValue}
      />
      <div className="c-checkbox-filter__search">
        <Input
          className="g-with-search-icon"
          type="text"
          placeholder={t("common:label.search")}
          value={searchValue}
          notifying={false}
          isFormSubmitted={true}
          onChange={(e) => setSearchValue(e.target.value)}
        >
          <InputSearchIcon />
          <InputCleaner onClick={() => setSearchValue("")} />
        </Input>
      </div>
      <CheckboxGroup
        className="c-checkbox-filter__list"
        config={{
          ...ethnicBackgroundConfig,
          options: ethnicBackgroundOptions,
        }}
        value={ethnicBackgroundValue}
        valueChanged={handleEthnicBackground}
        skipValidation
      />
      <div className="w-full h-[1px] my-5 bg-grey-light-2" />
      <div className="further-information-search">
        <Input
          label={t("common:heritage.ethnicity.label.search")}
          type="text"
          placeholder={t("common:label.search")}
          value={heritageAndEthnicityInfo}
          notifying={false}
          isFormSubmitted={true}
          onChange={(e) => handleHeritageAndEthnicityInfo(e.target.value)}
        >
          <InputCleaner onClick={() => handleHeritageAndEthnicityInfo("")} />
        </Input>
      </div>
      <div className="font-semibold mb-[10px]">
        {t("common:heritage.ethnicity.label.specific.colon")}
      </div>
      <CheckboxGroup
        className="c-checkbox-filter__list"
        config={{
          ...heritageAndEthnicityConfig,
          options: heritageAndEthnicityOptions,
        }}
        value={heritageAndEthnicityValues}
        valueChanged={handleHeritageAndEthnicity}
        skipValidation
      />
    </div>
  );
};

export default HeritageAndEthnicityFilterControlSection;
