import { authAxios as axios } from "shared-auth";
import { getPerformersSearchText } from "./getPerformersSearchText";
import { IPerformerSearchResult } from "../../../NavigationHeader";
import { PerformerType } from "../../../../../index";
import { config } from "../../../../../config";

export interface IPerformer {
  forename: string;
  surname: string;
  performerType: PerformerType;
  viewPin: string;
}

export const unifyUrlsConfigs = (url: string) =>
  url.endsWith("/") ? url.slice(0, -1) : url;

export const getCDSearchConfig = () => ({
  content: {
    ...getPerformersSearchText(),
  },
  loadSearchResults: async (
    searchTerm: string
  ): Promise<IPerformerSearchResult[]> => {
    const response = await axios.post<{
      profiles: IPerformer[];
      total: number;
    }>(`${unifyUrlsConfigs(config.searchAndFiltersApiUrl)}/search`, {
      skip: 0,
      take: 11,
      performerName: searchTerm,
      orderBy: "Relevance",
    });

    return response.data.profiles.map(
      (item: IPerformer): IPerformerSearchResult => ({
        url: `${unifyUrlsConfigs(config.siteRootUrl)}/${item.viewPin}`,
        name: item.forename,
        surname: item.surname,
        type: item.performerType,
      })
    );
  },
  showMoreResults: (searchTerm: string) =>
    window.open(`/casting/performers?performerName=${searchTerm}`, "_blank"),
});
