export const nuJobsApp = {
  "MakeSuggestionsView.Card.TagminAvailabilityCalendar.TagminAvailabilityCalendar.handleOpen":
    "see-more-toggler",
  "MakeSuggestionsView.Card.TagminAvailabilityCalendar.CalendarRecord.individualRecord":
    "individual-record",
  "MakeSuggestionsView.Card.TagminAvailabilityCalendar.CalendarRecord.groupedRecordToggler":
    "grouped-record-toggler",
  "PerformerResultsClassic.PerformerResultsClassic.performerResultsRow":
    "performer-row",
  "PerformerResultsClassic.PerformerResultsClassic.performerEmptyRow":
    "performer-row-empty",
  "PerformerResultsPremium.PerformerResultsPremium.quickView": "quickView",
  "MySuggestionsView.MySuggestionsView.downloadSuggestionsButton":
    "DownloadSuggestionsButtonTestId",
  "MySuggestions.MySuggestions.searchInput": "search roles",
  "Components.SelectAllControls.markAsRead": "markAsRead",
  "Components.SelectAllControls.markAsUnread": "markAsUnread",
  "Components.Breakdown.breakdownMessage": "breakdown-message",
  "SelfTapeRequest.Title.header": "selftapeRequest-title-header",
  "SelfTapeRequest.Title.breakDownTitle":
    "selftapeRequest-title-breakDownTitle",
  "SelfTapeRequest.Info.description": "selftapeRequest-info-description",
  "SelfTapeRequest.Info.videoInstruction":
    "selftapeRequest-info-videoInstruction",
  "SelfTapeAcceptRejectBlock.Response.Banner": "selfTapeRequest-response",
  "SelfTapeRequests.SendToClientModal.sendToClientButton": "sendToClientButton",
  "SelfTapeRequests.AcceptModal.acceptButton": "acceptButton",
};
