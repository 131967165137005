import React, { FC, useContext } from "react";
import { useTranslation } from "i18n";
import Section from "../Section";
import PaymentMethodButton from "./PaymentMethodButton";
import {
  CheckoutFlow,
  ExistingPaymentMethod,
  ISubscription,
  PaymentMethodType,
  PlanCodeMethod,
  PlanCodePeriod,
} from "../../types";
import "./styles.scss";
import { BillingContext } from "../../BillingContext";
import { Spinner } from "../../../../Atoms/Spinner/Spinner";
import ExistingPaymentMethodBlock from "./ExistingPaymentMethodBlock/ExistingPaymentMethodBlock";
import { useGetSubscriptions } from "../../hooks/useGetSubscriptions";
import { BillingUser } from "translations/src/models/billing";

interface Props {
  existingPaymentMethodData: ExistingPaymentMethod | undefined;
  existingPaymentMethodIsLoading: boolean;
  existingPaymentMethodIsError: boolean;
  selectedMethod?: PlanCodeMethod;
  setPaymentMethod: (paymentMethodType: PlanCodeMethod) => void;
}

const PaymentMethodBlock: FC<Props> = ({
  selectedMethod,
  setPaymentMethod,
  existingPaymentMethodData,
  existingPaymentMethodIsLoading = false,
  existingPaymentMethodIsError = false,
}) => {
  const { t } = useTranslation();
  const {
    setUseExistingPaymentMethod,
    useExistingPaymentMethod,
    billingUserType,
    checkoutFlow,
    planPeriodData: { selectedPeriod },
  } = useContext(BillingContext);

  const { data: subscriptionsData } = useGetSubscriptions();

  const method =
    existingPaymentMethodData?.paymentMethodType ===
    PaymentMethodType.CreditCard
      ? PlanCodeMethod.CreditCard
      : PlanCodeMethod.DirectDebit;

  const handleSelectExistingPaymentMethod = () => {
    setUseExistingPaymentMethod(!useExistingPaymentMethod);
    setPaymentMethod(method);
  };

  const handleSelectPaymentMethod = (planCodeMethod: PlanCodeMethod) => {
    setPaymentMethod(planCodeMethod);
    setUseExistingPaymentMethod(false);
  };

  const isGraduate =
    billingUserType === BillingUser.JoiningGraduate ||
    checkoutFlow === CheckoutFlow.GraduateTransfer;
  const isMonthly = selectedPeriod === PlanCodePeriod.Monthly;

  const renderExistingPaymentMethod = () => {
    if (existingPaymentMethodIsError) {
      setUseExistingPaymentMethod(false);
      return null;
    }

    // For some reason this useQuery sets the response from a 404 as data, instead of throwing an error. Instead checking for a value that must exist on successful call.
    if (existingPaymentMethodData?.paymentMethodType) {
      if (
        existingPaymentMethodData?.paymentMethodType ===
          PaymentMethodType.CreditCard &&
        isMonthly &&
        isGraduate
      ) {
        return null;
      }

      if (existingPaymentMethodIsLoading) return <Spinner inline />;
      else {
        return (
          <PaymentMethodButton
            isSelected={useExistingPaymentMethod}
            onSelect={handleSelectExistingPaymentMethod}
            variant="secondary"
          >
            <ExistingPaymentMethodBlock data={existingPaymentMethodData} />
          </PaymentMethodButton>
        );
      }
    } else return null;
  };

  const shouldShowCC = () => {
    if (isGraduate) {
      return !isMonthly;
    } else
      return subscriptionsData.some((sub: ISubscription) =>
        sub.code.includes(PlanCodeMethod.CreditCard)
      );
  };

  const shouldShowDD = subscriptionsData.some((sub: ISubscription) =>
    sub.code.includes(PlanCodeMethod.DirectDebit)
  );

  return (
    <Section isOpen customClasses="max-w-[680px]">
      <h2 className="text-[1.1rem] mb-4">
        {t("common:billing.select.payment.method")}
      </h2>
      <div className="flex flex-col gap-[4px]">
        {renderExistingPaymentMethod()}
        {shouldShowDD && (
          <PaymentMethodButton
            isSelected={
              selectedMethod === PlanCodeMethod.DirectDebit &&
              !useExistingPaymentMethod
            }
            text={t("common:button.label.add.dd")}
            onSelect={() =>
              handleSelectPaymentMethod(PlanCodeMethod.DirectDebit)
            }
            id="payment-method-button-direct-debit"
          />
        )}
        {shouldShowCC() && (
          <PaymentMethodButton
            isSelected={
              selectedMethod === PlanCodeMethod.CreditCard &&
              !useExistingPaymentMethod
            }
            text={t("common:button.label.add.cc")}
            onSelect={() =>
              handleSelectPaymentMethod(PlanCodeMethod.CreditCard)
            }
            id="payment-method-button-credit-card"
          />
        )}
      </div>
    </Section>
  );
};

export default PaymentMethodBlock;
