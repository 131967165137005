import React from "react";

/**
 * Used to scroll page to top on mount
 */
export const useScrollToTop = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
