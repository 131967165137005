import React from "react";

import { GlobalRouteContext, IGlobalContext } from "./GlobalContext";

export interface IGlobalRouteContextProviderProps {
  globalContext: IGlobalContext;
  children?: React.ReactNode;
}

const GlobalRouteContextProvider: React.FC<IGlobalRouteContextProviderProps> = (
  props: IGlobalRouteContextProviderProps
) => {
  return (
    <GlobalRouteContext.Provider value={props.globalContext}>
      {props.children}
    </GlobalRouteContext.Provider>
  );
};

export default GlobalRouteContextProvider;
