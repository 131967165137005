import React, { FC } from "react";
import { dataTestIds } from "data-testids";
import "./LinksListWithTitle.scss";
import { ILink } from "../../../generics/interfaces";

export interface ILinksListWithTitleProps {
  /**
   * The text to show as a title above the border
   */
  titleText: string;
  /**
   * The list of links to show under the border
   */
  links: ILink[];
  /** Optional css class for wrapper */
  className?: string;
}

export const LinksListWithTitle: FC<ILinksListWithTitleProps> = ({
  titleText,
  links,
  className,
}) => {
  return (
    <div className={`c-links-list${className ? " " + className : ""}`}>
      <div className="c-links-list__site-links">
        <div className="c-links-list__site-links-header">{titleText}</div>
        <ul className="c-links-list__site-links-list">
          {links.map((link, index) => {
            return (
              <li
                key={index}
                data-testid={
                  dataTestIds.componentLibrary[
                    "Molecules.LinksListWithTitle.siteLinks"
                  ]
                }
              >
                <a
                  className="c-links-list__link"
                  href={link.href}
                  title={link.title}
                >
                  {link.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LinksListWithTitle;
