import React, { JSX } from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { Button } from "../../Atoms/Button/Button";
import { InputCleaner } from "../../Atoms/InputsCommon/InputCleaner";
import { Input } from "../../Molecules/Input/Input";

import "./Search.scss";

export interface ISearchProps {
  maxLength?: number;
  placeholder?: string;
  label?: string;
  onSearch: (search: string) => void;
  onClear?: () => void;
  onTextChange?: (search: string) => void;
  className?: string;
  dropdownTag?: JSX.Element;
  disabled?: boolean;
  "data-testid"?: string;
  initialValue?: string;
}

export interface ISearchState {
  searchString: string;
  isInitialValueSet: boolean;
}

export class Search extends React.Component<ISearchProps, ISearchState> {
  constructor(props: ISearchProps) {
    super(props);
    this.state = {
      searchString: "",
      isInitialValueSet: false,
    };
  }

  public componentDidUpdate() {
    const { isInitialValueSet, searchString } = this.state;
    const { initialValue } = this.props;

    if (!isInitialValueSet && !searchString && initialValue) {
      this.setState({ searchString: initialValue, isInitialValueSet: true });
    }
  }

  public render() {
    return (
      <div className={cx("c-search", this.props.className)}>
        <Input
          disabled={this.props.disabled}
          type="text"
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          label={this.props.label}
          onChange={this.handleTextChange}
          onKeyDown={this.handleKeyDown}
          value={this.state.searchString}
          className={cx("c-search__input", {
            "c-search__without-label": !this.props.label,
          })}
          data-testid={this.props["data-testid"]}
        >
          <div className="c-search__children-wrapper">
            {this.state.searchString && (
              <InputCleaner onClick={this.handleCleanClick} />
            )}
            {this.props.dropdownTag && (
              <div className="c-search__dropdown-tag-wrapper">
                {this.props.dropdownTag}
              </div>
            )}
          </div>
        </Input>
        <div className="c-search__button-wrapper">
          <Button
            disabled={this.props.disabled}
            onClick={this.handleButtonClick}
            type="primary"
            text=""
            iconName="icon-magnify"
            ariaLabel={this.props.placeholder}
            data-testid={
              dataTestIds.componentLibrary[
                "Organisams.Search.searchInpputButton"
              ]
            }
          />
        </div>
      </div>
    );
  }

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchString: event.target.value }, () => {
      return (
        this.props.onTextChange &&
        this.props.onTextChange(this.state.searchString)
      );
    });
  };

  private handleCleanClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ searchString: "" }, () => {
      return this.props.onClear && this.props.onClear();
    });
  };

  private handleButtonClick = () => {
    this.props.onSearch(this.state.searchString);
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.props.onSearch(this.state.searchString);
    }
  };
}
