export const joiningPages = {
  "Layout.layout": "Layout",
  "JoiningPages.CastingDirector.AboutYouPageCD.linkOrCVSection": "test",
  "JoiningPages.CastingDirector.AboutYouPageCD.confirmAgeRadiogroup":
    "CONFIRM_AGE_TEST_ID",
  "JoiningPages.CastingDirector.AboutYouPageCD.notSureTextAreaParent":
    "NOT_SURE_ABOUT_YOU_TEXTAREA_PARENT",
  "JoiningPages.CastingDirector.AboutYouPageCD.notSureTextArea":
    "NOT_SURE_ABOUT_YOU_TEXTAREA",
  "JoiningPages.CastingDirector.ReviewPage.nameRow": "name-row",
  "JoiningPages.CastingDirector.ReviewPage.phoneRow": "phone-row",
  "JoiningPages.CastingDirector.ReviewPage.emailRow": "email-row",
  "JoiningPages.CastingDirector.ReviewPage.occupationRow": "occupation-row",
  "JoiningPages.CastingDirector.ReviewPage.companyNameRow": "companyName-row",
  "JoiningPages.CastingDirector.ReviewPage.websiteRow": "website-row",
  "JoiningPages.CastingDirector.ReviewPage.orgMemberRow": "orgMember-row",
  "JoiningPages.CastingDirector.ReviewPage.orgMemberNameRow":
    "orgMemberName-row",
  "JoiningPages.CastingDirector.ReviewPage.orgMemberEmailRow":
    "orgMemberEmail-row",
  "JoiningPages.CastingDirector.ReviewPage.orgMemberNumberRow":
    "orgMemberNumber-row",
  "JoiningPages.CastingDirector.ReviewPage.spotlightMemberRow":
    "spotlightMember-row",
  "JoiningPages.CastingDirector.ReviewPage.spotlightMemberNameRow":
    "spotlightMemberName-row",
  "JoiningPages.CastingDirector.ReviewPage.spotlightMemberTypeRow":
    "spotlightMemberType-row",
  "JoiningPages.CastingDirector.ReviewPage.spotlightMemberEmailRow":
    "spotlightMemberEmail-row",
  "JoiningPages.CastingDirector.ReviewPage.castingForRow": "castingFor-row",
  "JoiningPages.CastingDirector.ReviewPage.linksRow": "links-row",
  "JoiningPages.CastingDirector.ReviewPage.cvRow": "cv-row",
  "JoiningPages.CastingDirector.ReviewPage.castingUnder18Row":
    "castingUnder18-row",
  "JoiningPages.CastingDirector.ReviewPage.awareOfRegulationsRow":
    "under18Aware-row",
  "JoiningPages.CastingDirector.ReviewPage.spotlightUseRow": "spotlightUse-row",
  "JoiningPages.Performers.UploadImageModal.choosePhotoButton": "choose-photo",
};
