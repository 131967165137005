import React from "react";
import cx from "classnames";
import { FilterTag, IFilterTagProps } from "../../../Atoms/FilterTag/FilterTag";
import { debounce } from "../../../../generics/debounce";
import { isSmallDeviceWidth } from "../../../../generics/dom-extensions";
import { IFilterControlTranslations } from "../../../../translations/translationModels";

import "./TagsPanel.scss";

export interface ITagsPanelProps {
  /**
   * texts
   */
  texts: IFilterControlTranslations;
  /**
   *  Collection of selected tags  to be displayed in the filter control
   */
  tags: IFilterTagProps[];
  /**
   * Callback for Clear All click
   */
  onClearAllFilters?: (tags: IFilterTagProps[]) => void;
  /**
   * Callback for mobile counter click
   */
  onTagCounterClick?: (
    event: React.MouseEvent<HTMLDivElement>,
    tags: IFilterTagProps[]
  ) => void;
}

interface ITagsPanelState {
  lastShownTagIndex: number;
  isTagsHidden: boolean;
  tagsLength: number;
}

export class TagsPanel extends React.Component<
  ITagsPanelProps,
  ITagsPanelState
> {
  private readonly tagsAreaRef = React.createRef<HTMLDivElement>();
  private readonly hiddenCalcAreaRef = React.createRef<HTMLDivElement>();

  constructor(props: ITagsPanelProps) {
    super(props);

    this.state = {
      lastShownTagIndex: props.tags.length,
      isTagsHidden: false,
      tagsLength: props.tags.length,
    };

    this.onResize = debounce(this.onResize.bind(this), 100);
  }

  public componentDidMount() {
    this.reflow(this.props.tags);
    window.addEventListener("resize", this.onResize);
  }

  public componentDidUpdate(
    prevProps: Readonly<ITagsPanelProps>,
    prevState: Readonly<ITagsPanelState>
  ) {
    if (
      prevProps.tags !== this.props.tags ||
      this.props.tags.length !== prevState.tagsLength
    ) {
      this.reflow(this.props.tags);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  public render() {
    const { tags, texts } = this.props;
    const isWithTags = tags.length > 0;

    return (
      <div
        ref={this.tagsAreaRef}
        className={cx(
          "c-tags-panel g-col-lg-12 ",
          isWithTags && "c-tags-panel--with-tags flex gap-1"
        )}
      >
        {tags.slice(0, this.state.lastShownTagIndex).map((item) => {
          return this.renderFilterTag(item);
        })}
        {this.state.isTagsHidden && (
          <FilterTag
            text={`+ ${tags.length - this.state.lastShownTagIndex}`}
            className="c-tags-panel__more-tags"
            onClick={this.handleTagCounterClick}
          />
        )}
        {isWithTags && (
          <span className="mx-1 md:mx-0">
            <FilterTag
              text={`${tags.length}${texts.filtersMobile}`}
              iconName="icon-eye-red"
              className="c-tags-panel__tag-counter"
              onClick={this.handleTagCounterClick}
            />
            <a
              className="inline-block c-tags-panel__clear-all g-alternative-link g-alternative-link--lg"
              onClick={this.handleClearAllFilters}
              tabIndex={0}
            >
              {texts.clearAll}
            </a>
          </span>
        )}
        <div
          ref={this.hiddenCalcAreaRef}
          className="c-tags-panel__hidden-calc-area"
          aria-hidden={true}
        />
      </div>
    );
  }

  readonly renderFilterTag = (tag: IFilterTagProps) => {
    return (
      <FilterTag
        {...tag}
        className="c-tags-panel__active-tag"
        iconName="icon-cross1"
        key={tag.text}
      />
    );
  };

  private handleTagCounterClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.props.onTagCounterClick &&
      this.props.onTagCounterClick(event, this.props.tags);
  };

  private handleClearAllFilters = () => {
    this.props.onClearAllFilters &&
      this.props.onClearAllFilters(this.props.tags);
  };

  private onResize() {
    this.reflow(this.props.tags);
  }

  // @ts-ignore
  private reflow(tags) {
    if (isSmallDeviceWidth() || !tags.length) {
      return;
    }
    this.setState(this.getNewState(tags));
  }

  // @ts-ignore
  private getNewState = (tags) => {
    this.fillCalcArea(tags);
    const index = this.putEllipsis(this.calcIndex(), tags);
    const isTagsHidden = index < tags.length;
    const lastShownTagIndex = isTagsHidden ? index : tags.length;
    return { isTagsHidden, lastShownTagIndex, tagsLength: tags.length };
  };

  public calcIndex = () => {
    let element = this.hiddenCalcAreaRef.current
      ?.firstElementChild as HTMLElement;
    let index = 0;

    while (element) {
      if (element.offsetTop > 0) {
        break;
      }
      index++;
      element = element.nextElementSibling as HTMLElement;
    }
    return index;
  };

  // @ts-ignore
  private putEllipsis(index, tags) {
    if (index === tags.length) {
      return index;
    }

    const units = tags.slice(0, index);
    this.fillCalcArea(
      units,
      `<span class='c-tags-panel__hidden-calc-area__unit'>+99</span>`
    );
    const nodeEllipsis = this.hiddenCalcAreaRef.current
      ?.lastElementChild as HTMLElement;
    return nodeEllipsis && nodeEllipsis.offsetTop > 0 ? index - 1 : index;
  }

  private fillCalcArea(tags: IFilterTagProps[], end = "") {
    if (this.hiddenCalcAreaRef.current) {
      this.hiddenCalcAreaRef.current.innerHTML =
        tags
          .map(
            (c) =>
              `<span class='c-tags-panel__hidden-calc-area__unit ${c.className}'>${c.text}</span>`
          )
          .join("") + end;
    }
  }
}
