import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import { billingService } from "../services";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { PUT_CPA_OPT_IN } from "../services/billingService";

export const useCpaOptIn = () => {
  const isCpaMessageFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.ContinuousPaymentAuthorityCheckoutMessage
  );

  const { mutate } = useMutation<AxiosResponse, AxiosError, boolean>({
    mutationKey: PUT_CPA_OPT_IN,
    mutationFn: async (cpaEligible: boolean) => {
      return await billingService.optInToCpa(
        isCpaMessageFlagEnabled && cpaEligible
      );
    },
  });

  return { mutate };
};
