import React, { FC } from "react";
import { useTranslation } from "i18n";
import cx from "classnames";

import {
  CreditCardType,
  ExistingPaymentMethod,
  PaymentMethodType,
} from "../../types";
import { ReactComponent as VisaLogo } from "../../../../../images/svg/icons/visa.svg";
import { ReactComponent as MastercardLogo } from "../../../../../images/svg/icons/mastercard.svg";
import { ReactComponent as DirectDebitLogo } from "../../../../../images/svg/icons/direct-debit.svg";
import { ReactComponent as SepaLogo } from "../../../../../images/svg/icons/sepa.svg";

type ExistingPaymentMethodBlockPropsType = {
  data: ExistingPaymentMethod | undefined;
  type?: "primary" | "compact";
};

type DataDisplayProps = {
  logoComponent: JSX.Element;
  accountString: string;
  typeString: string;
  designType: "primary" | "compact";
};

const LOGO_MAP = {
  [CreditCardType.Visa]: VisaLogo,
  [CreditCardType.Mastercard]: MastercardLogo,
  [CreditCardType.MasterCard]: MastercardLogo,
  [PaymentMethodType.Bacs]: DirectDebitLogo,
  [PaymentMethodType.Sepa]: SepaLogo,
};

const DataDisplay = ({
  logoComponent,
  typeString,
  accountString,
  designType,
}: DataDisplayProps) => {
  const isCompact = designType === "compact";

  return (
    <div className={cx({ "flex flex-row gap-2": isCompact })}>
      {logoComponent}

      <div>
        <p
          className={cx("text-lg font-semibold", {
            hidden: isCompact,
          })}
        >
          {typeString}
        </p>
        <p>{accountString}</p>
      </div>
    </div>
  );
};

const ExistingPaymentMethodBlock: FC<ExistingPaymentMethodBlockPropsType> = ({
  data,
  type = "primary",
}) => {
  const { t } = useTranslation();

  const primaryLogoProps = {
    width: 64,
    height: 32,
  };

  const compactLogoProps = {
    width: 35,
    height: 24,
  };

  const renderLogoComponent = (
    key: CreditCardType | PaymentMethodType.Bacs | PaymentMethodType.Sepa
  ) => {
    const LogoComponent = LOGO_MAP[key];

    const props = type === "primary" ? primaryLogoProps : compactLogoProps;

    return <LogoComponent {...props} />;
  };

  if (!data) return null;

  if (data.paymentMethodType === PaymentMethodType.CreditCard) {
    const truncatedCardNumber = data.cardNumber.slice(-4);

    return (
      <DataDisplay
        logoComponent={renderLogoComponent(data.cardType as CreditCardType)}
        accountString={t("common:button.subtitle.existingCc", {
          cardNumber: truncatedCardNumber,
        })}
        typeString={data.cardType}
        designType={type}
      />
    );
  }

  if (
    data.paymentMethodType === PaymentMethodType.Bacs ||
    data.paymentMethodType === PaymentMethodType.Sepa
  ) {
    const truncatedAccountNumber = data.bankAccountNumber.slice(-2);

    return (
      <DataDisplay
        logoComponent={renderLogoComponent(data.paymentMethodType)}
        accountString={t("common:button.subtitle.existingDd", {
          accountNumber: truncatedAccountNumber,
        })}
        typeString={t("common:button.title.existingDd")}
        designType={type}
      />
    );
  }

  return null;
};

export default ExistingPaymentMethodBlock;
