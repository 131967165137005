import { ICheckboxGroupValue } from "../../Organisms/CheckboxGroup/CheckboxGroup";

export function arrayToGroupCheckbox(
  array: (string | number)[]
): ICheckboxGroupValue {
  return array.reduce((acc, item) => {
    return { ...acc, [item]: true };
  }, {});
}

export function groupCheckboxToArray(group: ICheckboxGroupValue): string[] {
  return Object.keys(group).filter((key) => group[key]);
}
