import { IBillingFallbacks, BillingUser } from "../../models/billing";
import { BillingCommonTranslations } from "./common";
import { BillingCastingTranslations } from "./casting";
import { BillingPerformerTranslations } from "./performer";
import { BillingYoungPerformerTranslations } from "./youngPerformer";
import { BillingGraduateTranslations } from "./graduate";

export const BillingFallbacks: IBillingFallbacks = {
  [BillingUser.Common]: BillingCommonTranslations,
  [BillingUser.Casting]: BillingCastingTranslations,
  [BillingUser.Performer]: BillingPerformerTranslations,
  [BillingUser.Graduate]: BillingPerformerTranslations,
  [BillingUser.GraduateTransfer]: BillingGraduateTranslations,
  [BillingUser.YoungPerformer]: BillingYoungPerformerTranslations,
  [BillingUser.JoiningCasting]: BillingCastingTranslations,
  [BillingUser.JoiningPerformer]: BillingPerformerTranslations,
  [BillingUser.JoiningYoungPerformer]: BillingYoungPerformerTranslations,
  [BillingUser.JoiningGraduate]: BillingGraduateTranslations,
};
