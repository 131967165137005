import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const ReactQueryClientWrapper = (): FC => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });
  return ({ children }) => (
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
  );
};
