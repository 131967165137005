import { MemberGroups } from "shared-types";
import { checkIsFilmmakersProfile } from "shared-auth";
import { getPerformerNav } from "./getPerformerNav";
import { getYoungPerformerNav } from "./getYoungPerformerNav";
import { getGradsNav } from "./getGradsNav";
import { getAgentsNav } from "./getAgentsNav";
import { getCDNav } from "./getCDNav";
import { getCDSearchOnlyNav } from "./getCDSearchOnlyNav";
import { IModifiers } from "./getNavHeaderLinks";
import { getCastingBBCNav } from "./getCastingBBCNav";

export function getMainNav(memberGroup: string, modifiers: IModifiers) {
  if (modifiers.isCastingBBC) return getCastingBBCNav();

  switch (memberGroup) {
    case MemberGroups.agent:
      return getAgentsNav(modifiers);
    case MemberGroups.castingDirector:
      return getCDNav(modifiers);
    case MemberGroups.searchOnlyCD: {
      return getCDSearchOnlyNav();
    }
    case MemberGroups.performer: {
      const isFilmmakersProfile = checkIsFilmmakersProfile();

      return getPerformerNav({
        isFilmmakersProfile,
        ...modifiers,
      });
    }
    case MemberGroups.graduatePerformer:
      return getGradsNav(modifiers);
    case MemberGroups.yPerformer:
      return getYoungPerformerNav(modifiers);
    default:
      return { mainNavigationItems: [] };
  }
}
