import React from "react";

import { IMultiSelectItem } from "../MultiSelectItem";
import { ITooltipContent } from "../../../Molecules/Tooltip/Tooltip";
import {
  Autocomplete,
  IAutocompleteItem,
} from "../../../Atoms/Autocomplete/Autocomplete";
import { Input } from "../../../Molecules/Input/Input";
import { Helper } from "../../Helper/Helper";
import { debounce } from "../../../../generics/debounce";

interface IMultiSelectAutocompleteContent {
  label: string;
  placeholder: string;
  tooltip?: ITooltipContent;
}

interface IMultiSelectAutocompleteProps<TValue> {
  content: IMultiSelectAutocompleteContent;
  name: string;
  delayLoadTime?: number;
  invalid?: boolean;
  validationMessage?: string;
  selectItem: (value: IMultiSelectItem<TValue>) => void;
  loadData: (value: string) => Promise<IMultiSelectItem<TValue>[]>;
  annex?: JSX.Element;
}

interface IMultiSelectAutocompleteState {
  value: string;
  displayAutocomplete: boolean;
  autocompleteList: IAutocompleteItem[];
}

export class MultiSelectAutocomplete<TValue> extends React.Component<
  IMultiSelectAutocompleteProps<TValue>,
  IMultiSelectAutocompleteState
> {
  public readonly state: Readonly<IMultiSelectAutocompleteState> = {
    value: "",
    autocompleteList: [],
    displayAutocomplete: false,
  };

  public render() {
    const { autocompleteList, displayAutocomplete, value } = this.state;
    const { content, name, invalid, validationMessage, annex } = this.props;

    return (
      <Input
        id={name}
        name={name}
        type="text"
        invalid={invalid}
        validationMessage={validationMessage}
        isFormSubmitted
        label={content.label}
        placeholder={content.placeholder}
        value={value}
        valueChanged={this.valueChanged}
        annex={
          annex ||
          (content.tooltip && (
            <Helper
              {...{
                tooltip: {
                  texts: content.tooltip,
                  config: {
                    minimalTopDistance: 50,
                  },
                },
              }}
            />
          ))
        }
      >
        <Autocomplete
          list={autocompleteList}
          display={displayAutocomplete}
          selectItem={this.selectItem}
        />
      </Input>
    );
  }

  private readonly valueChanged = (value: string) => {
    const state = { ...this.state };
    if (value.length === 0) {
      state.displayAutocomplete = false;
      state.autocompleteList = [];
    }

    state.displayAutocomplete = state.autocompleteList.length > 0;
    state.value = value;

    this.setState(state, this.loadListDebounced);
  };

  private readonly selectItem = ({ name, data }: IAutocompleteItem) => {
    this.setState(
      {
        value: "",
        autocompleteList: [],
        displayAutocomplete: false,
      },
      () => this.props.selectItem({ name, value: data })
    );
  };

  private readonly loadList = async () => {
    const { value: searchString } = this.state;

    if (searchString.length > 0) {
      const autocompleteList = (await this.props.loadData(searchString)).map(
        ({ name, value }) => ({ name, data: value })
      );

      this.setState({
        autocompleteList,
        displayAutocomplete: autocompleteList.length > 0,
      });
    }
  };

  private readonly loadListDebounced = debounce(
    this.loadList,
    this.props.delayLoadTime || 0
  );
}
