import React from "react";
import { Popup } from "component-library";

import { MainPhotoView } from "./MainPhotoView";
import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "../PopupFlowBaseComponent";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";

import { IMainPhotoFlowContent } from "./MainPhotoFlowContent";
import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo } from "models";
import { FLOW_TYPES } from "flows";
import compose from "shared/utils/compose";

const STEP_NAME = {
  mainPhotoView: "mainPhotoView",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export interface IMainPhotoFlowProps
  extends IFlowContextProps,
    IGlobalContextProps {
  photoPromise: () => Promise<Photo>;
  replace: () => void;
  content: IMainPhotoFlowContent;
}

interface IMainPhotoFlowState extends IPopupFlowBaseComponentState {
  photo?: Photo;
  error?: string;
}

export class MainPhotoFlow extends PopupFlowBaseComponent<
  IMainPhotoFlowProps,
  IMainPhotoFlowState
> {
  public readonly state: Readonly<IMainPhotoFlowState> = {
    currentStep: STEP_NAME.mainPhotoView,
  };

  private photoPromise: ICancelablePromise;

  constructor(props: Readonly<IMainPhotoFlowProps>) {
    super(props);

    this.makeFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();

    let view: React.ReactNode = null;

    if (popupConfiguration && component) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public async componentDidMount() {
    this.photoPromise = makeCancelable(this.props.photoPromise());

    try {
      const photo = await this.photoPromise.promise;
      this.setState({ photo });
    } catch (error) {
      const { content } = this.props;
      if (!error.isCanceled) {
        this.setState({ error: content.photoLoadingError });
      }
    }
  }

  public componentWillUnmount() {
    this.photoPromise && this.photoPromise.cancel();
  }

  private makeFlowSteps = () => {
    const { content } = this.props;

    this.flowSteps = [
      {
        name: STEP_NAME.mainPhotoView,
        component: this.getMainPhotoView,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          {
            closePopup: content.closePopup,
          },
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          close: this.closeFlow,
          editPhoto: this.editPhoto,
          replacePhoto: this.replacePhoto,
          content: this.props.content.viewMainPhoto,
          showHelpPopup: this.showHelpPopup,
        },
      },
    ];
  };

  public getMainPhotoView = (mainPhotoViewProps: any) => (
    <MainPhotoView
      {...mainPhotoViewProps}
      photo={this.state.photo}
      error={this.state.error}
    />
  );

  public closeFlow = () => this.props.flowContext.changeContext("", null);

  public editPhoto = () =>
    this.props.flowContext.changeContext(FLOW_TYPES.editMainPhoto, {
      photo: this.state.photo,
    });

  public replacePhoto = () => this.props.replace();

  private showHelpPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
}

export default compose(withGlobalContext, withFlowContext)(MainPhotoFlow);
