import React, { FC, useState } from "react";
import { useQuery } from "react-query";
import { authAxios as axios } from "shared-auth";
import cx from "classnames";
import { mapToAudioModel, mapToVideoModel } from "shared-services";
import { MediaType, TranscodeStatus } from "shared-types";
import { useTranslation } from "i18n";
import { getTootlipProps, PopUpMediaViewer, Tooltip } from "../../../../index";
import { config } from "../../../../config";
import ThumbnailViewer from "../ThumbnailVideoViewer";

const mediaDataPollingInterval = 2000;
const getMediaPreviewQueryKey = "get-media-preview";

interface Props {
  id?: string;
  isProcessingVisible?: boolean;
  processingTooltipText?: string;
}

const MediaFilePreview: FC<Props> = ({
  id,
  isProcessingVisible,
  processingTooltipText,
}) => {
  const { t } = useTranslation();
  const [mediaPlayerPopIsOpen, setMediaPlayerPopIsOpen] = useState(false);
  const { data } = useQuery(
    [getMediaPreviewQueryKey, id],
    async () => {
      const { data } = await axios.get(`${config.titaniumApiUrl}/media/${id}`);

      return data.value;
    },
    {
      enabled: !!id,
      refetchInterval: (data) => {
        if (data?.transcodeStatus === TranscodeStatus.Complete) return false;

        return mediaDataPollingInterval;
      },
    }
  );
  const mediaType = data?.mediaType;
  const isComplete = data?.transcodeStatus === TranscodeStatus.Complete;
  let media;

  if (isComplete && mediaType === MediaType.Video) {
    media = mapToVideoModel(
      { ...data, title: data?.originalFileName, selectedThumbnail: 0 },
      data
    );
  }

  if (isComplete && mediaType === MediaType.Audio) {
    media = mapToAudioModel({ ...data, title: data?.originalFileName }, data);
  }

  return (
    <Tooltip
      {...getTootlipProps(
        "",
        processingTooltipText || t("common:videoProcessing.tooltip.body")
      )}
      className="outline-none"
      disabled={isComplete}
    >
      <div
        className={cx("max-h-[60px] w-[100px] mr-2", {
          "bg-grey-fog-1": !isComplete,
        })}
      >
        <ThumbnailViewer
          playback={() => setMediaPlayerPopIsOpen(true)}
          thumbnailUrl={data?.thumbnails?.[0]}
          mediaType={data?.mediaType}
          isLoaded={isComplete}
          isProcessingVisible={isProcessingVisible}
        />
        {media && mediaType && (
          <PopUpMediaViewer
            isOpen={mediaPlayerPopIsOpen}
            onClose={() => setMediaPlayerPopIsOpen(false)}
            mediaType={mediaType}
            mediaList={[media]}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default MediaFilePreview;
