import React from "react";
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { Button, SectionContainer } from "component-library";
import { IVideoModel } from "shared-types";
import { UserRoleManager, UserRoles } from "app/core";
import { getLocalUrl } from "common";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import { VideoPlayerContainer } from "mediaModules/media/components/VideoPlayerContainer";
import withMediaData, {
  IWithMediaDataInjectedProps,
  withMediaAssetType,
} from "mediaModules/media/hoc/withMediaData";
import {
  MediaAsset,
  MediaAssetType,
  TranscodeStatus,
} from "mediaModules/media/models";
import { scrollRefToTop } from "mediaModules/media/utils/page";

import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";
import compose from "shared/utils/compose";
import { PromiseContainer } from "shared/utils/promise";
import { PERFORMER_PROFILE_PATH } from "shared/constants";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  ITranslationContextProps,
  Translator,
  withTranslationF,
} from "contexts/TranslationContext";

import {
  Breadcrumbs,
  IBreadcrumbsContent,
} from "components/common/Breadcrumbs";

import { EditVideoListForm } from "./components/EditVideoListForm";
import {
  getAlertMessage,
  getDisplayLimitAlert,
  getSectionContainerProps,
  IVideoPageContent,
  IVideoPageContentKeys,
  PopupTypes,
} from "./VideoPageContent";

import "./VideoPage.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";
import { IBreadcrumbsContentKeys } from "components/common/Breadcrumbs/Breadcrumbs";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

export interface IVideoPageProps
  extends ITranslationContextProps,
    IGlobalContextProps,
    RouteComponentProps,
    IWithMediaDataInjectedProps {}

interface IVideoPageState {
  content: IVideoPageContent;
}

export class VideoPage extends React.Component<
  IVideoPageProps,
  IVideoPageState
> {
  public static getDerivedStateFromProps(
    nextProps: IVideoPageProps,
    prevState: IVideoPageState
  ) {
    let stateUpdates = {};

    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(prevState.content)
    ) {
      const content = VideoPage.mapTranslation(nextProps.translator);

      stateUpdates = { content };
    }

    return stateUpdates;
  }

  private static mapTranslation = (translator: Translator) =>
    translator.compileTranslationObject<IVideoPageContent>(
      IVideoPageContentKeys,
      "common"
    );

  private deletePromise: PromiseContainer<any> = new PromiseContainer();
  private pageRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: Readonly<IVideoPageProps>) {
    super(props);

    this.state = {
      content: VideoPage.mapTranslation(props.translator),
    };
  }

  public render() {
    const { content } = this.state;
    const {
      storageSpace,
      mediaAssets,
      isMediaMetadataLoading,
      areMediaObjectsLoading,
      isPatching,
      changeMediaAssetsOrder,
    } = this.props;

    const breadcrumbsContent: IBreadcrumbsContent =
      this.getBreadcrumbsTranslation();
    const sectionContainerProps = getSectionContainerProps(
      content,
      this.closePage
    );

    return (
      <div ref={this.pageRef}>
        <Breadcrumbs content={breadcrumbsContent} />
        <div className="g-content-area p-video-page">
          <div className="g-col-lg-12 p-video-page__container">
            <SectionContainer {...sectionContainerProps}>
              <div className="p-video-page__content">
                <EditVideoListForm
                  content={content.editVideoListForm}
                  showreelList={mediaAssets}
                  storageSpace={storageSpace}
                  editShowreel={this.redirectToEditVideoPage}
                  hideShowreels={this.hideShowreels}
                  playbackVideo={this.playbackVideo}
                  deleteShowreels={this.deleteShowreelsAlert}
                  changeMediaAssetsOrder={changeMediaAssetsOrder}
                  addNewShowreel={this.addNewShowreel}
                  downloadShowreel={this.downloadShowreel}
                  isPatching={isPatching}
                  isMediaMetadataLoading={isMediaMetadataLoading}
                  areMediaObjectsLoading={areMediaObjectsLoading}
                />
                {this.renderFormButtons()}
              </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    );
  }

  public async componentDidMount() {
    try {
      GAEvents.trackPageLoad("Edit video page is loaded", MediaAssetType.Video);
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }
  }

  private getBreadcrumbsTranslation = () =>
    this.props.translator
      .getTranslator("breadcrumbs", true)
      .createTranslationObject<IBreadcrumbsContent>(IBreadcrumbsContentKeys);

  private renderFormButtons = () => {
    const { areMediaObjectsLoading } = this.props;
    const { content } = this.state;

    return (
      <div className="p-video-page__buttons">
        <div className="p-video-page__button">
          <Button
            type="primary"
            onClick={this.returnToCVEditor}
            text={content.cancelButton}
          />
        </div>
        <div className="p-video-page__button">
          <Button
            className={cx("p-video-page__add-showreel", {
              "p-video-page__add-showreel--disabled": areMediaObjectsLoading,
            })}
            type="secondary"
            onClick={this.addNewShowreel}
            text={content.addButton}
          />
        </div>
      </div>
    );
  };

  private redirectTo = (path) => {
    scrollRefToTop(this.pageRef);
    this.props.history.push(getLocalUrl(path));
  };

  private redirectToEditVideoPage = ({ id }: MediaAsset) =>
    this.redirectTo(`media/video/edit/${id}`);

  private returnToCVEditor = () => {
    const { agentCVLink } = this.state.content;

    GAEvents.editMediaAssets.trackCloseClick(MediaAssetType.Video);

    window.location.assign(this.getCVLink(PERFORMER_PROFILE_PATH, agentCVLink));
  };

  private getCVLink = (performerCVLink: string, agentCVLink: string) => {
    return UserRoleManager.isInRole(UserRoles.agent)
      ? agentCVLink
      : performerCVLink;
  };

  private closePage = () => this.props.history.push(getLocalUrl());

  private playbackVideo = (showreel: MediaAsset) => {
    if (showreel.transcodeStatus === TranscodeStatus.Complete) {
      const controls = this.getPlayerPanel(showreel);
      const video = this.mapVideoModel(showreel);

      const videoPlayer = (
        <VideoPlayerContainer
          playerConfig={{
            autoPlay: true,
            hasCloseButton: true,
            videoList: [video],
            selectedVideo: video,
            selectVideo: () => undefined,
            close: this.closeVideoPopupWrapper(),
          }}
          controlPanelButtons={controls}
        />
      );

      const popupProps = {
        hideCloseButton: true,
        fullHeight: true,
      };

      this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
        content: videoPlayer,
        popupProps,
      });
    }
  };

  private hideShowreels = async (showreels: MediaAsset[]) => {
    const { content } = this.state;
    const { hideMediaAssets, globalContext } = this.props;

    const displayLimitReachedCallback = () => {
      const message = getDisplayLimitAlert(content.displayLimitAlert, () =>
        this.closePopup()
      );
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );
    };

    await hideMediaAssets(showreels, displayLimitReachedCallback);
  };

  private addNewShowreel = () => {
    GAEvents.editMediaAssets.trackAddClick(MediaAssetType.Video);

    const { content } = this.state;
    const { globalContext } = this.props;

    const message = getAlertMessage(
      content,
      PopupTypes.Copyright,
      this.redirectToAddNewVideoPage,
      this.closePopup,
      "notice"
    );
    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
  };

  private redirectToAddNewVideoPage = () => {
    this.closePopup();
    this.redirectTo("media/video/new");
  };

  private deleteShowreelsAlert = (deletedShowreels: MediaAsset[]) => {
    const { content } = this.state;

    const { globalContext, removeMediaAssets } = this.props;

    this.deletePromise.setPromise();
    this.deletePromise.promise
      .then(async () => await removeMediaAssets(deletedShowreels))
      .catch((error) =>
        captureExceptionEvent(error, authorizationService.getDecodedUserToken())
      );

    const closePopup = () => {
      this.deletePromise.reject("Popup is closed");
      this.closePopup();
    };

    const message = getAlertMessage(
      content,
      PopupTypes.Delete,
      this.deletePromise.resolve,
      closePopup
    );
    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );

    return this.deletePromise.promise;
  };

  private downloadShowreel = (showreel: MediaAsset) =>
    (window.location.href = showreel.downloadLink);

  private getPlayerPanel = (showreel: MediaAsset) => {
    const { controls } = this.state.content.editVideoListForm;
    const buttons: (IActionButtonProps & { key: string | number })[] = [];

    buttons.push({
      key: "edit",
      icon: "edit",
      label: controls.edit,
      onClick: this.closeVideoPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackEditClick(
          MediaAssetType.Video
        );
        this.redirectToEditVideoPage(showreel);
      }),
    });

    buttons.push({
      key: "delete",
      icon: "delete",
      label: controls.delete,
      onClick: this.closeVideoPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackDeleteClick(
          MediaAssetType.Video
        );
        this.deleteShowreelsAlert([showreel]);
      }),
    });

    if (!UserRoleManager.isInRole(UserRoles.castingDirector)) {
      buttons.push({
        key: "hide",
        icon: "hide",
        label: controls.hide,
        onClick: this.closeVideoPopupWrapper(() => {
          GAEvents.editMediaAssets.playerControls.trackHideClick(
            MediaAssetType.Video
          );
          this.hideShowreels([showreel]);
        }),
      });
    }

    buttons.push({
      key: "download1",
      icon: "download1",
      label: controls.download,
      onClick: this.closeVideoPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackDownloadClick(
          MediaAssetType.Video
        );
        this.downloadShowreel(showreel);
      }),
    });

    return buttons;
  };

  private mapVideoModel = (showreel: MediaAsset) => {
    const {
      durationInMs,
      title,
      sources,
      id,
      thumbnails,
      selectedThumbnail,
      downloadLink,
    } = showreel;

    const video: IVideoModel = {
      id,
      sources,
      downloadLink,
      duration: durationInMs,
      fileName: title,
      previewImg: thumbnails[selectedThumbnail],
      poster: thumbnails[0],
      shareLink: downloadLink,
    };

    return video;
  };

  private closeVideoPopupWrapper =
    (wrappedFunction?: () => any | Promise<any>) => async () => {
      const result = wrappedFunction && wrappedFunction();

      if (result instanceof Promise) {
        await result;
      }

      this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
        content: null,
      });
    };

  private closePopup = () =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.closeAllGlobalAlert
    );
}

export default compose(
  withTranslationF("editVideoPage"),
  withMediaAssetType(MediaAssetType.Video),
  withMediaData,
  withGlobalContext
)(VideoPage);
