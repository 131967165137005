import React from "react";
import { Popup } from "../../../Molecules/Popup/Popup";
import { Button } from "../../Button/Button";
import { ReactComponent as WarningIcon } from "../../../../images/svg/icons/warning-icon-base.svg";
import { ErrorCodes, formatBytes } from "./Dropzone.helpers";
import { IImageDimensionValidations } from "./DropzoneFormik";
import "./DropzoneInvalidModal.scss";

export interface IDropzoneInvalidModalTexts {
  title?: string;
  close?: string;
  fileTypeErrorTitle?: string;
  fileTypeErrorMessage?: string;
  fileSizeLargeErrorTitle?: string;
  fileSizeLargeErrorMessage?: string; // substitute {size} with size in bytes
  fileSizeSmallErrorTitle?: string;
  fileSizeSmallErrorMessage?: string; // substitute {size} with size in bytes
  fileDimensionsErrorTooSmall?: string; // substitute {minWidth} and {minHeight} with width and height in pixels
  fileDimensionsErrorTooLarge?: string; // substitute {maxWidth} and {maxHeight} with width and height in pixels
  tooManyFilesErrorTitle?: string;
  tooManyFilesErrorMessage?: string;
  uploadErrorMessage?: string;
}

interface IDropzoneInvalidModalProps {
  errors: ErrorCodes[];
  onClose: () => void;
  supportedFileTypes?: string[];
  maxSize?: number;
  minSize?: number;
  maxFiles?: number;
  imageDimensions?: IImageDimensionValidations;
  texts?: IDropzoneInvalidModalTexts;
  errorButtonId?: string;
}

export const DropzoneInvalidModal: React.FC<IDropzoneInvalidModalProps> = ({
  errors,
  onClose,
  supportedFileTypes,
  maxSize,
  minSize,
  maxFiles,
  imageDimensions,
  texts,
  errorButtonId,
}) => {
  const uniqueErrorCodes = React.useMemo(() => {
    return Array.from(new Set(errors));
  }, [errors]);

  const showFileTypeError = uniqueErrorCodes.includes(
    ErrorCodes.FileInvalidType
  );
  const showFileLargeError = uniqueErrorCodes.includes(ErrorCodes.FileTooLarge);
  const showFileSmallError = uniqueErrorCodes.includes(ErrorCodes.FileTooSmall);
  const showTooManyFilesError = uniqueErrorCodes.includes(
    ErrorCodes.TooManyFiles
  );
  const showFileDimensionsTooSmallError = uniqueErrorCodes.includes(
    ErrorCodes.FileDimensionSizesTooSmall
  );
  const showFileDimensionsTooLargeError = uniqueErrorCodes.includes(
    ErrorCodes.FileDimensionSizesTooLarge
  );
  const uploadError = uniqueErrorCodes.includes(ErrorCodes.UploadError);

  return (
    <Popup
      width={{ lg: 5, md: 8 }}
      close={onClose}
      priority="high"
      texts={{
        closePopup: texts?.close || "",
      }}
    >
      <div className="g-popup-content">
        <header className="DropzoneInvalidModal__header g-popup-content__header">
          <WarningIcon
            width={34}
            height={34}
            className="font-color-neutral-one"
          />
          <span className="DropzoneInvalidModal__title">{texts?.title}</span>
        </header>
        <main className="DropzoneInvalidModal__main">
          {showFileTypeError && (
            <section>
              <div className="DropzoneInvalidModal__fileTypeListTitle">
                {texts?.fileTypeErrorTitle}
              </div>
              <div>{texts?.fileTypeErrorMessage}</div>
              <ul className="DropzoneInvalidModal__fileTypeList">
                {supportedFileTypes?.map((type) => <li key={type}>{type}</li>)}
              </ul>
            </section>
          )}

          {showFileLargeError && (
            <section>
              <div>{texts?.fileSizeLargeErrorTitle}</div>
              <div>
                {texts?.fileSizeLargeErrorMessage?.replace(
                  "{size}",
                  formatBytes(maxSize || 0)
                )}
              </div>
            </section>
          )}

          {showFileSmallError && (
            <section>
              <div>{texts?.fileSizeSmallErrorTitle}</div>
              <div>
                {texts?.fileSizeSmallErrorMessage?.replace(
                  "{size}",
                  formatBytes(minSize || 0)
                )}
              </div>
            </section>
          )}

          {showTooManyFilesError && (
            <section>
              <div>{texts?.tooManyFilesErrorTitle}</div>
              <div>
                {texts?.tooManyFilesErrorMessage?.replace(
                  "{size}",
                  String(maxFiles)
                )}
              </div>
            </section>
          )}

          {showFileDimensionsTooSmallError && (
            <section>
              <div>
                {texts?.fileDimensionsErrorTooSmall
                  ?.replace("{minWidth}", `${imageDimensions?.minWidth}`)
                  .replace("{minHeight}", `${imageDimensions?.minHeight}`)}
              </div>
            </section>
          )}

          {showFileDimensionsTooLargeError && (
            <section>
              {texts?.fileDimensionsErrorTooLarge
                ?.replace("{maxWidth}", `${imageDimensions?.maxWidth}`)
                .replace("{maxHeight}", `${imageDimensions?.maxHeight}`)}
            </section>
          )}

          {uploadError && (
            <section>
              <div>{texts?.uploadErrorMessage}</div>
            </section>
          )}
        </main>
        <footer className="DropzoneInvalidModal__footer">
          <Button
            type="primary"
            text={texts?.close || ""}
            onClick={onClose}
            id={errorButtonId}
          />
        </footer>
      </div>
    </Popup>
  );
};
