import _find from "lodash/find";
import { FacetType, IFacet, IFacetResponse } from "shared-services";

export function getFacetListByType(
  facetResponse: IFacetResponse[],
  systemName: FacetType
): IFacet[] {
  const facetGroup = _find(facetResponse, { systemName });

  if (!facetGroup) {
    return [];
  }

  return (
    facetGroup.facetGroups.reduce<IFacet[]>(
      (acc, facetGroup) => [...acc, ...facetGroup.facets],
      []
    ) || []
  );
}
