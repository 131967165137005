export enum HeapCustomEvent {
  BreakdownCreated = "Breakdown Created",
  TapeRequestSent = "Tape Request Sent",
  AgentRequestReceived = "Agent - Tape Request Received",
  CDToPerformerRequestReceived = "CD 2 Performer - Tape Request Received",
  AgentToPerformerRequestReceived = "Agent 2 Performer - Tape Request Received",
  AgentSendTapeRequestToPerformer = "Agent - Send Tape Request To Performer",
  AgentTapeRequestUpload = "Agent - Tape Request upload",
  PerformerTapeRequestUpload = "Performer - Tape Request upload",
}
