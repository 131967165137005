import React, { MouseEvent } from "react";
import cx from "classnames";
import { Photo } from "../../../Atoms/Photo/Photo";

import "./Card.scss";
import { dataTestIds } from "data-testids";

export interface ICardProps {
  /** Url for loading image */
  imageUrl: string;
  /**  Text to show the users full name */
  fullName: string;
  /** Text to show the membership type for the user */
  membershipType: string;
  /** Text to show the contract expiration days */
  membershipExp?: string | number;
  /** Text to show name of the user agent */
  performerAgent?: string;
  /** Email of the user agent */
  performerAgentEmail?: string;
  /** Text to display age of performer */
  performerAge?: string | number;
  /** Link for the user name to be clickable */
  linkUrl?: string;
  /** Alternate text for image */
  altText?: string;
  /** Handler for click event */
  handleClick?: (event: MouseEvent<HTMLDivElement>) => void;
  /** Card is active (selected) */
  active?: boolean;
  /** Optional css class for wrapper */
  className?: string;
  /** Optional id for wrapper */
  id?: string;
  /** Optional css modifier for cards style */
  cardSize?: "small" | "normal" | "tall";
  /** Flag to specify whether performer has audioclips or not */
  hasAudioclips?: boolean;
  /** Flag to specify whether performer has showreels or not */
  hasShowreels?: boolean;
  membershipExpiredDays?: string;
  onAudioClipClick?: () => void;
  onShowreelsClick?: () => void;
}

export class Card extends React.Component<ICardProps> {
  showreelClickHandler = (e: MouseEvent<HTMLSpanElement>) => {
    const { onShowreelsClick } = this.props;
    if (onShowreelsClick) {
      e.stopPropagation();
      e.preventDefault();
      onShowreelsClick();
    }
  };

  audioClipClickHandler = (e: MouseEvent<HTMLSpanElement>) => {
    const { onAudioClipClick } = this.props;
    if (onAudioClipClick) {
      e.stopPropagation();
      e.preventDefault();
      onAudioClipClick();
    }
  };

  public render() {
    const {
      className,
      imageUrl,
      altText,
      fullName,
      membershipType,
      linkUrl,
      active,
      handleClick,
      membershipExp,
      performerAge,
      performerAgent,
      id,
      cardSize = "normal",
      hasAudioclips,
      hasShowreels,
      membershipExpiredDays,
      onAudioClipClick,
      onShowreelsClick,
    } = this.props;

    const cardClassNames = [
      "c-card",
      `c-card--${cardSize}`,
      { "c-card--active": active, "c-card--clickable": handleClick },
      className,
    ];

    const isIconsSectionVisible = hasShowreels || hasAudioclips;

    return (
      <div
        className={cx(cardClassNames)}
        onClick={handleClick}
        id={id}
        data-testid={dataTestIds.componentLibrary["Organisams.Cards.card"]}
        tabIndex={0}
        onKeyPress={this.handleKeyPress}
      >
        <div className="c-card__container">
          <div className="c-card__image-container">
            <Photo
              className="c-card__image"
              src={imageUrl}
              alt={altText}
              hasLoadingSpinner={true}
            />
            {membershipExp && (
              <div className="c-card__expiration-warning">
                {" "}
                {membershipExp}{" "}
              </div>
            )}
            {membershipExpiredDays && (
              <div className="c-card__expired-warning">
                {membershipExpiredDays}
              </div>
            )}
          </div>
          <div className="c-card__content">
            <div className="c-card__name">
              {linkUrl ? (
                <a
                  href={linkUrl}
                  target="_blank"
                  className="c-card__name-link"
                  onClick={this.handleCardNameClick}
                >
                  {fullName.toUpperCase()}
                </a>
              ) : (
                <div>{fullName.toUpperCase()}</div>
              )}
            </div>
            <div className="c-card__member-type">{membershipType}</div>
            {performerAge && (
              <div className="c-card__performer-age">{performerAge}</div>
            )}
            {performerAgent && this.renderAgentLabel()}
            {isIconsSectionVisible && (
              <div className="c-card__icons-section">
                {hasShowreels && (
                  <span
                    className={cx("c-card__icon icon-showreel", {
                      "c-card__icon--clickable": onShowreelsClick,
                    })}
                    tabIndex={0}
                    onClick={this.showreelClickHandler}
                  />
                )}
                {hasAudioclips && (
                  <span
                    role="button"
                    className={cx("c-card__icon icon-audio", {
                      "c-card__icon--clickable": onAudioClipClick,
                    })}
                    tabIndex={0}
                    onClick={this.audioClipClickHandler}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      this.props.handleClick?.(
        e as unknown as React.MouseEvent<HTMLDivElement>
      );
    }
  };

  private handleCardNameClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.props.linkUrl) {
      e.stopPropagation();
    }
  };

  private renderAgentLabel = () => {
    const { performerAgentEmail, performerAgent } = this.props;

    const agentClassNames = [
      "c-card__performer-agent",
      { "g-alternative-link": performerAgentEmail },
    ];

    const content = React.createElement(
      performerAgentEmail ? "a" : "div",
      {
        className: cx(agentClassNames),
        onClick: performerAgentEmail && ((event) => event.stopPropagation()),
        href: performerAgentEmail && `mailto:${performerAgentEmail}`,
      },
      performerAgent
    );

    return <div className="c-card__performer-agent-wrapper">{content}</div>;
  };
}
