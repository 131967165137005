import React from "react";
import cx from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { Button, SectionContainer } from "component-library";
import { IAudioModel } from "shared-types";
import { getLocalUrl } from "common";
import { UserRoleManager, UserRoles } from "core";
import { AudioPlayerContainer } from "mediaModules/media/components/AudioPlayerContainer";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import withMediaData, {
  IWithMediaDataInjectedProps,
  withMediaAssetType,
} from "mediaModules/media/hoc/withMediaData";
import {
  MediaAsset,
  MediaAssetType,
  TranscodeStatus,
} from "mediaModules/media/models";
import { scrollRefToTop } from "mediaModules/media/utils/page";

import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";
import compose from "shared/utils/compose";
import { PromiseContainer } from "shared/utils/promise";
import { PERFORMER_PROFILE_PATH } from "shared/constants";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  ITranslationContextProps,
  Translator,
  withTranslationF,
} from "contexts/TranslationContext";

import {
  Breadcrumbs,
  IBreadcrumbsContent,
} from "components/common/Breadcrumbs";

import {
  getAlertMessage,
  getDisplayLimitAlert,
  getSectionContainerProps,
  IAudioPageContent,
  IAudioPageContentKeys,
  PopupTypes,
} from "./AudioPageContent";
import { AudioPageForm } from "./components/AudioPageForm";

import "./AudioPage.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";
import { IBreadcrumbsContentKeys } from "components/common/Breadcrumbs/Breadcrumbs";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

export interface IAudioPageProps
  extends ITranslationContextProps,
    IGlobalContextProps,
    RouteComponentProps,
    IWithMediaDataInjectedProps {}

export interface IAudioPageState {
  content: IAudioPageContent;
}

export class AudioPage extends React.Component<
  IAudioPageProps,
  IAudioPageState
> {
  private deletePromise: PromiseContainer<any> = new PromiseContainer();
  private pageRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: Readonly<IAudioPageProps>) {
    super(props);

    this.state = {
      content: AudioPage.mapTranslation(props.translator),
    };
  }

  public render() {
    const { content } = this.state;
    const {
      mediaAssets,
      storageSpace,
      isMediaMetadataLoading,
      areMediaObjectsLoading,
      isPatching,
      changeMediaAssetsOrder,
    } = this.props;

    const breadcrumbsContent: IBreadcrumbsContent =
      this.getBreadcrumbsTranslation();
    const sectionContainerProps = getSectionContainerProps(
      content,
      this.closePage
    );

    return (
      <div ref={this.pageRef}>
        <Breadcrumbs content={breadcrumbsContent} />
        <div className="g-content-area p-audio-page">
          <div className="g-col-lg-12 p-audio-page__container">
            <SectionContainer {...sectionContainerProps}>
              <div className="p-audio-page__content">
                <AudioPageForm
                  content={content.editAudioListForm}
                  mediaAssets={mediaAssets}
                  editMediaAsset={this.redirectToEditAudioPage}
                  hideMediaAssets={this.hideMediaAssets}
                  playback={this.playback}
                  deleteMediaAssets={this.deleteMediaAssetsAlert}
                  changeMediaAssetsOrder={changeMediaAssetsOrder}
                  addNewMediaAsset={this.addNewMediaAsset}
                  downloadMediaAsset={this.downloadMediaAsset}
                  isBlocked={isPatching}
                  storageSpace={storageSpace}
                  isMediaMetadataLoading={isMediaMetadataLoading}
                  areMediaObjectsLoading={areMediaObjectsLoading}
                />
                {this.renderFormButtons()}
              </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    );
  }

  public static getDerivedStateFromProps(
    nextProps: IAudioPageProps,
    prevState: IAudioPageState
  ) {
    let stateUpdates = {};

    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(prevState.content)
    ) {
      const content = AudioPage.mapTranslation(nextProps.translator);

      stateUpdates = { content };
    }

    return stateUpdates;
  }

  public async componentDidMount() {
    try {
      GAEvents.trackPageLoad("Edit audio page is loaded", MediaAssetType.Audio);
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }
  }

  private renderFormButtons = () => {
    const { content } = this.state;
    const { areMediaObjectsLoading } = this.props;

    return (
      <div className="p-audio-page__buttons">
        <div className="p-audio-page__button">
          <Button
            type="primary"
            onClick={this.returnToCVEditor}
            text={content.returnToProfileButton}
          />
        </div>
        <div className="p-audio-page__button">
          <Button
            className={cx("p-audio-page__add-audio-clip", {
              "p-audio-page__add-audio-clip--disabled": areMediaObjectsLoading,
            })}
            type="secondary"
            onClick={this.addNewMediaAsset}
            text={content.addNewAudioButton}
          />
        </div>
      </div>
    );
  };

  private static mapTranslation = (translator: Translator): IAudioPageContent =>
    translator.compileTranslationObject<IAudioPageContent>(
      IAudioPageContentKeys,
      "common"
    );

  private getBreadcrumbsTranslation = () =>
    this.props.translator
      .getTranslator("breadcrumbs", true)
      .createTranslationObject<IBreadcrumbsContent>(IBreadcrumbsContentKeys);

  private redirectTo = (path) => {
    scrollRefToTop(this.pageRef);
    this.props.history.push(getLocalUrl(path));
  };

  private redirectToAddNewAudioPage = () => {
    this.closePopup();
    this.redirectTo("media/audio/new");
  };

  private redirectToEditAudioPage = ({ id }: MediaAsset) =>
    this.redirectTo(`media/audio/edit/${id}`);

  private returnToCVEditor = () => {
    const { agentCVLink } = this.state.content;

    GAEvents.editMediaAssets.trackCloseClick(MediaAssetType.Audio);

    window.location.assign(this.getCVLink(PERFORMER_PROFILE_PATH, agentCVLink));
  };

  private getCVLink = (performerCVLink: string, agentCVLink: string) => {
    return UserRoleManager.isInRole(UserRoles.agent)
      ? agentCVLink
      : performerCVLink;
  };

  private closePage = () => this.props.history.push(getLocalUrl());

  private addNewMediaAsset = () => {
    GAEvents.editMediaAssets.trackAddClick(MediaAssetType.Audio);

    const { content } = this.state;
    const { globalContext } = this.props;

    const message = getAlertMessage(
      content,
      PopupTypes.Copyright,
      this.redirectToAddNewAudioPage,
      this.closePopup,
      "notice"
    );
    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
  };

  private playback = (mediaAsset: MediaAsset) => {
    if (mediaAsset.transcodeStatus === TranscodeStatus.Complete) {
      const controls = this.getPlayerPanel(mediaAsset);
      const audio = this.mapAudioModel(mediaAsset);

      const audioPlayer = (
        <AudioPlayerContainer
          playerConfig={{
            isAutoPlay: true,
            tracks: [],
            currentTrack: audio,
            selectTrack: () => undefined,
          }}
          closeAction={this.closeAudioPopupWrapper()}
          controlPanelButtons={controls}
        />
      );

      const popupProps = {
        hideCloseButton: true,
        fullHeight: true,
      };

      this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
        content: audioPlayer,
        popupProps,
      });
    }
  };

  private hideMediaAssets = async (mediaAssets: MediaAsset[]) => {
    const { content } = this.state;
    const { hideMediaAssets, globalContext } = this.props;

    const displayLimitReachedCallback = () => {
      const message = getDisplayLimitAlert(content.displayLimitAlert, () =>
        this.closePopup()
      );
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );
    };

    await hideMediaAssets(mediaAssets, displayLimitReachedCallback);
  };

  private deleteMediaAssetsAlert = (deletedMediaAssets: MediaAsset[]) => {
    const { content } = this.state;
    const { globalContext, removeMediaAssets } = this.props;

    this.deletePromise.setPromise();
    this.deletePromise.promise
      .then(async () => await removeMediaAssets(deletedMediaAssets))
      .catch((error) =>
        captureExceptionEvent(error, authorizationService.getDecodedUserToken())
      );

    const closePopup = () => {
      this.deletePromise.reject("Popup is closed");
      this.closePopup();
    };

    const message = getAlertMessage(
      content,
      PopupTypes.Delete,
      this.deletePromise.resolve,
      closePopup
    );
    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );

    return this.deletePromise.promise;
  };

  private downloadMediaAsset = (mediaAsset: MediaAsset) =>
    (window.location.href = mediaAsset.downloadLink);

  private getPlayerPanel = (mediaAsset: MediaAsset) => {
    const { controls } = this.state.content.editAudioListForm;
    const buttons: (IActionButtonProps & { key: string | number })[] = [];

    buttons.push({
      key: "edit",
      icon: "edit",
      label: controls.edit,
      onClick: this.closeAudioPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackEditClick(
          MediaAssetType.Audio
        );
        this.redirectToEditAudioPage(mediaAsset);
      }),
    });

    buttons.push({
      key: "delete",
      icon: "delete",
      label: controls.delete,
      onClick: this.closeAudioPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackDeleteClick(
          MediaAssetType.Audio
        );
        this.deleteMediaAssetsAlert([mediaAsset]);
      }),
    });

    if (!UserRoleManager.isInRole(UserRoles.castingDirector)) {
      buttons.push({
        key: "hide",
        icon: "hide",
        label: controls.hide,
        onClick: this.closeAudioPopupWrapper(() => {
          GAEvents.editMediaAssets.playerControls.trackHideClick(
            MediaAssetType.Audio
          );
          this.hideMediaAssets([mediaAsset]);
        }),
      });
    }

    buttons.push({
      key: "download1",
      icon: "download1",
      label: controls.download,
      onClick: this.closeAudioPopupWrapper(() => {
        GAEvents.editMediaAssets.playerControls.trackDownloadClick(
          MediaAssetType.Audio
        );
        this.downloadMediaAsset(mediaAsset);
      }),
    });

    return buttons;
  };

  private mapAudioModel = (mediaAsset: MediaAsset) => {
    const { durationInMs, title, sources, id } = mediaAsset;

    const audio: IAudioModel = {
      id,
      src: sources[0],
      duration: durationInMs,
      fileName: title,
    };

    return audio;
  };

  private closeAudioPopupWrapper =
    (wrappedFunction?: () => any | Promise<any>) => async () => {
      const result = wrappedFunction && wrappedFunction();

      if (result instanceof Promise) {
        await result;
      }

      this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
        content: null,
      });
    };

  private closePopup = () =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.closeAllGlobalAlert
    );
}

export default compose(
  withTranslationF("editAudioPage"),
  withMediaAssetType(MediaAssetType.Audio),
  withMediaData,
  withGlobalContext
)(AudioPage);
