import React from "react";

import { Pill } from "../../../../Atoms/Pill/Pill";

import "./InboxRowLabel.scss";

export interface IInboxRowLabelProps {
  text: string;
  className?: string;
}

export const InboxRowLabel: React.FC<IInboxRowLabelProps> = ({
  text,
  className,
}) => (
  <div className="c-inbox-row-label">
    <Pill title={text} size="md" className={className} theme="dark">
      {text}
    </Pill>
  </div>
);
