import React, { FC, useMemo } from "react";
import _merge from "lodash/merge";
import _sortBy from "lodash/sortBy";
import { getTootlipProps } from "../../Molecules/QuestionTooltip";
import { MultiSelectList } from "../MultiSelectList";
import { Helper } from "../Helper/Helper";
import { Input } from "../../Molecules/Input/Input";
import { InputLabelStyleTypes } from "../../Atoms/InputsCommon/InputLabel";
import { RadioList } from "../../Molecules/RadioList/RadioList";
import {
  facetToOption,
  optionToFacet,
} from "../../Functional/converters/facetConverter";
import ToggleFormik from "../../Atoms/FormikInputs/ToggleFormik";

const HNE_LIMIT = 10;
const WARNING_LIMIT = 3;

export type HeritageAndEthnicityContent = typeof heritageAndEthnicityFallback;

interface Props {
  heritageAndEthnicityFacet: FacetCategoryViewModel;
  ethnicBackgroundFacet: FacetCategoryViewModel;
  model: IHeritageAndEthnicityModel;
  handleChange: (model: IHeritageAndEthnicityModel) => void;
  content: HeritageAndEthnicityContent;
  showAlertPopUp: () => void;
  handleTrack?: (values: any) => void;
  names?: {
    heritageAndEthnicity: string;
    ethnicBackground: string;
    heritageAndEthnicityInfo: string;
    isVisibleHeritageAndEthnicity: string;
  };
  isFormik?: boolean;
}

const HeritageAndEthnicityList: FC<Props> = ({
  heritageAndEthnicityFacet,
  ethnicBackgroundFacet,
  model,
  handleChange,
  content,
  handleTrack,
  showAlertPopUp,
  names,
  isFormik,
}) => {
  const translations = _merge(heritageAndEthnicityFallback, content);

  const { heritageAndEthnicity = [], ethnicBackground = [] } = model;

  const heritageAndEthnicityOptions = useMemo(() => {
    return heritageAndEthnicityFacet.facets.map(facetToOption);
  }, [heritageAndEthnicityFacet]);

  const ethnicBackgroundOptions = useMemo(() => {
    return ethnicBackgroundFacet.facets.map(facetToOption);
  }, [ethnicBackgroundFacet]);

  const sortAndMapList = (list: any) => {
    return _sortBy(list.map(facetToOption), ["name"]);
  };

  const heritageValue = useMemo(() => {
    return sortAndMapList(heritageAndEthnicity);
  }, [heritageAndEthnicity]);

  const ethnicBackgroundValue = useMemo(() => {
    return sortAndMapList(ethnicBackground);
  }, [ethnicBackground]);

  const loadData = (searchString: string) =>
    Promise.resolve(
      heritageAndEthnicityOptions.filter(
        ({ name, value }: { name: string; value: number }) =>
          name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) &&
          !heritageValue.some(
            ({ value: selectedValue }) => selectedValue === value
          )
      )
    );

  const selectedValuesLength =
    ethnicBackgroundValue.length + heritageValue.length;
  const isDisabled = selectedValuesLength >= HNE_LIMIT;

  const handleChangeHeritageAndEthnicityValues = async (values: any[]) => {
    const upcomingLength = ethnicBackgroundValue.length + values.length;

    if (
      upcomingLength > WARNING_LIMIT &&
      upcomingLength > selectedValuesLength
    ) {
      await showAlertPopUp();
    }

    handleTrack?.(values);

    handleChange({
      ...model,
      heritageAndEthnicity: values.map((value) => ({
        ...optionToFacet(value),
        id: Number(optionToFacet(value).id),
      })),
    });
  };

  const handleEthnicBackground = async (values: any[]) => {
    const upcomingLength = heritageValue.length + values.length;

    if (
      upcomingLength > WARNING_LIMIT &&
      upcomingLength > selectedValuesLength
    ) {
      await showAlertPopUp();
    }

    handleTrack?.(values);

    handleChange({
      ...model,
      ethnicBackground: values.map((value) => ({
        ...optionToFacet(value),
        id: Number(optionToFacet(value).id),
      })),
    });
  };

  return (
    <>
      <div className="flex">
        <div className="sm:flex-[1] pr-0 md:flex-[0.5] md:pr-[19px] ">
          <MultiSelectList
            type="dropdown"
            content={{
              label: translations.ethnicBackgroundLabel,
              placeholder: translations.ethnicBackgroundPlaceholder,
              tooltip: getTootlipProps(
                translations.ethnicBackgroundLabel,
                translations.ethnicBackgroundTooltipContent
              ).texts,
            }}
            isDisabled={isDisabled}
            selectedItems={ethnicBackgroundValue}
            name={names?.ethnicBackground ?? "ethnicBackground"}
            list={ethnicBackgroundOptions}
            handleChanges={handleEthnicBackground}
            hideMoveControls
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:gap-[19px] md:items-end">
        <div className="flex-1">
          <MultiSelectList
            type="autocomplete"
            content={{
              label: translations.specificEthnicitiesLabel,
              placeholder: translations.specificEthnicitiesPlaceholder,
              tooltip: getTootlipProps(
                translations.specificEthnicitiesLabel,
                translations.specificEthnicitiesTooltipContent
              ).texts,
            }}
            isDisabled={isDisabled}
            selectedItems={heritageValue}
            name={names?.heritageAndEthnicity ?? "heritageAndEthnicity"}
            loadData={loadData}
            handleChanges={handleChangeHeritageAndEthnicityValues}
            hideMoveControls
          />
        </div>
        <div className="flex-1">
          <Input
            name={names?.heritageAndEthnicityInfo}
            label={translations.heritageAndEthnicityFurtherInformationLabel}
            value={model?.heritageAndEthnicityInfo}
            onChange={(e) => {
              handleChange({
                ...model,
                heritageAndEthnicityInfo: e.target.value,
              });
            }}
            annex={
              <Helper
                tooltip={getTootlipProps(
                  translations.heritageAndEthnicityFurtherInformationLabel,
                  translations.heritageAndEthnicityFurtherInformationTooltipContent
                )}
              />
            }
            maxLength={75}
          />
        </div>
      </div>
      {isFormik ? (
        <div className="mb-6">
          <ToggleFormik
            name={
              names?.isVisibleHeritageAndEthnicity ||
              "isVisibleHeritageAndEthnicity"
            }
            label={translations.heritageAndEthnicityVisibilityLabel}
            className="font-semibold"
          />
        </div>
      ) : (
        <RadioList<boolean>
          name={names?.isVisibleHeritageAndEthnicity}
          value={model.isVisibleHeritageAndEthnicity ?? false}
          label={translations.heritageAndEthnicityVisibilityLabel}
          options={[
            {
              name: translations.heritageAndEthnicityNo,
              value: false,
            },
            {
              name: translations.heritageAndEthnicityYes,
              value: true,
            },
          ]}
          labelStyleType={InputLabelStyleTypes.Secondary}
          isWidthLimited={true}
          className="pr-[19px]"
          valueChanged={(value) =>
            handleChange({ ...model, isVisibleHeritageAndEthnicity: value })
          }
          vertical
          annex={
            <Helper
              tooltip={getTootlipProps(
                translations.heritageAndEthnicityVisibilityLabel,
                translations.heritageAndEthnicityVisibilityTooltipContent
              )}
            />
          }
        />
      )}
      <div className="p-[10px] mb-[30px] max-w-[650px] bg-color-neutral-three">
        {model.isVisibleHeritageAndEthnicity === false && (
          <p
            dangerouslySetInnerHTML={{
              __html: translations.heritageAndEthnicityVisibilityNoInfo,
            }}
          />
        )}
        {model.isVisibleHeritageAndEthnicity === true && (
          <p
            dangerouslySetInnerHTML={{
              __html: translations.heritageAndEthnicityVisibilityYesInfo,
            }}
          />
        )}
      </div>
    </>
  );
};

export default HeritageAndEthnicityList;

export const heritageAndEthnicityFallback = {
  heritageAndEthnicityTitle: "Heritage & Ethnicity",
  heritageAndEthnicityHeader:
    "To indicate your ethnicity, you may select a broader ethnic background or add specific ethnicities in the fields below. Alternatively, you can choose a combination of both options.",
  heritageAndEthnicityWarningDescription:
    "Are you sure you want to add more than 3 ethnicities? Please ensure that you are only adding the most relevant information.",
  ethnicBackgroundLabel: "Add ethnic background:",
  ethnicBackgroundPlaceholder: "Please select",
  ethnicBackgroundTooltipContent: "Add ethnic background tooltip content",
  specificEthnicitiesLabel: "Add specific ethnicities:",
  specificEthnicitiesPlaceholder: "Please start typing...",
  specificEthnicitiesTooltipContent: "Add specific ethnicities tooltip content",
  heritageAndEthnicityFurtherInformationLabel:
    "Further information (optional):",
  heritageAndEthnicityFurtherInformationTooltipContent:
    "Further information tooltip content",
  heritageAndEthnicityVisibilityLabel:
    "Do you want your ethnicity displayed on your profile?",
  heritageAndEthnicityVisibilityTooltipContent:
    "Do you want your ethnicity displayed on your profile content",
  heritageAndEthnicityVisibilityYesInfo:
    "By selecting 'Yes', this information will be visible on your profile at all times.  Everyone who has a link to your profile will be able to see this information, it will be public.  To restrict visibility choose 'No'.",
  heritageAndEthnicityVisibilityNoInfo:
    "By selecting 'No', this information will only be visible if a casting professional filters their Performer search results by ethnicity.",
  heritageAndEthnicityClose: "Close",
  heritageAndEthnicityContinue: "Continue to add",
  heritageAndEthnicityYes: "Yes",
  heritageAndEthnicityNo: "No",
};

export enum FacetCategories {
  MembershipBodies = "Membership Bodies",
  VoiceQuality = "Voice Quality",
  VoiceCharacter = "Voice Character",
  VoiceStyle = "Voice Style",
  FacialHair = "Facial Hair",
  Appearance = "Appearance",
  HairColour = "Hair Colour",
  EyeColour = "Eye Colour",
  HairLength = "Hair Length",
  Nationality = "Nationality",
  BeltNotes = "Belt Notes",
  Disability = "Disability",
  Gender = "Gender",
  HeritageAndEthnicity = "Heritage And Ethnicity",
  EthnicBackground = "Ethnic Background",
  VisasAndWorkingPermits = "Visas & Working Permits",
}

class FacetViewModel {
  id: number = 0;
  name: string = "";
  code: string = "";
  orderKey?: number | null = null;
}

export class FacetCategoryViewModel {
  id: number = 0;
  name: string = "";
  systemName: FacetCategories = FacetCategories.HeritageAndEthnicity;
  maxActiveCount: number = 0;
  facets: FacetViewModel[] = [];
}

export interface IEthnicityFacet {
  id: number;
  name: string;
}

export interface IHeritageAndEthnicityModel {
  heritageAndEthnicityInfo?: string;
  isVisibleHeritageAndEthnicity?: boolean;
  heritageAndEthnicity: IEthnicityFacet[];
  ethnicBackground: IEthnicityFacet[];
}

export class HeritageAndEthnicityModel implements IHeritageAndEthnicityModel {
  heritageAndEthnicity: IEthnicityFacet[] = [];
  ethnicBackground: IEthnicityFacet[] = [];
  heritageAndEthnicityInfo: string = "";
  isVisibleHeritageAndEthnicity: boolean = false;
}
