import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SectionContainer, Button } from "component-library";
import { IAudioModel } from "shared-types";
import diff from "json-patch-gen";

import { getLocalUrl } from "common";
import {
  Breadcrumbs,
  IBreadcrumbsContent,
} from "components/common/Breadcrumbs";
import {
  withTranslation,
  ITranslationContextProps,
  Translator,
} from "contexts/TranslationContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";
import {
  IEditAudioPageContent,
  getSectionContainerProps,
  IEditAudioPageContentKeys,
} from "./EditAudioPageContent";
import { EditAudioForm } from "./components/EditAudioForm";
import { AudioPlayerContainer } from "mediaModules/media/components/AudioPlayerContainer";
import { MediaAsset, MediaAssetType } from "mediaModules/media/models";
import mediaService from "mediaModules/media/services/MediaService";
import { scrollRefToTop } from "mediaModules/media/utils/page";
import { GAEvents } from "mediaModules/media/components/GAEvents";

import "./EditAudioPage.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";
import { IBreadcrumbsContentKeys } from "components/common/Breadcrumbs/Breadcrumbs";
import { captureExceptionEvent } from "shared-services";
import { authorizationService } from "shared-auth";

export interface IEditAudioPageMatchParams {
  id: string;
}

export interface IEditAudioPageProps
  extends ITranslationContextProps,
    IGlobalContextProps,
    RouteComponentProps<IEditAudioPageMatchParams> {}

export interface IEditAudioPageState {
  content: IEditAudioPageContent;
  audioModel: MediaAsset;
  isMediaMetadataLoading: boolean;
}

export class EditAudioPage extends React.Component<
  IEditAudioPageProps,
  IEditAudioPageState
> {
  private pageRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private initialModelSnapshot: string;

  constructor(props: Readonly<IEditAudioPageProps>) {
    super(props);

    this.state = {
      content: EditAudioPage.mapTranslation(props.translator),
      audioModel: { ...new MediaAsset(), isMediaObjectLoading: true },
      isMediaMetadataLoading: true,
    };
  }

  public render() {
    const { content, audioModel, isMediaMetadataLoading } = this.state;

    const breadcrumbsContent: IBreadcrumbsContent =
      this.getBreadcrumbsTranslation();
    const sectionContainerProps = getSectionContainerProps(
      content,
      this.handleCloseClick
    );

    return (
      <div ref={this.pageRef}>
        <Breadcrumbs content={breadcrumbsContent} />
        <div className="g-content-area p-edit-audio-page">
          <div className="g-col-lg-12 p-edit-audio-page__container">
            <SectionContainer {...sectionContainerProps}>
              <div className="p-edit-audio-page__content">
                <EditAudioForm
                  key={audioModel.id}
                  content={content.editAudioForm}
                  audioModel={audioModel}
                  changeModel={this.changeModel}
                  saveChanges={this.saveChanges}
                  showMediaPopup={this.showMediaPopup}
                  isMediaMetadataLoading={isMediaMetadataLoading}
                >
                  {this.renderFormButtons}
                </EditAudioForm>
              </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    );
  }

  public static getDerivedStateFromProps(
    nextProps: IEditAudioPageProps,
    prevState: IEditAudioPageState
  ) {
    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(prevState.content)
    ) {
      const content = EditAudioPage.mapTranslation(nextProps.translator);

      return { content };
    }

    return null;
  }

  public async componentDidMount() {
    try {
      GAEvents.trackPageLoad(
        "Edit individual audio page is loaded",
        MediaAssetType.Audio
      );

      await this.getAudioMetadata();
      await this.getAudioObject();
      this.setInitialModelSnapshot();
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }
  }

  private renderFormButtons = (submit: () => void) => {
    const {
      content,
      audioModel: { isMediaObjectLoading },
    } = this.state;

    return (
      <div className="p-edit-audio-page__buttons">
        <div className="p-edit-audio-page__button">
          <Button
            type="primary"
            onClick={() => this.handleSaveClick(submit)}
            text={content.saveButton}
            disabled={isMediaObjectLoading}
          />
        </div>
        <div className="p-edit-audio-page__button">
          <Button
            type="secondary"
            onClick={this.handleCancelAndCloseClick}
            text={content.cancelButton}
          />
        </div>
      </div>
    );
  };

  private changeModel = (audioModel: MediaAsset) =>
    this.setState({ audioModel });

  private setInitialModelSnapshot = () => {
    this.initialModelSnapshot = JSON.stringify(this.state.audioModel);
  };

  private isModelChanged = () =>
    this.initialModelSnapshot !== JSON.stringify(this.state.audioModel);

  private async getAudioMetadata() {
    const mediaMetadata = await mediaService.getMediaMetadataById(
      this.props.match.params.id
    );
    const audioModel = mediaService.createMediaAssetFromMeta(mediaMetadata);

    this.setState({ audioModel, isMediaMetadataLoading: false });
  }

  private async getAudioObject() {
    const mediaObject = await mediaService.getMediaObjectById(
      this.state.audioModel.mediaInfoId
    );
    const audioModel = mediaService.mapUpdatedMediaObject(
      this.state.audioModel,
      mediaObject
    );

    this.setState({ audioModel });
  }

  private handleSaveClick = (submit: () => void) => {
    GAEvents.editMediaAsset.trackSaveClick(MediaAssetType.Audio);
    submit();
  };

  private handleCancelAndCloseClick = () => {
    GAEvents.editMediaAsset.trackCancelCloseClick(MediaAssetType.Audio);
    this.closePage();
  };

  private handleCloseClick = () => {
    GAEvents.editMediaAsset.trackCloseClick(MediaAssetType.Audio);
    this.closePage();
  };

  private closePage = () => {
    const {
      content: { cancelPopup },
    } = this.state;
    const { globalContext } = this.props;

    if (this.isModelChanged()) {
      const message = new AlertConfigBuilder()
        .initBuildEntity()
        .setDefaultContent({
          closeButtonText: cancelPopup.cancelPopupContinueEditingButton,
          description: cancelPopup.cancelPopupDescription,
          title: cancelPopup.cancelPopupTitle,
        })
        .setCloseButton({
          type: "secondary",
          click: () =>
            globalContext.notifyListener(GlobalEventTypes.closeAllGlobalAlert),
        })
        .addButton({
          type: "primary",
          click: () => {
            globalContext.notifyListener(GlobalEventTypes.closeAllGlobalAlert);
            this.redirectToEditMediaAssets();
          },
          name: cancelPopup.cancelPopupCloseButton,
        })
        .build();

      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );
    } else {
      this.redirectToEditMediaAssets();
    }
  };

  private saveChanges = async () => {
    const initialModel = JSON.parse(this.initialModelSnapshot);
    const { title } = this.state.audioModel;

    const difference = diff(
      {
        title: initialModel.title,
      },
      {
        title,
      }
    );

    if (difference.length) {
      try {
        await mediaService.updateMediaMetadata(
          this.props.match.params.id,
          difference
        );
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    }
    this.redirectToEditMediaAssets();
  };

  private redirectToEditMediaAssets = () => {
    scrollRefToTop(this.pageRef);
    this.props.history.push(getLocalUrl("media/audio"));
  };

  private showMediaPopup = (audioModel: MediaAsset) => {
    const { id, title, sources = [], durationInMs } = audioModel;
    const controls = this.getPlayerPanel(audioModel);

    const currentTrack: IAudioModel = {
      id,
      fileName: title,
      src: sources[0],
      duration: durationInMs,
    };

    const audioPlayer = (
      <AudioPlayerContainer
        closeAction={this.closeMediaPopup}
        playerConfig={{
          currentTrack,
          isAutoPlay: true,
          tracks: [],
          selectTrack: () => undefined,
        }}
        controlPanelButtons={controls}
      />
    );

    const popupProps = {
      hideCloseButton: true,
      fullHeight: true,
    };

    this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
      content: audioPlayer,
      popupProps,
    });
  };

  private getPlayerPanel = (mediaAsset: MediaAsset) => {
    const { downloadButton } = this.state.content.editAudioForm;

    const buttons: Array<IActionButtonProps & { key: string | number }> = [];
    buttons.push({
      key: "download1",
      icon: "download1",
      label: downloadButton,
      onClick: () => {
        GAEvents.editMediaAsset.trackDownloadClick(MediaAssetType.Audio);
        window.location.href = mediaAsset.downloadLink;
        this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
          content: null,
        });
      },
    });

    return buttons;
  };

  private closeMediaPopup = () => {
    this.props.globalContext.notifyListener(GlobalEventTypes.showPopup, {
      content: null,
    });
  };

  private getBreadcrumbsTranslation = () =>
    this.props.translator
      .getTranslator("breadcrumbs", true)
      .createTranslationObject<IBreadcrumbsContent>(IBreadcrumbsContentKeys);

  private static mapTranslation = (translator: Translator) =>
    translator.compileTranslationObject<IEditAudioPageContent>(
      IEditAudioPageContentKeys,
      "common"
    );
}

export default withTranslation(
  withGlobalContext(EditAudioPage),
  "editAudioClipPage"
);
