import authorizationService from "../services/authorization/authorizationService";
import { UserAttributes } from "../services/authorization";
import { MemberGroups } from "shared-types";
import { checkIsCastingBBC } from "./checkIsCastingBBC";

export function checkIsCD() {
  return (
    [MemberGroups.castingDirector, MemberGroups.searchOnlyCD].includes(
      authorizationService.getUserAttribute(UserAttributes.MemberGroup)
    ) || checkIsCastingBBC()
  );
}
