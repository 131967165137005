export enum BrazeCustomEvents {
  PerformerJoiningStarted = "registration_started",
  PerformerJoiningCompleted = "registration_completed",
  CDJoiningStarted = "cd_registration_started",
  CDJoiningCompleted = "cd_registration_completed",
  TapeRequestSent = "tape_request_sent",
  TapeRequestSubmitted = "tape_request_submitted",
  SubscriptionPurchaseNewMember = "Subscription Purchase New Member",
  SubscriptionPurchaseRenewal = "Subscription Purchase Renewal",
  SubscriptionPurchaseReactivation = "Subscription Purchase Reactivation",
  AddOnBundlePurchaseNewMember = "AddOn Bundle Purchase New Member",
  AddOnBundlePurchaseRenewal = "AddOn Bundle Purchase Renewal",
  AddOnBundlePurchaseReactivation = "AddOn Bundle Purchase Reactivation",
}
