import { HttpClient } from "./HttpClient";

import { getLocationUrl } from "./UrlBuilder";

import { Location, GoogleLocation } from "models";
import { parseToUrlQuery } from "shared/utils/http";

export class LocationService {
  private httpClient: HttpClient = new HttpClient();
  private sessionToken = Math.random().toString(36).substr(2);

  public async searchForLocation(
    searchString: string,
    language: string,
    excludeIds?: string[]
  ) {
    const requiredLocationTypes = ["geocode"];
    const requestUrl = getLocationUrl(
      "locationsearch",
      parseToUrlQuery({
        searchText: searchString,
        langcode: language,
        types: requiredLocationTypes,
        sessionToken: this.sessionToken,
      })
    );

    let { object: locations } = await this.httpClient.get<Location[]>(
      requestUrl
    );

    if (excludeIds) {
      locations = locations.filter(
        (location) => !excludeIds.some((id) => location.place_id === id)
      );
    }

    return locations;
  }

  public async getRoundedLocation(placeId: string, language: string) {
    const requestUrl = getLocationUrl(
      `location/google/${placeId}`,
      parseToUrlQuery({ langcode: language })
    );

    const response = await this.httpClient.get<GoogleLocation>(requestUrl);

    return response.object;
  }

  public async getLocationInfo(placeId: string, language: string) {
    const requestUrl = getLocationUrl(
      `location/${placeId}`,
      parseToUrlQuery({ langcode: language })
    );

    const response = await this.httpClient.get<GoogleLocation>(requestUrl);

    return response.object;
  }

  public async getLocationsByGooglePlaceIds(
    placeIds: string[],
    localization: string
  ) {
    const googleLocationsPromises = placeIds.map((placeId) =>
      this.getLocationInfo(placeId, localization)
    );

    const newLocations = await Promise.all(googleLocationsPromises);

    return newLocations.map((newLocation) => {
      const nameParts = [
        newLocation.administrativeAreaLevel2,
        newLocation.administrativeAreaLevel1,
        newLocation.country,
      ];

      const description = nameParts.filter(Boolean).join(", ");

      return { googlePlaceId: newLocation.id, value: description };
    });
  }
}

export default new LocationService();
