// @ts-nocheck
import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import {
  IBaseInputProps,
  InputSizes,
} from "../../Atoms/InputsCommon/BaseInputProps";
import { Input } from "../Input/Input";
import { IOption } from "../../../generics/interfaces";
import { omit } from "../../../generics/object-manipulation";
import { getWindowWidth } from "../../../generics/dom-extensions";

// import SelectArrows from "-!svg-react-loader?name=CalendarIcon!src/images/svg/icons/arrow-select.svg";
import { ReactComponent as SelectArrows } from "../../../images/svg/icons/arrow-select.svg";
import "./DropdownInput.scss";
import { InputSearchIcon } from "../../Atoms/InputsCommon/InputSearchIcon";
import { getScrollWidth } from "../../../generics/scroll-width-detection";

const DEFAULT_MAX_RESULTS = 6;
const BORDER_WIDTH = 2;
const SCROLL_WIDTH = getScrollWidth();

export enum ListOpenDirection {
  Up = "up",
  Down = "down",
}

export type IDropdownInputProps<T> =
  | IBaseDropdownInputWithComparerProps<T>
  | IBaseDropdownInputProps<boolean | string>
  | IBaseDropdownInputProps<number>
  | IBaseDropdownInputProps<string>;

interface IBaseDropdownInputWithComparerProps<T>
  extends IBaseDropdownInputProps<T> {
  /**
   * compare function for complex value types. If this returns true, it means the two values are equal.
   * For string and number types, the component defaults internally to equality so you don't need to provide anything.
   */
  valueComparer: (v1: T, v2: T) => boolean;
}

export type DropdownOptionsSearchCallback<T> = (
  searchText: string
) => Promise<Array<IOption<T>>>;

export interface IOptionGroup<T> {
  label: string;
  options: Array<IOption<T>>;
}

export interface IDropdownSearchInput {
  /**
   * Show search input
   */
  show: boolean;

  /**
   * Text for search input placeholder
   */
  placeholder?: string;
}

interface IBaseDropdownInputProps<T>
  extends IBaseInputProps<HTMLInputElement, T> {
  /**
   * variant of rendering dropdown
   */
  variant: "autocomplete" | "select";

  /**
   * The list of entries to search from, or a callback that can be used to search for the options
   */
  options?: Array<IOption<T>> | DropdownOptionsSearchCallback<T>;

  /**
   * The grouped list of entries
   */
  groupedOptions?: Array<IOptionGroup<T>>;

  /**
   * Externally controlled value of the component. If T is a complex type, valueComparer needs
   * to be provided to find the value properly in the list of options
   */
  value?: T;
  /**
   * Optional
   * Externally controlled selected option.
   * No value comparer needed here, it will find it on its own.
   */
  selected?: IOption<T>;
  /**
   * Optional
   * Maximum number of items to show in the dropdown (defaults to 6)
   */
  maxResults?: number;
  /**
   * Optional
   * Callback to selecting an option, directly providing the value
   */
  valueChanged?: (value?: T | undefined) => void;
  /**
   * Optional
   * Callback to selecting an option, providing the option in full
   */
  optionSelected?: (item?: IOption<T> | undefined) => void;
  /**
   * Optional
   * Callback to typing in the input
   */
  inputChanged?: (term: string) => void;
  /**
   * Optional
   * Control externally whether the dropdown is opened or not
   */
  open?: boolean;
  /**
   * Optional
   * Define which direction dropdown list will open
   */
  listOpenDirection?: ListOpenDirection;
  /**
   * Optional
   * If true, keeps the dropdown open while selecting, but will still close normally via tabbing or clicking out of it
   */
  keepOpenOnSelect?: boolean;
  /**
   * Optional
   * Optional css class for the topmost div
   */
  className?: string;
  /**
   * Optional
   * optional id for the list box (defaults to a random one)
   */
  listboxID?: string;
  /**
   * Optional
   * Number of input characters that must be entered before showing results. Not used in 'select' variant.
   */
  minSearchLength?: number;
  /**
   * Optional
   * Whether to allow free text to be entered. This only prevents resetting the text value in the input so you can use it externally.
   *
   * If free text is disallowed and the search term changes, tabbing out of the autocomplete resets the search term to match the previously selected value
   *
   * If free text is allowed, changing the search term clears the value and calls valueChanged and optionSelected with undefined and tabbing out of the autocomplete retains the search term in the input.
   *
   * NOTE: to use the string value use the inputChanged prop (see above).
   *
   * TODO: implement allowing free text according to specs once said specs are received. Change this JSDoc to reflect the new specs behavior.
   */
  allowsFreeText?: boolean;
  /**
   * Optional
   * Invoke callback when dropdown gets open
   */
  onOpen?(): void;
  /**
   * Optional
   * Invoke callback when dropdown gets closed
   */
  onClose?(): void;
  /**
   * Optional
   * Truncate overflowing options regardless screen width
   */
  truncateOptions?: boolean;
  /**
   * Optional
   * Don't truncate overflowing options on small screen
   */
  notTruncateOptionsOnSmall?: boolean;
  /**
   * Optional
   * Wrap option text in to few lines if large
   */
  wrappedOptions?: boolean;
  /**
   * Show input in a dropdown for option filtering
   */
  searchInput?: IDropdownSearchInput;
  /**
   * Optional
   * Replace default mechanism of filtering for autocomplete and inner search
   */
  optionsFilter?: (filteredOption: IOption<T>, searchString: string) => boolean;
  /**
   * Optional
   * Show search icon
   */
  withSearchIcon?: boolean;
  /**
   * Optional
   * Show delete text icon
   */
  withRemoveSearchTextIcon?: boolean;
  /**
   * Optional
   * Gives input element a white bg
   */
  whiteInput?: boolean;
}

interface IDropdownInputState<ValueType> {
  isOpen: boolean;
  searchTerm: string;
  filterTerm: string;
  activeItemIndex: number;
  loadedOptions?: Array<IOption<ValueType>>;
}

export class DropdownInput<ValueType> extends React.Component<
  IDropdownInputProps<ValueType>,
  IDropdownInputState<ValueType>
> {
  private ref = React.createRef<HTMLDivElement>();
  private listContainerRef = React.createRef<HTMLDivElement>();
  private listRef = React.createRef<HTMLUListElement>();
  private minSearchLength = 3;
  private keyDownHandlers = {
    ArrowDown: (
      event: React.KeyboardEvent<HTMLInputElement>,
      filteredOptions: Array<IOption<ValueType>>
    ) => {
      event.preventDefault();
      this.arrowKeyHandler("ArrowDown", filteredOptions);
    },

    ArrowUp: (
      event: React.KeyboardEvent<HTMLInputElement>,
      filteredOptions: Array<IOption<ValueType>>
    ) => {
      event.preventDefault();
      this.arrowKeyHandler("ArrowUp", filteredOptions);
    },

    Enter: (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (this.props.variant === "select") {
        this.open();
      }

      /**
       * When in select mode, only select an option when you can see the list
       */
      if (
        (this.props.variant === "select" && this.state.isOpen) ||
        this.props.variant === "autocomplete"
      ) {
        const index = this.state.activeItemIndex;

        if (index > -1) {
          this.selectItem(index);
        }
      }
    },

    Escape: (event: React.KeyboardEvent<HTMLInputElement>) =>
      this.setState({ activeItemIndex: -1 }, () => {
        event.preventDefault();
        this.clearValue();
        if (this.inputRef.current) {
          this.inputRef.current.blur();
        }
        this.close();
      }),

    Tab: () => this.tabHandler(),
  };
  private searchInputKeydownHandlers = {
    ArrowDown: (
      event: React.KeyboardEvent<HTMLInputElement>,
      filteredOptions: Array<IOption<ValueType>>
    ) => {
      event.preventDefault();
      this.handleSearchInputArrowKeyPress("ArrowDown", filteredOptions);
    },

    ArrowUp: (
      event: React.KeyboardEvent<HTMLInputElement>,
      filteredOptions: Array<IOption<ValueType>>
    ) => {
      event.preventDefault();
      this.handleSearchInputArrowKeyPress("ArrowUp", filteredOptions);
    },
    Tab: () => this.resetSearchOrClearValueAndClose(),
  };
  private searchInProgress = false;
  private readonly inputRef = React.createRef<Input>();

  private isHandleFocusTriggered: boolean;
  private queuedSearch: string | undefined;
  private listboxID: string;

  constructor(props: IDropdownInputProps<ValueType>) {
    super(props);
    this.listboxID = props.listboxID || Math.random().toString();

    this.state = {
      isOpen: false,
      searchTerm: this.getSearchTermFromProps(props),
      filterTerm: "",
      activeItemIndex:
        typeof props.options === "function"
          ? -1
          : this.getActiveIndexFromProps(props),
    };
  }

  public componentDidUpdate() {
    this.adaptToWindow();
  }

  public componentWillReceiveProps(nextProps: IDropdownInputProps<ValueType>) {
    if (
      nextProps.value !== undefined ||
      nextProps.value !== null ||
      nextProps.selected
    ) {
      const newState: {
        [key in keyof IDropdownInputState<ValueType>]?: IDropdownInputState<ValueType>[key];
      } = {
        activeItemIndex: this.getActiveIndexFromProps(nextProps),
      };

      const hasValueChanges = !this.valueComparer(
        this.getValueFromProps(nextProps),
        this.getValueFromProps(this.props)
      );
      const hasSearchTermChanges =
        this.getSearchTermFromProps(nextProps) !==
        this.getSearchTermFromProps(this.props);

      if (hasValueChanges || hasSearchTermChanges) {
        newState.searchTerm = this.getSearchTermFromProps(nextProps);
      }

      this.setState(newState as IDropdownInputState<ValueType>);
    }
  }

  public componentWillUnmount() {
    this.removeBlurListeners();
    window.removeEventListener("resize", this.adaptToWindow);
  }

  private clearSearchText = () => {
    this.setState({
      searchTerm: "",
    });
  };

  public render() {
    const inputProps = omit(this.props, [
      "options",
      "groupedOptions",
      "value",
      "selected",
      "valueChanged",
      "itemSelected",
      "optionSelected",
      "valueComparer",
      "open",
      "onSelectKeep",
      "className",
      "onFocus",
      "placeholder",
      "keepOpenOnSelect",
      "minSearchLength",
      "maxResults",
      "inputChanged",
      "allowsFreeText",
      "onOpen",
      "onClose",
      "searchInput",
      "optionsFilter",
      "truncateOptions",
      "notTruncateOptionsOnSmall",
      "listOpenDirection",
    ]);
    const { withSearchIcon, withRemoveSearchTextIcon } = this.props;
    const filteredOptions = this.getFilteredOptionsUpToMaxResults();
    const activeItem = filteredOptions[
      this.state.activeItemIndex
    ] as IOption<ValueType>;
    const placeholder = activeItem ? activeItem.name : this.props.placeholder;

    let isOpen = this.state.isOpen;

    if (typeof this.props.open !== "undefined") {
      isOpen = this.props.open;
    }

    if (!filteredOptions.length && !this.state.filterTerm) {
      isOpen = false;
    }

    const listboxID = this.props.listboxID || this.listboxID;
    const isSelectVariant = this.props.variant === "select";
    const isScrollable =
      (this.props.maxResults || isSelectVariant) &&
      filteredOptions.length > DEFAULT_MAX_RESULTS;

    const rootClassNames = cx(
      "c-dropdown-input",
      `c-dropdown-input--${this.props.size ?? InputSizes.Large}`,
      {
        [`c-dropdown-input--${this.props.variant}`]: this.props.variant,
        "c-dropdown-input--disabled": this.props.disabled,
        "c-dropdown-input--truncate-options": this.props.truncateOptions,
        "c-dropdown-input--not-truncate-options-sm":
          this.props.notTruncateOptionsOnSmall,
        "c-dropdown-input--with-search-input":
          this.props.searchInput && this.props.searchInput.show,
      },
      this.props.className
    );
    const listOpenDirection =
      this.props.listOpenDirection ?? ListOpenDirection.Down;
    const optionsContainerClassNames = cx(
      "c-dropdown-input__options-container",
      {
        [`c-dropdown-input__options-container--open-${listOpenDirection}`]:
          isOpen,
      }
    );

    const optionsListClassNames = cx("c-dropdown-input__options", {
      "c-dropdown-input__options--scrollable": isScrollable,
    });
    const inputClassNames = cx("c-dropdown-input__relative-container", {
      "c-dropdown-input--with-search-icon": withSearchIcon,
      "c-dropdown-input--with-remove-search-text-icon":
        withRemoveSearchTextIcon,
    });

    return (
      <div
        ref={this.ref}
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={this.state.isOpen}
        aria-owns={listboxID}
        className={rootClassNames}
      >
        <Input
          className={inputClassNames}
          type="text"
          ref={this.inputRef}
          placeholder={placeholder}
          value={this.state.searchTerm}
          valueChanged={this.search}
          onFocus={this.handleFocus}
          onClick={this.handleInputClick}
          onKeyDown={this.handleKeyDown(filteredOptions)}
          aria-autocomplete="list"
          aria-controls={listboxID}
          aria-activedescendant={`${listboxID}active`}
          aria-label={this.props.label}
          readOnly={isSelectVariant}
          {...inputProps}
        >
          {withSearchIcon && (
            <div
              className="c-input-search-icon icon-magnify"
              aria-label="Search"
            />
          )}

          <div
            ref={this.listContainerRef}
            tabIndex={-1}
            aria-hidden={!isOpen}
            className={optionsContainerClassNames}
          >
            {this.props.searchInput && this.props.searchInput.show && (
              <div className="c-dropdown-input__search">
                <Input
                  className="g-with-search-icon"
                  disabled={!isOpen}
                  type="text"
                  placeholder={this.props.searchInput.placeholder}
                  value={this.state.filterTerm}
                  onChange={this.handleSearchInputChange}
                  onKeyDown={this.handleSearchInputKeyDown}
                  whiteInput
                  data-testid={`${this.props["data-testid"]}-search`}
                >
                  <InputSearchIcon />
                </Input>
              </div>
            )}

            <ul
              ref={this.listRef}
              id={listboxID}
              role="listbox"
              className={optionsListClassNames}
            >
              {isOpen && this.renderOptions(filteredOptions, listboxID)}
            </ul>
          </div>
        </Input>
        {isSelectVariant && this.renderSelectArrows()}
        {withRemoveSearchTextIcon && (
          <div
            onClick={this.clearSearchText}
            className="c-cleaner icon-cross-circle"
            data-testid={
              dataTestIds.componentLibrary[
                "Molecules.DropdownInput.inputCleaner"
              ]
            }
          />
        )}
      </div>
    );
  }

  private renderOptions = (
    filteredOptions: Array<IOption<ValueType> | string>,
    listboxID: string
  ) => {
    const { wrappedOptions } = this.props;
    const activeIndex = this.state.activeItemIndex;

    return filteredOptions.map((option, index) => {
      const isSelected = index === activeIndex;
      const attrs: { [key: string]: any } = {};
      if (isSelected) {
        attrs["aria-selected"] = true;
        attrs["id"] = `${listboxID}active`;
      }

      if (typeof option === "string") {
        return (
          <li
            key={option + index}
            className="c-dropdown-input__section-label"
            tabIndex={-1}
          >
            {option}
          </li>
        );
      }

      const optionNameClass = cx("c-dropdown-input__entry__name", {
        "c-dropdown-input__entry__name--wrapped": wrappedOptions,
      });

      if (option.disabled) {
        return (
          <li
            key={option.name + index}
            className="c-dropdown-input__entry c-dropdown-input__entry--disabled"
            role="option"
            tabIndex={-1}
          >
            <div className={optionNameClass}>
              {this.renderFormattedName(option.name)}
            </div>
          </li>
        );
      }

      return (
        <li
          key={option.name + index}
          {...attrs}
          className={cx("c-dropdown-input__entry", { active: isSelected })}
          role="option"
          tabIndex={this.state.isOpen ? 0 : -1}
          onMouseUp={this.optionClicked(index)}
        >
          <div className={optionNameClass}>
            {this.renderFormattedName(option.name)}
          </div>
        </li>
      );
    });
  };

  private renderFormattedName = (name: string) => {
    if (this.props.variant === "autocomplete" && !this.props.optionsFilter) {
      const fromIndex = name
        .toLowerCase()
        .indexOf(this.state.searchTerm.toLowerCase());
      const toIndex = fromIndex + this.state.searchTerm.length;

      if (fromIndex > -1 && toIndex > fromIndex) {
        return (
          <>
            {name.substring(0, fromIndex)}
            <b>{name.substring(fromIndex, toIndex)}</b>
            {name.substring(toIndex, name.length)}
          </>
        );
      }
    }
    return name;
  };

  private renderSelectArrows = () => {
    const arrowsClassNames = cx("c-dropdown-input__arrows", {
      "c-dropdown-input__arrows--disabled": this.props.disabled,
    });

    return (
      <SelectArrows
        onClick={
          !this.props.disabled ? this.handleArrowsClick : () => undefined
        }
        className={arrowsClassNames}
      />
    );
  };

  /*event handlers*/
  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.isHandleFocusTriggered = true;

    this.open();
    if (this.props.variant === "select" && event.currentTarget) {
      event.currentTarget.selectionStart = event.currentTarget.selectionEnd;
    }
    return this.props.onFocus && this.props.onFocus(event);
  };

  private handleBlur = (event: FocusEvent | MouseEvent) => {
    if (this.ref.current && !this.ref.current.contains(event.target as Node)) {
      this.closeOnBlur();
    }
  };

  private handleInputClick = () => {
    if (this.isHandleFocusTriggered) {
      this.isHandleFocusTriggered = false;
      return;
    }

    if (!this.state.isOpen) {
      this.open();
    } else if (this.props.variant === "select") {
      this.close();
    }
  };

  private handleArrowsClick = () => {
    this.handleInputClick();
    this.focus();
    this.isHandleFocusTriggered = false;
  };

  private handleKeyDown =
    (filteredOptions: Array<IOption<ValueType> | string>) =>
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keydownHandler = this.keyDownHandlers[event.key];

      if (keydownHandler) {
        keydownHandler(event, filteredOptions);
      }
    };

  private handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      filterTerm: event.target.value,
      activeItemIndex: -1,
    });
  };

  private handleSearchInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const keydownHandler = this.searchInputKeydownHandlers[event.key];

    if (keydownHandler) {
      const filteredOptions = this.getFilteredOptionsUpToMaxResults();
      keydownHandler(event, filteredOptions);
    }
  };

  private handleSearchInputArrowKeyPress = (
    keyCode: "ArrowUp" | "ArrowDown",
    filteredOptions: Array<IOption<ValueType>>
  ) => {
    this.focus();
    this.arrowKeyHandler(keyCode, filteredOptions);
  };

  private arrowKeyHandler = (
    keyCode: "ArrowUp" | "ArrowDown",
    filteredOptions: Array<IOption<ValueType>>
  ) => {
    if (this.isCanOpen()) {
      this.open();
      let index = this.state.activeItemIndex || 0;
      const isSectionLabel = (option: IOption<ValueType> | string): boolean =>
        typeof option === "string";

      const moveBy =
        this.state.isOpen || this.state.activeItemIndex < 0 ? 1 : 0;

      switch (keyCode) {
        case "ArrowUp":
          index = index - moveBy;
          if (index <= -1) {
            index = filteredOptions.length - 1;
          }
          if (isSectionLabel(filteredOptions[index])) {
            index -= 1;
          }
          break;
        case "ArrowDown":
          index = (index + moveBy) % filteredOptions.length;
          if (isSectionLabel(filteredOptions[index])) {
            index += 1;
          }
      }

      this.setState({ activeItemIndex: index }, this.scrollResults);
    }
  };

  private tabHandler = () => {
    if (!this.props.searchInput || !this.props.searchInput.show) {
      this.closeOnBlur();
    }
  };

  private closeOnBlur() {
    if (this.props.allowsFreeText) {
      this.setState({ activeItemIndex: -1 }, this.close);
    } else {
      this.resetSearchOrClearValueAndClose();
    }
  }

  /*behavior*/
  private search = async (searchTerm: string) => {
    if (this.props.inputChanged) {
      this.props.inputChanged(searchTerm);
    }
    this.setState({ searchTerm }, this.openOrClose);

    if (!searchTerm.length) {
      this.clearValue();
    } else if (this.props.allowsFreeText) {
      const keepOpen = true;
      this.clearValue(keepOpen);
    }

    if (
      typeof this.props.options === "function" &&
      searchTerm.length >= this.getMinSearchLength()
    ) {
      if (!this.searchInProgress) {
        this.queuedSearch = searchTerm;
        while (this.queuedSearch) {
          const search = this.queuedSearch;
          this.queuedSearch = undefined;
          this.searchInProgress = true;

          let result: Array<IOption<ValueType>>;
          try {
            result = (await this.props.options(search)) as Array<
              IOption<ValueType>
            >;
          } catch (e) {
            result = [];
          }
          this.setState({
            loadedOptions: result,
          });
          this.searchInProgress = false;
        }
      } else {
        this.queuedSearch = searchTerm;
      }
    }
  };

  private openOrClose = () => (this.isCanOpen() ? this.open() : this.close());

  private isCanOpen = () =>
    this.props.variant === "select" ||
    this.state.searchTerm.length >= this.getMinSearchLength();

  private open = () => {
    if (!this.state.isOpen && this.isCanOpen()) {
      this.addBlurListeners();
      this.setState(
        {
          isOpen: true,
          activeItemIndex: this.getActiveIndexFromProps(this.props),
        },
        () => {
          this.scrollResults(true);
          window.addEventListener("resize", this.adaptToWindow);

          if (this.props.onOpen) {
            this.props.onOpen();
          }
        }
      );
    }
  };

  public close = () => {
    if (this.state.isOpen) {
      this.setState(
        { isOpen: false, filterTerm: "" },
        this.removeBlurListeners
      );
      window.removeEventListener("resize", this.adaptToWindow);

      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  public focus() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  public resetSearch() {
    this.setState({
      activeItemIndex: -1,
      searchTerm: "",
    });
  }

  private addBlurListeners = () => {
    document.addEventListener("focus", this.handleBlur, true);
    document.addEventListener("click", this.handleBlur);
  };

  private removeBlurListeners = () => {
    document.removeEventListener("focus", this.handleBlur, true);
    document.removeEventListener("click", this.handleBlur);
  };

  private optionClicked = (index: number) => (event: React.MouseEvent) => {
    this.removeBlurListeners();
    this.selectItem(index);
    this.addBlurListeners();
  };

  private resetSearchOrClearValueAndClose = () => {
    let searchTerm = this.state.searchTerm;
    const searchTermFromProps = this.getSearchTermFromProps(this.props);

    if (searchTermFromProps) {
      searchTerm = searchTermFromProps;
    } else {
      this.clearValue();
    }

    this.setState({ searchTerm, activeItemIndex: -1 }, this.close);
  };

  private scrollResults = (scrollToSelected: boolean = false): void => {
    if (
      (this.props.maxResults && this.props.maxResults > DEFAULT_MAX_RESULTS) ||
      this.props.variant === "select"
    ) {
      const list = this.listRef.current;
      const selectedElement =
        list && (list.getElementsByClassName("active")[0] as HTMLLIElement);

      if (!list || !selectedElement) {
        return;
      }

      const { offsetHeight, scrollTop } = list;
      const { offsetHeight: elementOffsetHeight, offsetTop: elementOffsetTop } =
        selectedElement;

      if (scrollToSelected) {
        list.scrollTop = elementOffsetTop;
        return;
      }

      const elementShouldBeAtTop =
        scrollTop > elementOffsetTop - elementOffsetHeight / 2;
      const elementShouldBeAtBottom =
        scrollTop + offsetHeight <= elementOffsetTop + elementOffsetHeight / 2;

      if (elementShouldBeAtTop) {
        list.scrollTop = elementOffsetTop;
      } else if (elementShouldBeAtBottom) {
        list.scrollTop = elementOffsetTop - offsetHeight + elementOffsetHeight;
      }
    }
  };

  private clearValue = (keepOpen?: boolean) => this.selectItem(-1, keepOpen);

  private selectItem = (index: number, keepOpen?: boolean) => {
    const item = this.getFilteredOptionsUpToMaxResults()[
      index
    ] as IOption<ValueType>;
    const props = this.props as IDropdownInputProps<ValueType>;

    if (!this.props.allowsFreeText) {
      this.setState({
        searchTerm: props.keepOpenOnSelect || !item ? "" : item.name,
      });
    }

    this.setState(
      {
        activeItemIndex: index,
      },
      () => {
        const itemSelected =
          props.optionSelected as IBaseDropdownInputProps<ValueType>["optionSelected"];
        const valueChanged =
          props.valueChanged as IBaseDropdownInputProps<ValueType>["valueChanged"];

        if (itemSelected) {
          itemSelected(item);
        }

        if (valueChanged) {
          valueChanged(item ? item.value : undefined);
        }

        if (item && this.props.inputChanged) {
          this.props.inputChanged(item.name);
        }

        if (!this.props.keepOpenOnSelect && !keepOpen) {
          this.close();
          if (this.inputRef.current && index > -1) {
            this.inputRef.current.blur();
          }
        }
      }
    );
  };

  private valueComparer = (v1?: ValueType, v2?: ValueType): boolean => {
    const props = this.props as IBaseDropdownInputWithComparerProps<ValueType>;
    if (props.valueComparer) {
      if (v1 !== undefined && v1 !== null && v2 !== undefined && v2 !== null) {
        return props.valueComparer(v1, v2);
      }
    }

    return v1 === v2;
  };

  private adaptToWindow = () => {
    if (
      this.state.isOpen &&
      this.listContainerRef.current &&
      this.ref.current
    ) {
      const list = this.listContainerRef.current;
      const input = this.ref.current;
      const listBoundingRect = list.getBoundingClientRect();
      const inputBoundingRect = input.getBoundingClientRect();
      const listLX = listBoundingRect.left;
      const listRX = listBoundingRect.right;
      const listWidth = listBoundingRect.width;
      const inputLX = inputBoundingRect.left;
      const inputRX = inputBoundingRect.right;
      const windowWidth = getWindowWidth();

      let newLeft: string | null = "0px";
      if (
        listRX > windowWidth - BORDER_WIDTH - SCROLL_WIDTH ||
        (listRX > inputRX &&
          listRX < windowWidth &&
          list.offsetWidth > windowWidth - inputLX)
      ) {
        const newRight = -windowWidth + inputRX + SCROLL_WIDTH;
        list.style.right = (newRight > 0 ? 0 : newRight) + "px";
      } else {
        list.style.right = "";
      }

      if (
        listLX < BORDER_WIDTH ||
        listWidth > windowWidth - BORDER_WIDTH - SCROLL_WIDTH
      ) {
        const left = -inputLX;
        newLeft = left + "px";
      } else {
        newLeft = "";
      }
      list.style.left = newLeft;
    }
  };

  private getSearchTermFromProps = (
    props: IDropdownInputProps<ValueType>
  ): string => {
    let searchTerm = "";

    if (props.selected) {
      searchTerm = props.selected.name;
    }

    if (props.value !== undefined) {
      const options =
        (typeof props.options === "function"
          ? this.state && this.state.loadedOptions
          : props.options) || [];
      const selectedItem = (options as Array<IOption<ValueType>>).find(
        (option) => this.valueComparer(option.value, props.value as ValueType)
      );
      if (selectedItem) {
        searchTerm = selectedItem.name;
      }
    }

    return searchTerm;
  };

  private getActiveIndexFromProps = (props: IDropdownInputProps<ValueType>) => {
    let options: Array<IOption<ValueType>>;
    if (typeof props.options === "function") {
      options = this.state.loadedOptions || [];
    } else if (this.props.groupedOptions) {
      const initialValue: Array<IOption<ValueType>> = [];
      const groupedOptions = this.props.groupedOptions as Array<
        IOptionGroup<ValueType>
      >;

      options = groupedOptions.reduce<typeof initialValue>((result, group) => {
        return [...result, ...[group.label, ...group.options]] as Array<
          IOption<ValueType>
        >;
      }, initialValue);
    } else {
      options = props.options as Array<IOption<ValueType>>;
    }

    const value = this.getValueFromProps(props);
    if (value !== undefined && value !== null) {
      return options.findIndex(
        (entry) =>
          typeof entry !== "string" && this.valueComparer(value, entry.value)
      );
    }

    return -1;
  };

  private getValueFromProps = (
    props: IDropdownInputProps<ValueType>
  ): ValueType | undefined => {
    if (props.selected) {
      return props.selected.value as ValueType;
    }

    return props.value as ValueType | undefined;
  };

  private getFilteredOptionsUpToMaxResults = () => {
    const filteredOptions = this.props.groupedOptions
      ? this.getFilteredGroupedOptions()
      : this.getFilteredOptions();
    const maxResults =
      this.props.variant === "autocomplete"
        ? this.props.maxResults || DEFAULT_MAX_RESULTS
        : filteredOptions.length;

    return filteredOptions.slice(0, maxResults);
  };

  private getFilteredOptions = (): Array<IOption<ValueType>> => {
    if (typeof this.props.options === "function") {
      return this.state.loadedOptions || [];
    } else {
      return this.filterOptionsByTerm(
        this.props.options as Array<IOption<ValueType>>,
        this.props.variant === "autocomplete"
          ? this.state.searchTerm
          : this.state.filterTerm
      );
    }
  };

  private filterOptionsByTerm = (
    options: Array<IOption<ValueType>>,
    term: string
  ): Array<IOption<ValueType>> => {
    const customOptionsFilter = this.props
      .optionsFilter as IBaseDropdownInputProps<ValueType>["optionsFilter"];
    return options.filter((option) =>
      customOptionsFilter
        ? customOptionsFilter(option, term)
        : option.name.toLowerCase().includes(term.toLowerCase())
    );
  };

  private getFilteredGroupedOptions() {
    const initialValue: Array<IOption<ValueType> | string> = [];
    const options = this.props.groupedOptions as Array<IOptionGroup<ValueType>>;

    return options.reduce<typeof initialValue>((result, group) => {
      const filteredOptions = this.filterOptionsByTerm(
        group.options,
        this.state.filterTerm
      );

      return [
        ...result,
        ...(filteredOptions.length ? [group.label, ...filteredOptions] : []),
      ];
    }, initialValue);
  }

  private getMinSearchLength = () => {
    return this.props.variant === "select"
      ? 0
      : typeof this.props.minSearchLength !== "undefined"
      ? this.props.minSearchLength
      : this.minSearchLength;
  };
}
