import React, { ReactNode } from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import { ActionButton } from "../../Atoms/ActionButton/ActionButton";

import "./RecordWithActionControls.scss";

export interface IRecordWithActionControlsProps {
  /** Title of item */
  title?: ReactNode;
  /** ActionButton component or any JSX.Element to display opposite the title */
  controls?: ActionButton[] | JSX.Element[];
  /** Optional css class */
  className?: string;
  /** Optional children to display the section below the title and controls */
  children?: React.ReactNode;
}

export const RecordWithActionControls: React.FC<
  IRecordWithActionControlsProps
> = (props) => {
  const { title, controls, className, children } = props;
  return (
    <div className={cx("c-record-with-action-controls", className)}>
      <div
        data-testid={
          dataTestIds.componentLibrary[
            "Molecules.RecordWithActionControls.uploadRecord"
          ]
        }
        className="c-record-with-action-controls__header"
      >
        <div className="c-record-with-action-controls__title">{title}</div>
        {controls?.length ? (
          <div className="c-record-with-action-controls__controls">
            {controls}
          </div>
        ) : null}
      </div>
      <div className="c-record-with-action-controls__content">{children}</div>
    </div>
  );
};
