import React, { ReactElement } from "react";
import cx from "classnames";

import { SlideDown } from "../../Functional/SlideDown/SlideDown";
import { ReactComponent as WarningIcon } from "component-library/src/images/svg/icons/warning-icon-base.svg";

import "./ExpandableRow.scss";

export interface IExpandableRowControl {
  component?: ReactElement;
  /**
   * Define action handler for control. Will not be executed if control has `isInteractionDisabled` flag set.
   */
  handler?: () => void;
  /**
   * Define which of the icons to use (check the available list)
   */
  icon?: string;
  /**
   * Flag to disable control
   */
  disabled?: boolean;
  /**
   * Optional text to be displayed near control icon
   */
  text?: string;
  /**
   * Optional flag to disable any interactions for control
   */
  isInteractionDisabled?: boolean;
  /**
   * Optional aria-label on the control
   */
  ariaLabel?: string;
}

interface IUIFlagProps {
  isVisible: boolean;
  text: string;
}

export interface IExpandableRowProps {
  /**
   * Text within container header.
   */
  header: string | React.ReactNode;
  /**
   * Actions for row controls
   */
  controls?: IExpandableRowControl[];
  /**
   * Initial row state
   */
  isInitiallyExpanded?: boolean;
  /**
   * Flag to set header text in uppercase
   */
  isHeaderUppercased?: boolean;
  /**
   * Optional parameter to specify header text size. Normal is default
   */
  headerTextSize?: "normal" | "large";
  /**
   * Optional parameter to specify header text weight. Semibold is default
   */
  headerTextWeight?: "regular" | "semibold";
  /**
   * Callback function that fires for each expanding/collapsing with current expanded status as an argument
   */
  onToggleExpand?: (isExpanded: boolean) => void;
  /**
   * Flag for disable vertical css transition
   */
  isTransitionDisabled?: boolean;
  /**
   * Flag for select if content should stay on in the dom tree when the element is collapsed.
   */
  isCollapsedContentRemoved?: boolean;
  /**
   * Optionally set an aria-label on the expandable icon
   */
  toggleAriaLabel?: string;
  uiFlag?: IUIFlagProps;
  "data-testid"?: string;
}

interface IExpandableRowState {
  expanded: boolean;
}

export class ExpandableRow extends React.Component<
  IExpandableRowProps,
  IExpandableRowState
> {
  constructor(props: IExpandableRowProps) {
    super(props);

    this.state = {
      expanded: Boolean(this.props.isInitiallyExpanded),
    };
  }

  public render() {
    const { expanded } = this.state;
    const {
      controls,
      isHeaderUppercased,
      header,
      children,
      headerTextSize = "normal",
      headerTextWeight = "semibold",
      isTransitionDisabled,
      isCollapsedContentRemoved,
      toggleAriaLabel,
      uiFlag,
    } = this.props;
    const rootClassNames = cx("c-expandable-row", {
      expanded,
      "header-uppercase": isHeaderUppercased,
    });
    const headerClassNames = cx(
      "c-expandable-row__header",
      `c-expandable-row__header--${headerTextSize}`,
      `c-expandable-row__header--${headerTextWeight}`,
      {
        "c-expandable-row__header--ui-flag": uiFlag?.isVisible,
      }
    );

    const headerContentClassNames = cx({
      "c-expandable-row__header-content-wrapper": uiFlag?.isVisible,
      "c-expandable-row__header-content-wrapper--normal": !uiFlag?.isVisible,
    });

    const expandableContainerWrapperClassNames = cx(
      "c-breakdown-expandable-container-wrapper",
      {
        "c-breakdown-expandable-container-wrapper__margin":
          uiFlag?.isVisible && (controls || []).length > 0,
      }
    );

    const content = !expanded && isCollapsedContentRemoved ? null : children;

    return (
      <div className={rootClassNames} aria-expanded={expanded}>
        <div
          className={headerClassNames}
          onClick={this.handleOnClick(this.toggleExpand)}
          data-testid={this.props["data-testid"]}
        >
          {uiFlag?.isVisible && (
            <div className={expandableContainerWrapperClassNames}>
              <div className="c-breakdown-expandable-ui-flag">
                <WarningIcon
                  height={20}
                  width={20}
                  className="font-color-neutral-one"
                />
                <span className="c-breakdown-expandable-ui-flag__title">
                  {uiFlag?.isVisible && uiFlag.text}
                </span>
              </div>
            </div>
          )}
          <div className={headerContentClassNames}>
            <div className="c-expandable-row__header-content">{header}</div>
            <div className="c-expandable-row__controls-wrapper">
              {controls && (
                <div className="c-expandable-row__header-controls">
                  {this.renderControls(controls)}
                </div>
              )}
              <div
                tabIndex={0}
                className="c-expandable-row__header-expander icon-chevrondown"
                onClick={this.handleOnClick(this.toggleExpand)}
                onKeyPress={this.handleKeyPress(this.toggleExpand)}
                aria-label={
                  toggleAriaLabel &&
                  `${expanded ? "Close" : "Expand"} ${toggleAriaLabel}`
                }
                role="button"
              />
            </div>
          </div>
        </div>
        <div className="c-expandable-row__container">
          {isTransitionDisabled ? (
            <div
              className={cx("c-expandable-row__content", {
                "c-expandable-row__content--expanded": expanded,
              })}
            >
              {content}
            </div>
          ) : (
            <SlideDown
              className="c-expandable-row__slide-down"
              closed={!expanded}
              transitionOnAppear={false}
            >
              {content}
            </SlideDown>
          )}
        </div>
      </div>
    );
  }

  public expand = () => this.setState({ expanded: true });

  public collapse = () => this.setState({ expanded: false });

  public isExpanded = () => this.state.expanded;

  private renderControls = (controls: IExpandableRowControl[]) =>
    controls.map((control, index) => {
      const {
        handler,
        icon,
        text,
        disabled,
        isInteractionDisabled,
        ariaLabel,
        component,
      } = control;

      if (component) return component;

      const controlClassNames = cx("c-expandable-row__header-control", {
        disabled,
        "no-interaction": isInteractionDisabled,
      });
      const iconClassNames = cx(
        "c-expandable-row__control-icon",
        `icon-${icon}`
      );

      const isHandlerDisabled = disabled || isInteractionDisabled;
      const controlTabIndex = isHandlerDisabled ? -1 : 0;

      const clickHandler = this.handleOnClick(handler, isHandlerDisabled);
      const keyPressHandler = this.handleKeyPress(handler, isHandlerDisabled);

      return (
        <div
          key={index}
          tabIndex={controlTabIndex}
          className={controlClassNames}
          onClick={clickHandler}
          onKeyPress={keyPressHandler}
          role="button"
          aria-label={ariaLabel}
        >
          <div className={iconClassNames} />
          {text && <div className="c-expandable-row__control-text">{text}</div>}
        </div>
      );
    });

  private toggleExpand = () =>
    this.setState({ expanded: !this.state.expanded }, () => {
      this.props.onToggleExpand?.(this.state.expanded);
    });

  private handleOnClick =
    (action?: () => void, disabled: boolean = false) =>
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        action?.();
      }

      (event.target as any).blur();
      event.stopPropagation();
    };

  private handleKeyPress =
    (action?: () => void, disabled: boolean = false) =>
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (["Enter", " "].includes(event.key) && !disabled) {
        event.preventDefault();
        action?.();
      }
    };
}
