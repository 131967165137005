import React from "react";
import diff from "json-patch-gen";
import {
  Popup,
  IAlertMessageProps,
  AlertMessage,
  Checkbox,
} from "component-library";
import { getCookie, setCookie, ExpiryTimeUnits } from "shared-utils";

import {
  IPhotoEditorContent,
  PhotoEditor,
  IPhotoEditorProps,
} from "components/flows/Common/PhotoEditor";
import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "components/flows/PopupFlowBaseComponent";
import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { PhotoToolState } from "shared/modules/Common";

import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo, ImageLocation } from "models";
import {
  IPhotoEditingFlowContent,
  getSaveDialogSettings,
  getCancelDialogSettings,
  getErrorDialogSettings,
} from "./PhotoEditingFlowContent";
import { getImageUrl } from "services/UrlBuilder";
import { AdminPhotoEdit } from "flows/Common/AdminPhotoEdit";
import { UserRoleManager } from "core";
import { InvalidOperationError } from "shared/utils/exceptions";
import compose from "shared/utils/compose";
import { captureExceptionEvent } from "shared-services";
import { authorizationService } from "shared-auth";

const STEP_NAME = {
  photoEditor: "photoEditor",
  cancel: "cancel",
  save: "save",
  error: "error",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
  extraLarge: { lg: 12, md: 12 },
};

const SKIP_SAVE_ALERT_COOKIE_NAME = "skipSaveAlert";
const SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS = 30;

export interface IPhotoEditingFlowProps
  extends IFlowContextProps,
    IGlobalContextProps {
  photo: Photo;
  content: IPhotoEditingFlowContent;
}

interface IPhotoEditingFlowState extends IPopupFlowBaseComponentState {
  photo?: Photo;
  skipSaveAlert: boolean;
  imageType: string;
  error?: string;
  showContent: boolean;
}

export class PhotoEditingFlow extends PopupFlowBaseComponent<
  IPhotoEditingFlowProps,
  IPhotoEditingFlowState
> {
  public readonly state: Readonly<IPhotoEditingFlowState> = {
    currentStep: STEP_NAME.photoEditor,
    skipSaveAlert: false,
    imageType: "",
    showContent: true,
  };

  private photoEditorState?: PhotoToolState;

  private photoEditorRef = React.createRef<PhotoEditor>();
  private adminPhotoEditorRef = React.createRef<AdminPhotoEdit>();

  private errorMessage: string;
  private isAvailableClose = false;
  private isProcessStarted = false;
  private updatedPhotoData?: Photo;
  private photoPromise: ICancelablePromise<[Photo, string]>;

  constructor(props: IPhotoEditingFlowProps) {
    super(props);

    this.makeFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    const { showContent } = this.state;

    let view: React.ReactNode = null;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public componentWillUnmount() {
    this.photoPromise && this.photoPromise.cancel();
    //@ts-ignore
    delete this.photoEditorState;
  }

  public async componentDidMount() {
    const { content } = this.props;

    this.photoPromise = makeCancelable(this.uploadPhoto());
    try {
      const [photo, imageType] = await this.photoPromise.promise;

      this.setState({ photo, imageType });
    } catch (error) {
      if (!error.isCanceled) {
        this.setState({ error: content.photoLoadingError });
      }
    }
  }

  private uploadPhoto = async (): Promise<[Photo, string]> => {
    const { photo } = this.props;

    const blob = await this.getPhotoBlob(photo);

    const url = URL.createObjectURL(blob);

    return [{ ...photo, url }, blob.type];
  };

  private getPhotoBlob = async (photo: Photo) => {
    const photoUrl = getImageUrl(photo.url, ImageLocation.PhotoEditor);

    const reader = await fetch(photoUrl);

    if (!reader.ok) {
      throw new InvalidOperationError("Photo cant be retrieved by url", {
        reader,
      });
    }

    return await reader.blob();
  };

  private makeFlowSteps = () => {
    const { content } = this.props;

    const photoEditorContent: IPhotoEditorContent =
      this.props.content.editPhoto;

    const photoEditorSize = UserRoleManager.isInRole("Spotlight")
      ? POPUP_SIZES.extraLarge
      : POPUP_SIZES.large;
    this.flowSteps = [
      {
        name: STEP_NAME.photoEditor,
        component: this.renderPhotoTool,
        data: {},
        popupSettings: this.createPopupConfiguration(
          photoEditorSize,
          content.closePopup,
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          content: photoEditorContent,
          errorNotification: this.showPopup,
          savePhoto: this.preSavePhoto,
          cancelEditing: this.cancelPhotoEditing,
          showHelpPopup: this.showPopup,
        },
      },
      {
        name: STEP_NAME.cancel,
        component: this.renderCancelDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          this.closeFlow
        ),
        settings: getCancelDialogSettings({
          content: content.cancelDialog,
          close: this.forceClosePopup,
          editPhoto: this.backToPhotoEditing,
          save: this.savePhoto,
        }),
      },
      {
        name: STEP_NAME.save,
        component: this.renderSaveDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          this.closeFlow
        ),
        settings: getSaveDialogSettings({
          content: content.saveDialog,
          save: this.savePhoto,
          editPhoto: this.backToPhotoEditing,
          close: this.forceClosePopup,
        }),
      },
      {
        name: STEP_NAME.error,
        component: this.renderErrorDialog,
        data: {},
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          content.closePopup,
          this.closeFlow
        ),
        settings: getErrorDialogSettings({
          content: content.errorDialog,
          editPhoto: this.backToPhotoEditing,
        }),
      },
    ];
  };

  private renderPhotoTool = (photoEditProps: IPhotoEditorProps) => {
    if (UserRoleManager.isInRole("Spotlight")) {
      const { photographer = "", description = "" } =
        this.updatedPhotoData || this.state.photo || {};
      return this.renderAdminPhotoEdit(photoEditProps, {
        photographer,
        description,
      });
    } else {
      return this.renderPhotoEditor(photoEditProps);
    }
  };

  private renderPhotoEditor = (photoEditProps: IPhotoEditorProps) => {
    const { photo, imageType, error } = this.state;

    return (
      <PhotoEditor
        ref={this.photoEditorRef}
        {...photoEditProps}
        error={error}
        photo={photo}
        imageType={imageType}
        photoEditorData={this.photoEditorState}
      />
    );
  };

  private renderAdminPhotoEdit = (
    photoEditProps: IPhotoEditorProps,
    photoMetadata: any
  ) => (
    <AdminPhotoEdit
      ref={this.adminPhotoEditorRef}
      {...photoEditProps}
      error={this.state.error}
      photo={{ ...this.state.photo, ...photoMetadata }}
      imageType={this.state.imageType}
      photoEditorData={this.photoEditorState}
    />
  );

  private renderSaveDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps}>
      <Checkbox
        htmlId="photoEditingFlowDontShowAgain"
        name="dontShowAgain"
        value="dont"
        valueChanged={this.setSkipSaveAlertState}
        label={this.props.content.saveDialog.dontShowAgain}
        ariaLabel={this.props.content.saveDialog.dontShowAgain}
      />
    </AlertMessage>
  );

  private renderCancelDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  private renderErrorDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage
      {...alertProps}
      {...{
        texts: {
          ...alertProps.texts,
          description: this.errorMessage,
        },
      }}
    />
  );

  private closeFlow = () => {
    const { photo, error } = this.state;

    if (!this.isAvailableClose && !Boolean(error)) {
      this.isAvailableClose = true;

      let photoEditorState: PhotoToolState | undefined;
      let updatedPhotoData: Photo | undefined = photo;

      const adminPhotoEditorContainer = this.adminPhotoEditorRef.current;
      const photoEditorContainer = this.photoEditorRef.current;

      try {
        if (photoEditorContainer) {
          photoEditorState = photoEditorContainer.getPhotoEditorState();
        } else if (adminPhotoEditorContainer) {
          photoEditorState = adminPhotoEditorContainer.getPhotoEditorState();

          if (photo) {
            updatedPhotoData = {
              ...photo,
              ...adminPhotoEditorContainer.getPhotoCreditsData(),
            };
          }
        }
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }

      let hasChanges = false;

      if (photoEditorState) {
        const { history } = photoEditorState;

        hasChanges = history.isEmpty;

        if (history.isEmpty) {
          this.photoEditorState = photoEditorState;
        }
      }

      if (photo && updatedPhotoData) {
        const photoDiff = diff(photo, updatedPhotoData);

        if (photoDiff.length > 0) {
          this.updatedPhotoData = updatedPhotoData;
          hasChanges = true;
        }
      }

      if (hasChanges) {
        this.moveToStep(STEP_NAME.cancel);
      } else {
        this.props.flowContext.changeContext("", null);
      }
    } else {
      this.props.flowContext.changeContext("", null);
    }
  };

  public preSavePhoto = (data: PhotoToolState, photo: Photo) => {
    this.photoEditorState = data;
    this.updatedPhotoData = photo;

    if (getCookie(SKIP_SAVE_ALERT_COOKIE_NAME)) {
      this.savePhoto();
    } else {
      this.isAvailableClose = true;

      this.moveToStep(STEP_NAME.save);
    }
  };

  private savePhoto = async () => {
    const { globalContext } = this.props;

    if (!this.isProcessStarted) {
      this.isProcessStarted = true;
      this.isAvailableClose = true;
      this.setState({ showContent: false });

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      const promises: Promise<any>[] = [];

      const { photo } = this.state;

      if (this.updatedPhotoData && photo) {
        const difference = diff(photo, this.updatedPhotoData);

        if (difference.length > 0) {
          promises.push(PhotoService.updatePhotoMetadata(difference, photo));
        }
      }

      const { history = { isEmpty: true }, builtImage } =
        this.photoEditorState || {};

      if (!history.isEmpty && builtImage && photo) {
        promises.push(PhotoService.savePhoto({ ...photo, ...builtImage }));
      }

      try {
        await Promise.all([...promises]);

        globalContext.notifyListener(GlobalEventTypes.updateMainPhoto, {
          showDialog: false,
        });
        globalContext.notifyListener(GlobalEventTypes.updateGallery);
        this.forceClosePopup();
      } catch (error) {
        this.setState({ showContent: true });
        this.moveToStep(STEP_NAME.error);
      }
    }

    if (this.state.skipSaveAlert) {
      setCookie(SKIP_SAVE_ALERT_COOKIE_NAME, "true", {
        expiryTime: SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS,
        expiryTimeUnit: ExpiryTimeUnits.DAY,
      });
    }

    this.props.globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );
    this.isProcessStarted = true;
  };

  private cancelPhotoEditing = (
    photoToolState?: PhotoToolState,
    photo?: Photo
  ) => {
    if (photoToolState || photo) {
      this.photoEditorState = photoToolState;
      this.isAvailableClose = true;

      if (photo) {
        this.updatedPhotoData = photo;
      }

      const { history = { isEmpty: true } } = photoToolState || {};

      if (!history.isEmpty || photo) {
        this.moveToStep(STEP_NAME.cancel);
      } else {
        this.closeFlow();
      }
    } else {
      this.forceClosePopup();
    }
  };

  private backToPhotoEditing = () => {
    this.isAvailableClose = false;

    this.moveToStep(STEP_NAME.photoEditor);
  };

  private forceClosePopup = () => {
    this.isAvailableClose = true;

    this.closeFlow();
  };

  private setSkipSaveAlertState = (isChecked: boolean) =>
    this.setState({ skipSaveAlert: isChecked });

  private showPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
}

export default compose(withGlobalContext, withFlowContext)(PhotoEditingFlow);
