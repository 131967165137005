import React, { FC, ReactNode, ChangeEvent, KeyboardEvent } from "react";
import { dataTestIds } from "data-testids";
import cx from "classnames";
import "./styles.scss";

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  checked?: boolean;
  value?: any;
  testId?: string;
  label?: ReactNode;
  hint?: ReactNode;
  labelBold?: boolean;
}

const ToggleSwitch: FC<Props> = ({
  onKeyDown,
  checked,
  value,
  testId,
  name,
  inputRef,
  onChange,
  className,
  label,
  hint,
  labelBold,
}) => {
  return (
    <div className={cx("switch", className)}>
      <div className="slider-wrapper">
        <input
          ref={inputRef}
          name={name}
          type="checkbox"
          value={value}
          checked={checked}
          data-testid={
            testId ||
            dataTestIds.componentLibrary["Atoms.ToggleSwitch.ToggleButton"]
          }
          onChange={(e) => onChange(e)}
          onKeyDown={onKeyDown}
        />
        <div className="slider" />
      </div>
      <div
        className={cx("label-wrapper", {
          "with-hint": hint,
          "label-bold": labelBold,
        })}
      >
        <label htmlFor={name}>{label}</label>
        {hint && <p className="hint">{hint}</p>}
      </div>
    </div>
  );
};

export default ToggleSwitch;
