export const myAccountLink = "/myhome/";

export function getHeaderLabels() {
  return {
    signIn: "common:nav.signin",
    signOut: "common:nav.signout",
    myAccount: "common:nav.links.home",
    logoLabel: "common:nav.spotlightLogoAlt",
    flagLabel: "common:nav.changeLanguage",
    burger: {
      labelOpen: "common:nav.openHamburgerMenu",
      labelClose: "common:nav.closeHamburgerMenu",
      name: "common:nav.menu",
    },
    myAccountLink,
  };
}
