import React from "react";
import { dataTestIds } from "data-testids";

import { ReactComponent as WarningIcon } from "../../../images/svg/icons/warning-icon.svg";
import { ReactComponent as SuccessIcon } from "../../../images/svg/icons/success-icon.svg";

import "./InlineMessage.scss";

export interface IInlineMessageProps {
  type?: "error" | "success";
  title: string;
  text?: string;
}

export const InlineMessage: React.FC<IInlineMessageProps> = ({
  type = "success",
  title,
  text,
}) => {
  let icon: React.ReactNode = null;

  if (type === "error") {
    icon = (
      <WarningIcon
        className="InlineMessage__icon"
        data-testid={
          dataTestIds.componentLibrary["Atoms.InlineMessage.warningIcon"]
        }
      />
    );
  }

  if (type === "success") {
    icon = (
      <SuccessIcon
        color="#50E3C2" // TODO: How can we use scss or tw here
        className="InlineMessage__icon"
        data-testid={
          dataTestIds.componentLibrary["Atoms.InlineMessage.successIcon"]
        }
      />
    );
  }

  return (
    <section className={`InlineMessage InlineMessage--${type}`}>
      <header className="InlineMessage__header">
        {icon}
        <h2 className="InlineMessage__title">{title}</h2>
      </header>

      <main className="InlineMessage__text">{text}</main>
    </section>
  );
};
