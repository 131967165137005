import React, { FC, useContext } from "react";
import { useTranslation } from "i18n";
import PriceInfoBlock from "../PriceInfoBlock";
import { addOnNamesMap } from "../../../constants";
import { IAddOns } from "../../../types";
import { BillingContext } from "../../../BillingContext";

type PriceInfoPropsType = {
  addOns: IAddOns[];
  planName: string;
  currencySymbol?: string;
  placeholderSubscription: any;
  isAnnual: boolean;
  triggerRefetch?: () => {};
  showButton?: boolean;
  title?: string;
};

const PriceInfo: FC<PriceInfoPropsType> = ({
  addOns,
  planName,
  currencySymbol,
  placeholderSubscription,
  isAnnual,
  triggerRefetch,
  showButton,
  title,
}) => {
  const { toggleAddOnSelected } = useContext(BillingContext);

  const { t } = useTranslation();

  const editAddOnsSelection = (addOn: IAddOns) => {
    toggleAddOnSelected(addOn);

    triggerRefetch?.();
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <PriceInfoBlock
        name={planName}
        currencySymbol={currencySymbol}
        value={placeholderSubscription?.totalValue || 0}
        title={
          title?.toUpperCase() ??
          t("common:billing.checkout.infoBox.subscription").toUpperCase()
        }
        vatPrice={t("common:billing.vat", {
          price: placeholderSubscription?.amount,
        })}
        paymentPeriod={t(
          isAnnual
            ? "common:billing.planSelector.addOns.price.annual"
            : "common:billing.planSelector.addOns.price.monthly"
        )}
        variant="primary"
      />
      {addOns.length > 0 &&
        addOns.map((addOn) => {
          return (
            <PriceInfoBlock
              currencySymbol={currencySymbol}
              value={addOn.totalValue}
              title={t(addOnNamesMap[addOn.code]).toUpperCase()}
              vatPrice={t("common:billing.vat", {
                price: addOn.amount,
              })}
              paymentPeriod={t(
                isAnnual
                  ? "common:billing.planSelector.addOns.price.annual"
                  : "common:billing.planSelector.addOns.price.monthly"
              )}
              variant="secondary"
              key={addOn.code}
              icon={showButton ? "delete" : undefined}
              onIconClick={
                showButton ? () => editAddOnsSelection(addOn) : undefined
              }
            />
          );
        })}
    </div>
  );
};

export default PriceInfo;
