import React from "react";
import { TFunction } from "i18n";
import { ReactComponent as MediaPlayIcon } from "../../../../../images/svg/icons/media-play-skinny.svg";
import { Pill } from "../../../../Atoms/Pill/Pill";
import { IModifiers } from "./getNavHeaderLinks";

export function getCDNav(modifiers: IModifiers) {
  const { isSelfTapesEnabled } = modifiers;

  return {
    mainNavigationItems: [
      {
        text: "common:nav.links.industryResources",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl:
          "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
      },
      {
        text: "common:nav.links.contactsListings",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1163/contacts.svg",
        linkUrl: "/contacts/",
      },
      {
        text: "common:nav.links.draftBreakdowns",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1176/star.svg",
        linkUrl: "/casting/active-breakdowns",
      },
      {
        text: "common:nav.links.projectFolders",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1165/folder.svg",
        linkUrl: "/casting/projects",
      },
      ...(isSelfTapesEnabled
        ? [
            {
              text: "",
              RenderLabel: (t: TFunction) => (
                <span className="text-s">
                  {t("common:nav.links.mediaRequests")}{" "}
                  <Pill
                    className="bg-blue-sky-2 ml-1.5"
                    theme="light"
                    size="md"
                  >
                    {t("common:nav.links.pill.beta")}
                  </Pill>
                </span>
              ),
              svgIconStart: <MediaPlayIcon />,
              linkUrl: "/casting/self-tape-requests",
            },
          ]
        : []),
      {
        text: "common:nav.links.createBreakdown",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1169/pencil.svg",
        linkUrl: "/casting/create-breakdown",
      },
      {
        text: "common:nav.links.followUpMessages",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1167/messages.svg",
        linkUrl: "/casting/follow-up-messages",
      },
      {
        text: "common:nav.links.agentsList",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1174/twoclients.svg",
        linkUrl: "/casting/agent-lists",
      },
      {
        text: "common:nav.links.performerSearch",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1166/magnify.svg",
        linkUrl: "/casting/performers",
      },
      {
        text: "common:nav.links.shortlists",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1171/shortlist.svg",
        linkUrl: "/casting/shortlists",
      },
      { text: "", linkUrl: "", divider: true },
      {
        text: "common:nav.links.contactDetails",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1173/sun.svg",
        linkUrl: "https://portal.spotlight.com/portal/Account/Details",
      },
      {
        text: "common:nav.links.performanceCalendar",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1170/performancecal.svg",
        linkUrl: "https://calendar.spotlight.com/",
      },
      {
        text: "common:nav.links.helpTutorials",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1164/lhnhelp.svg",
        linkUrl:
          "https://www.spotlight.com/the-small-print/help-for-casting-users-of-the-spotlight-link/",
      },
    ],
  };
}
