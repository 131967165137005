import React from "react";
import {
  Popup,
  IAlertMessageProps,
  AlertMessage,
  Checkbox,
} from "component-library";
import { getCookie, setCookie, ExpiryTimeUnits } from "shared-utils";

import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";
import {
  IEditCreditsFormProps,
  EditCreditsForm,
} from "flows/UploadingPhotoFlow/EditCreditsForm";
import {
  IPopupFlowBaseComponentState,
  PopupFlowBaseComponent,
} from "flows/PopupFlowBaseComponent";
import {
  PhotoEditor,
  IPhotoEditorContent,
  IPhotoEditorProps,
} from "components/flows/Common/PhotoEditor";

import PhotoService from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { PhotoToolState } from "shared/modules/Common";

import {
  IUploadingPhotoFlowContent,
  getError,
  getSaveDialogSettings,
  getCancelDialogSettings,
  getErrorDialogSettings,
} from "./UploadingPhotoFlowContent";
import { ICancelablePromise, makeCancelable } from "shared/utils/promise";
import { Photo, PhotoEdit } from "models";
import { FlowStep } from "flows/PopupFlowBaseComponent/FlowStep";
import { InvalidOperationError } from "shared/utils/exceptions";
import compose from "shared/utils/compose";
import { captureExceptionEvent } from "shared-services";
import { authorizationService } from "shared-auth";

const SKIP_SAVE_ALERT_COOKIE_NAME = "skipSaveAlert";
const SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS = 30;

const STEP_NAME = {
  editPhoto: "edit-photo",
  editCredits: "edit-credits",
  error: "error",
  confirmSave: "confirm-save",
  cancel: "cancel",
};

const POPUP_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export interface IUploadingPhotoFlowProps
  extends IFlowContextProps,
    IGlobalContextProps {
  photo: Photo;
  content: IUploadingPhotoFlowContent;
}

interface IUploadingPhotoFlowState extends IPopupFlowBaseComponentState {
  imageType?: string;
  photo?: Photo;
  skipSaveAlert?: boolean;
  showContent: boolean;
}

export class UploadingPhotoFlow extends PopupFlowBaseComponent<
  IUploadingPhotoFlowProps,
  IUploadingPhotoFlowState
> {
  public readonly state: Readonly<IUploadingPhotoFlowState> = {
    currentStep: STEP_NAME.editPhoto,
    showContent: true,
  };

  private photoEditorState: PhotoToolState;
  private photoEditorRef: React.RefObject<PhotoEditor> =
    React.createRef<PhotoEditor>();

  private photoPromise: ICancelablePromise;

  private errorMessage = "";

  private isAvailableClose = false;
  private isProcessStarted = false;

  constructor(props: Readonly<IUploadingPhotoFlowProps>) {
    super(props);

    this.flowSteps = this.getFlowSteps();
  }

  public render() {
    const component = this.getCurrentStep();
    const popupConfiguration = this.getCurrentPopupConfiguration();
    const { showContent } = this.state;

    let view: React.ReactNode = null;

    if (popupConfiguration && component && showContent) {
      view = <Popup {...popupConfiguration}>{component}</Popup>;
    }

    return view;
  }

  public componentWillUnmount() {
    this.photoPromise && this.photoPromise.cancel();
    //@ts-ignore
    delete this.photoEditorState;
  }

  public async componentDidMount() {
    this.photoPromise = makeCancelable(this.uploadPhoto());

    try {
      const [photo, imageType] = await this.photoPromise.promise;

      this.setState({ photo, imageType });
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }
  }

  private uploadPhoto = async (): Promise<[Photo, string]> => {
    const { photo } = this.props;

    const blob = await this.getPhotoBlob(photo);

    const url = URL.createObjectURL(blob);

    return [{ ...photo, url }, blob.type];
  };

  private getPhotoBlob = async (photo: Photo) => {
    const reader = await fetch(photo.url);

    if (!reader.ok) {
      throw new InvalidOperationError("Photo cant be retrieved by url", {
        reader,
      });
    }

    const blob = await reader.blob();

    return blob;
  };

  private getFlowSteps = (): FlowStep[] => {
    const {
      content: { photoEditFlow: photoEditFlowContent, ...content },
    } = this.props;

    const photoEditorTexts: IPhotoEditorContent =
      photoEditFlowContent.editPhoto;

    return [
      {
        name: STEP_NAME.editPhoto,
        component: this.renderPhotoEditor,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          { closePopup: photoEditFlowContent.closePopup },
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          savePhoto: this.preSavePhoto,
          cancelEditing: this.cancelPhotoEditing,
          content: photoEditorTexts,
          errorNotification: this.showPopup,
          showHelpPopup: this.showPopup,
        },
      },
      {
        name: STEP_NAME.editCredits,
        component: this.renderPhotoCreditsEditor,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.large,
          content.closePopup,
          this.closeFlow,
          "t-setting__full-width-sm"
        ),
        settings: {
          content: this.props.content.editCreditsForm,
          getPhotographer: this.getPhotographer,
          save: this.savePhoto,
          cancel: this.forceClosePopup,
          showHelpPopup: this.showPopup,
        },
      },
      {
        name: STEP_NAME.confirmSave,
        component: this.renderSaveDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.saveDialog.closeSavePopup },
          this.closeFlow
        ),
        settings: getSaveDialogSettings({
          content: photoEditFlowContent,
          confirmSave: this.confirmSavePhoto,
          photoEdit: this.backToPhotoEditing,
          close: this.forceClosePopup,
        }),
      },
      {
        name: STEP_NAME.cancel,
        component: this.renderCancelDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.cancelDialog.closeCancalPopup },
          this.closeFlow
        ),
        settings: getCancelDialogSettings({
          content: photoEditFlowContent,
          close: this.forceClosePopup,
          photoEdit: this.backToPhotoEditing,
          confirmSave: this.confirmSavePhoto,
        }),
      },
      {
        name: STEP_NAME.error,
        component: this.renderErrorDialog,
        popupSettings: this.createPopupConfiguration(
          POPUP_SIZES.medium,
          { closePopup: photoEditFlowContent.errorDialog.closeErrorPopup },
          this.closeFlow
        ),
        settings: getErrorDialogSettings({
          content: photoEditFlowContent,
          photoEdit: this.backToPhotoEditing,
        }),
      },
    ];
  };

  private renderPhotoEditor = (photoEditProps: IPhotoEditorProps) => {
    const { photo, imageType } = this.state;
    let component: React.ReactNode | null = null;

    if (photo && imageType) {
      component = (
        <PhotoEditor
          ref={this.photoEditorRef}
          {...photoEditProps}
          photo={photo}
          imageType={imageType}
          photoEditorData={this.photoEditorState}
        />
      );
    }

    return component;
  };

  private renderPhotoCreditsEditor = (
    photoEditProps: IEditCreditsFormProps
  ) => {
    const photo: PhotoEdit = {
      ...(this.photoEditorState.builtImage as Photo),
      isMainPhoto: false,
    };

    return <EditCreditsForm {...photoEditProps} photo={photo} />;
  };

  private renderErrorDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage
      {...alertProps}
      {...{
        texts: {
          ...alertProps.texts,
          description: this.errorMessage,
        },
      }}
    />
  );

  private renderCancelDialog = (alertProps: IAlertMessageProps) => (
    <AlertMessage {...alertProps} />
  );

  public renderSaveDialog = (alertProps: IAlertMessageProps) => {
    const {
      content: { photoEditFlow },
    } = this.props;

    return (
      <AlertMessage {...alertProps}>
        <Checkbox
          htmlId="uploadingPhotoFlowDontShowAgain"
          name="dontShowAgain"
          value="dont"
          valueChanged={this.setSkipSaveAlertState}
          label={photoEditFlow.saveDialog.dontShowAgain}
          ariaLabel={photoEditFlow.saveDialog.dontShowAgain}
        />
      </AlertMessage>
    );
  };

  private closeFlow = () => {
    if (!this.isAvailableClose) {
      this.isAvailableClose = true;
      const photoEditorContainer = this.photoEditorRef.current;

      let photoEditorState: PhotoToolState | undefined;

      try {
        photoEditorState =
          (photoEditorContainer &&
            photoEditorContainer.getPhotoEditorState()) ||
          undefined;
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }

      if (photoEditorState) {
        const { history } = photoEditorState;

        if (!history.isEmpty) {
          this.photoEditorState = photoEditorState;
          this.moveToStep(STEP_NAME.cancel);
          return;
        }
      }
    }

    this.props.flowContext.changeContext("", null);
  };

  private backToPhotoEditing = () => {
    this.isAvailableClose = false;

    this.moveToStep(STEP_NAME.editPhoto);
  };

  private cancelPhotoEditing = (data: PhotoToolState) => {
    this.photoEditorState = data;

    this.closeFlow();
  };

  public preSavePhoto = (data: PhotoToolState) => {
    this.photoEditorState = data;
    const showAlert = getCookie(SKIP_SAVE_ALERT_COOKIE_NAME);

    if (showAlert) {
      this.confirmSavePhoto();
    } else {
      this.isAvailableClose = true;

      this.moveToStep(STEP_NAME.confirmSave);
    }
  };

  private confirmSavePhoto = () => {
    const { skipSaveAlert } = this.state;

    skipSaveAlert &&
      setCookie(SKIP_SAVE_ALERT_COOKIE_NAME, "true", {
        expiryTime: SKIP_SAVE_ALERT_COOKIE_EXPIRY_DAYS,
        expiryTimeUnit: ExpiryTimeUnits.DAY,
      });

    this.moveToPhotoCredits();
  };

  private savePhoto = async (photo: PhotoEdit) => {
    const { globalContext, content } = this.props;

    if (!this.isProcessStarted) {
      this.setState({ showContent: false });
      this.isProcessStarted = true;

      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        true
      );

      try {
        const uploadedPhoto = await PhotoService.uploadPhoto(photo);

        if (photo.isMainPhoto) {
          await PhotoService.makeMainPhoto(uploadedPhoto);
        }

        this.updatePhotoLists(photo);
      } catch (error) {
        this.isProcessStarted = false;
        this.isAvailableClose = true;

        this.errorMessage = getError(
          error.errorStatuses,
          content.photoEditFlow
        );

        globalContext.notifyListener(GlobalEventTypes.closeAllGlobalAlert);

        this.setState({ showContent: true });
        this.moveToStep(STEP_NAME.error);
      }
    }

    this.props.globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );
  };

  private getPhotographer = async (name: string) => {
    try {
      return await PhotoService.getPhotographer(name);
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }

    return Promise.resolve([]);
  };

  private updatePhotoLists = (photo: PhotoEdit) => {
    const { globalContext } = this.props;

    globalContext.notifyListener(GlobalEventTypes.updateGallery, {
      showDialog: true,
    });

    if (photo.isMainPhoto) {
      globalContext.notifyListener(GlobalEventTypes.updateMainPhoto);
      globalContext.notifyListener(GlobalEventTypes.changeMainPhoto);
    } else {
      globalContext.notifyListener(GlobalEventTypes.uploadNewPhoto);
    }

    this.forceClosePopup();
  };

  private forceClosePopup = () => {
    this.isAvailableClose = true;
    this.closeFlow();
  };

  private moveToPhotoCredits = () => {
    this.moveToStep(STEP_NAME.editCredits);
  };

  private showPopup = (message: GlobalAlertMessage) =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );

  private setSkipSaveAlertState = (checked: boolean) =>
    this.setState({ skipSaveAlert: checked });
}

export default compose(withGlobalContext, withFlowContext)(UploadingPhotoFlow);
