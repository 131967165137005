import React, { ReactNode } from "react";
import cx from "classnames";
import "./IconTextLink.scss";
import ReactSVG from "react-svg";
import { handleEnterPress } from "../../../generics/key-press-handler";
import iconMap from "../../GlobalStyles/iconMap";
import { dataTestIds } from "data-testids";

export interface IIconTextLinkProps extends React.AnchorHTMLAttributes<any> {
  /** The text for the link */
  text: string;
  /** render label */
  renderLabel?: ReactNode;
  /** Variants */
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "mainnav"
    | "button-icon-text"
    | "button-icon-text-secondary";
  /** Active state can be passed programmatically */
  active?: boolean;
  /** Optional flag to place icon after text */
  reverseOrder?: boolean;
  /** Icon source URL */
  imageSourceUrl?: string;
  /** URL to go to if the label is used as a link */
  linkUrl?: string;
  /** Handler for interacting with the button via click, Enter or Spacebar keys */
  handleClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Option to open url in a new tab */
  target?: string;
  /** Optional id for the link */
  id?: string;
  /** Optional icon class name to render font icon */
  iconName?: string;
  /** Optional css class for the topmost div */
  className?: string;
  textClassName?: string;
  /** SVG icon */
  svgIconStart?: ReactNode;
  /** SVG icon */
  svgIconEnd?: ReactNode;
  "data-testid"?: string;
}

export const IconTextLink: React.FC<IIconTextLinkProps> = ({
  linkUrl,
  text,
  reverseOrder,
  imageSourceUrl,
  handleClick,
  variant,
  target,
  active,
  id,
  iconName,
  tabIndex = 0,
  onKeyPress,
  className,
  svgIconStart,
  svgIconEnd,
  renderLabel,
  textClassName,
  ...rest
}) => {
  const getIconImg = () => <img src={imageSourceUrl} alt={"icon"} />;

  const beforeInjection = (svg: any) =>
    svg.classList.add("c-icon-text-link__icon-svg");

  const rootClassName = cx(
    "c-icon-text-link",
    `c-icon-text-link--${variant}`,
    {
      "c-icon-text-link--active": active,
      "c-icon-text-link--reversed": reverseOrder,
    },
    className
  );

  return (
    <a
      href={linkUrl}
      target={target}
      className={rootClassName}
      onClick={handleClick}
      id={id}
      tabIndex={tabIndex}
      onKeyPress={onKeyPress ? onKeyPress : handleEnterPress(handleClick)}
      {...rest}
      aria-label={text}
      data-testid={
        dataTestIds.componentLibrary["Atoms.IconTextLinkProps.button"]
      }
    >
      {svgIconStart && (
        <span className="c-icon-text-link__icon-svg-start">{svgIconStart}</span>
      )}
      {iconName && (
        <span className="icon-text-link__icon">
          {String.fromCharCode(iconMap[iconName])}
        </span>
      )}
      {imageSourceUrl && (
        <ReactSVG
          className="c-icon-text-link__icon"
          src={imageSourceUrl || ""}
          fallback={getIconImg}
          wrapper="span"
          beforeInjection={beforeInjection}
        />
      )}
      {renderLabel}
      {text && (
        <span
          className={cx(
            "c-icon-text-link__text",
            { "c-icon-text-link__text--icon": imageSourceUrl || iconName },
            textClassName
          )}
        >
          {text}
        </span>
      )}
      {svgIconEnd && (
        <span className="c-icon-text-link__icon-svg">{svgIconEnd}</span>
      )}
    </a>
  );
};
