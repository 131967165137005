import { MemberGroups } from "shared-types";

export function getLHNavBottomLinks(memberGroup: string) {
  const getInTouch = {
    href: "/get-in-touch",
    title: "common:nav.links.contact",
  };
  const newsAndAdvice = {
    href: "https://www.spotlight.com/news-and-advice/",
    title: "common:nav.links.newsAdvice",
  };
  const contacts = {
    href: "/contacts",
    title: "common:nav.links.contactsListings",
  };
  const discounts = {
    href: "/discounts/members",
    title: "common:nav.links.discounts",
  };
  const rooms = {
    href: "https://www.spotlight.com/rooms-and-studios/studios/",
    title: "common:nav.links.hireRoomsStudios",
  };
  const eventsAgent = {
    href: "https://www.spotlight.com/uncategorized/spotlight-performer-events/",
    title: "common:nav.links.clientEvents",
  };
  const eventsCasting = {
    href: "https://www.spotlight.com/uncategorized/spotlight-performer-events/",
    title: "common:nav.links.events",
  };

  switch (memberGroup) {
    case MemberGroups.agent:
      return [getInTouch, newsAndAdvice, eventsAgent, contacts, rooms];
    case MemberGroups.castingDirector:
    case MemberGroups.searchOnlyCD:
      return [getInTouch, newsAndAdvice, eventsCasting, contacts, rooms];
    case MemberGroups.performer:
    case MemberGroups.graduatePerformer:
    case MemberGroups.yPerformer:
      return [getInTouch, newsAndAdvice, contacts, discounts, rooms];
    default:
      return [];
  }
}
