export function getCastingBBCNav() {
  return {
    mainNavigationItems: [
      {
        text: "common:nav.links.industryResources",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl:
          "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
      },
      {
        text: "common:nav.links.contactsListings",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1163/contacts.svg",
        linkUrl: "/contacts/",
      },
      {
        text: "common:nav.links.performerSearch",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1166/magnify.svg",
        linkUrl: "/casting/performers",
      },
      {
        text: "common:nav.links.performanceCalendar",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1170/performancecal.svg",
        linkUrl: "https://calendar.spotlight.com/",
      },
      {
        text: "common:nav.help",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1164/lhnhelp.svg",
        linkUrl:
          "https://www.spotlight.com/the-small-print/help-for-casting-users-of-the-spotlight-link/",
      },
    ],
  };
}
