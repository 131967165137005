import { IViewGalleryPhotoContent } from "./GalleryPhotoView";
import { IAlertMessageProps, IPopupProps } from "component-library";

interface ICantDeletePhotoAlert {
  cantDeleteAlertTitle: string;
  cantDeleteDescription: string;
  cantDeleteClosePopup: string;
  cantDeleteCloseButton: string;
}

interface IDeletePhotoConfirmation {
  deletePhotoTitle: string;
  deletePhotoDescription: string;
  deleteClosePopup: string;
  deletePhotoButton: string;
  cancelDeletePopup: string;
}

export interface IGalleryPhotoFlowContent {
  viewGalleryPhoto: IViewGalleryPhotoContent;
  cantDeletePhotoAlert: ICantDeletePhotoAlert;
  deletePhotoConfirmation: IDeletePhotoConfirmation;
  photoLoadingError: string;
}
export const IGalleryPhotoFlowContentKeys = [
  "viewGalleryPhoto",
  "cantDeletePhotoAlert",
  "deletePhotoConfirmation",
  "photoLoadingError",
];

declare type DeleteDialogSettings = {
  content: IDeletePhotoConfirmation;
  deletePhoto: () => void | Promise<void>;
  backToView: () => void;
};

export const getDeleteDialogSettings = (setting: DeleteDialogSettings) => {
  const { content, deletePhoto, backToView } = setting;

  const config: IAlertMessageProps = {
    icon: "notice",
    buttons: [],
    focusOnOpen: true,
    texts: {
      title: content.deletePhotoTitle,
      description: content.deletePhotoDescription,
    },
  };

  config.buttons = [
    { name: content.deletePhotoButton, type: "primary", click: deletePhoto },
    { name: content.cancelDeletePopup, type: "secondary", click: backToView },
  ];

  return config;
};

export const getEditingPhotoError = (content: ICantDeletePhotoAlert) => {
  const alertProps: IAlertMessageProps = {
    icon: "warning",
    texts: {
      title: content.cantDeleteAlertTitle,
      description: content.cantDeleteDescription,
    },
    buttons: [],
  };

  const popupProps: IPopupProps = {
    width: { lg: 4, md: 6 },
    close: () => undefined,
    priority: "high",
    texts: { closePopup: content.cantDeleteClosePopup },
  };

  return {
    popupProps: popupProps,
    alertMessageProps: alertProps,
    closeButton: { name: content.cantDeleteCloseButton, type: "primary" },
  };
};
