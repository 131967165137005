import React from "react";

import {
  Autocomplete,
  IAutocompleteItem,
  IInputConfig,
  IGroupValidationResult,
  WatchedFunction,
  InputGroup,
  Button,
  debounce,
} from "component-library";

import {
  IFormState,
  getInitialFormState,
  PhotoFormType,
  cropAreaValidation,
  getErrorResolutionPopup,
  getFormConfiguration,
  InputBuilder,
  EDIT_PHOTO_FORM,
  MIN_PHOTO_HEIGHT,
  calculateHeight,
  CONTENT,
} from "./AdminPhotoEditContent";

import { Photo } from "models";
import {
  GlobalAlertMessage,
  PhotoToolState,
  PhotoTool,
} from "shared/modules/Common";
import { config } from "config";
import { PhotoService } from "services/PhotoService";

import "./AdminPhotoEdit.scss";

export interface IAdminPhotoEditProps {
  photo?: Photo;
  imageType: string;
  photoEditorData?: PhotoToolState;
  error?: string;
  content: { header: string };
  savePhoto: (data: PhotoToolState, photo: Photo) => void;
  cancelEditing: (data?: PhotoToolState, photo?: Photo) => void;
  errorNotification: (message: GlobalAlertMessage) => void;
}

interface IAdminPhotoEditState extends IFormState {
  displayAutocomplete: boolean;
  autocompleteList: IAutocompleteItem[];
  minPhotoHeight: number;
  occupiedHeight: number;
}

const VALIDATE = new WatchedFunction(() => undefined);

export default class AdminPhotoEdit extends React.Component<
  IAdminPhotoEditProps,
  IAdminPhotoEditState
> {
  public readonly state: IAdminPhotoEditState;
  private formConfig: IInputConfig[];
  private controlBuilder: InputBuilder;
  private photographerTimer: any;
  private photoService = new PhotoService();

  private containerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private headerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private buttonsContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private photoToolRef: React.RefObject<PhotoTool> =
    React.createRef<PhotoTool>();

  constructor(props: IAdminPhotoEditProps) {
    super(props);

    this.controlBuilder = new InputBuilder(this.inputChanged);

    const { photographer = "", description = "" } = props.photo || {};

    const form: PhotoFormType = {
      photographer,
      description,
    };

    this.state = {
      ...getInitialFormState({}, form),
      autocompleteList: [],
      occupiedHeight: 0,
      displayAutocomplete: false,
      minPhotoHeight: MIN_PHOTO_HEIGHT,
    };
  }

  public render() {
    const autoComplete = (
      <Autocomplete
        list={this.state.autocompleteList}
        formatName={this.formatName}
        display={this.state.displayAutocomplete}
        selectItem={this.selectPhotographer}
      />
    );

    const cropperSettings = {
      style: {
        minHeight: `${this.state.minPhotoHeight}px`,
        overflow: "hidden",
        maxHeight: `calc(100vh - ${this.state.occupiedHeight}px)`,
        width: "100%",
      },
    };

    const {
      photo = null,
      error = null,
      photoEditorData = null,
      imageType,
    } = this.props;
    const { form } = this.state;

    const isSaveButtonDisabled = Boolean(error) || !Boolean(photo && photo.url);

    this.formConfig = getFormConfiguration(form);

    return (
      <div className="c-admin-photo-edit" ref={this.containerRef}>
        <div ref={this.headerRef} className="c-admin-photo-edit__header">
          {this.props.content.header}
        </div>
        <div className="c-admin-photo-edit__photo-editor">
          <PhotoTool
            cropValidation={cropAreaValidation}
            error={error}
            validateApply={this.validateApply}
            ref={this.photoToolRef}
            photo={photo}
            cropperSettings={cropperSettings}
            photoToolData={photoEditorData}
            texts={CONTENT.photoEditor}
            imageType={imageType}
          />
        </div>
        <div className="c-admin-photo-edit__photo-form">
          <div className="c-admin-photo-edit__photo-data">
            <InputGroup
              inputConfigs={this.formConfig}
              validationDone={this.validationPerformed}
              triggerValidation={VALIDATE}
              variant="tertiary"
            >
              <div className="c-admin-photo-edit__photographer-name">
                {this.controlBuilder.getInput(
                  EDIT_PHOTO_FORM.photographer,
                  this.state,
                  autoComplete
                )}
              </div>
              <div className="c-admin-photo-edit__description">
                {this.controlBuilder.getTextArea(
                  EDIT_PHOTO_FORM.description,
                  this.state
                )}
              </div>
            </InputGroup>
          </div>
        </div>
        <div
          ref={this.buttonsContainerRef}
          className="c-admin-photo-edit__buttons"
        >
          <div className="c-admin-photo-edit__button">
            <Button
              type="secondary"
              text={CONTENT.texts.cancel}
              onClick={this.cancel}
            />
          </div>
          <div className="c-admin-photo-edit__button">
            <Button
              type="primary"
              disabled={isSaveButtonDisabled}
              text={CONTENT.texts.save}
              onClick={this.save}
            />
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.calculateHeight();

    window.addEventListener("resize", this.adaptHeight, false);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.adaptHeight, false);
  }

  public componentWillReceiveProps(newProps: IAdminPhotoEditProps) {
    if (
      newProps.photo &&
      !this.comparePhotoData(newProps.photo, this.state.form)
    ) {
      const { photographer = "", description = "" } = newProps.photo || {};

      const form: PhotoFormType = {
        photographer,
        description,
      };

      this.setState({ ...getInitialFormState(this.state, form) });
    }
  }

  public getPhotoEditorState = () => {
    const photoTool = this.photoToolRef.current;

    let photoToolState: PhotoToolState | undefined;

    if (photoTool && photoTool.isReady) {
      photoToolState = photoTool.getPhotoToolState() || undefined;
    }

    return photoToolState;
  };

  public getPhotoCreditsData = () => this.state.form;

  private formatName = (item: IAutocompleteItem) => {
    const indexStart = item.name
      .toLowerCase()
      .indexOf(this.state.form.photographer.toLowerCase());
    if (indexStart < 0) {
      return item.name;
    }

    const matchPart = item.name.substring(
      indexStart,
      indexStart + this.state.form.photographer.length
    );
    return item.name.replace(matchPart, `<b>${matchPart}</b>`);
  };

  private validationPerformed = (result: IGroupValidationResult) => {
    const validationData = {
      ...this.state.validationData,
      ...result.validationData,
    };

    this.setState({ validationData });
  };

  private validateApply = (canvas: any): boolean => {
    const isValidImageResolution =
      canvas.width >= config.photoValidation.minResolution.width &&
      canvas.height >= config.photoValidation.minResolution.height;
    if (!isValidImageResolution) {
      const errorResolutionPopup = getErrorResolutionPopup();

      this.props.errorNotification(errorResolutionPopup);
    }

    return isValidImageResolution;
  };

  private inputChanged = (name: keyof PhotoFormType) => {
    const state = { ...this.state };
    return (value: string) => {
      if (name === EDIT_PHOTO_FORM.photographer) {
        this.photographerTimer && clearTimeout(this.photographerTimer);

        if (value.trim().length > 2) {
          this.photographerTimer = setTimeout(
            this.loadPhotographers,
            300,
            value
          );
        } else {
          state.autocompleteList = [];
          state.displayAutocomplete = false;
        }
      }

      state.form[name] = value;
      this.setState(state, VALIDATE.call);
    };
  };

  private selectPhotographer = (item: IAutocompleteItem) => {
    const state = { ...this.state, displayAutocomplete: false };
    state.form.photographer = item.name;
    this.setState(state, VALIDATE.call);
  };

  private loadPhotographers = async (value: string) => {
    const photographers = await this.photoService.getPhotographer(value);

    const list: IAutocompleteItem[] = photographers.names.map((item: any) => ({
      name: item,
      data: item,
    }));

    this.setState({
      autocompleteList: list,
      displayAutocomplete: list.length > 0,
    });
  };

  private calculateHeight = () => {
    const buttonsContainer = this.buttonsContainerRef.current;
    const header = this.headerRef.current;
    const container = this.containerRef.current;

    if (header && buttonsContainer && container) {
      this.setState({
        ...calculateHeight(
          buttonsContainer,
          header,
          container,
          this.state.occupiedHeight
        ),
      });
    }
  };

  private adaptHeight = debounce(this.calculateHeight, 300);

  private cancel = () => {
    const { error, cancelEditing, photo } = this.props;

    if (!error) {
      const photoTool = this.photoToolRef.current;

      if (photoTool && photoTool.isReady && photo) {
        const state = photoTool.getPhotoToolState() || undefined;
        const { photographer, description } = this.state.form;

        cancelEditing(state, { ...photo, photographer, description });
      } else {
        cancelEditing();
      }
    } else {
      cancelEditing();
    }
  };

  private save = () => {
    if (this.controlBuilder.isFormValid(this.state)) {
      const photoTool = this.photoToolRef.current;
      const { photo, savePhoto } = this.props;

      if (photoTool && photoTool.isReady && photo) {
        const state = photoTool.getPhotoToolState();
        const { photographer, description } = this.state.form;

        if (state) {
          savePhoto(state, { ...photo, photographer, description });
        }
      }
    }
  };

  private comparePhotoData = (
    prevPhoto: Pick<Photo, "description" | "photographer">,
    newPhoto: Pick<Photo, "description" | "photographer">
  ) =>
    prevPhoto.description === newPhoto.description &&
    prevPhoto.photographer === newPhoto.photographer;
}
