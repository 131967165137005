import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  SectionContainer,
  transformMillisecToSec,
} from "component-library";

import {
  Breadcrumbs,
  IBreadcrumbsContent,
} from "components/common/Breadcrumbs";
import {
  ITranslationContextProps,
  Translator,
  withTranslation,
} from "contexts/TranslationContext";
import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";
import {
  getAlertMessage,
  getSectionContainerProps,
  IAddNewVideoPageContent,
  PopupTypes,
  getStoreLimitAlert,
  IAddNewVideoPageContentKeys,
} from "./AddNewVideoPageContent";
import { NewVideoForm } from "./components/NewVideoForm";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { getLocalUrl } from "common";
import mediaService from "mediaModules/media/services/MediaService";
import {
  MediaAssetType,
  MediaMetadataPayload,
  MediaStorageSpace,
} from "mediaModules/media/models";
import { scrollRefToTop } from "mediaModules/media/utils/page";
import { GAEvents } from "mediaModules/media/components/GAEvents";

import "./AddNewVideoPage.scss";
import { IBreadcrumbsContentKeys } from "components/common/Breadcrumbs/Breadcrumbs";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

export interface IAddNewVideoPageProps
  extends ITranslationContextProps,
    IGlobalContextProps,
    RouteComponentProps {}

interface IAddNewVideoPageState {
  content: IAddNewVideoPageContent;
  metadataPayload: MediaMetadataPayload;
  isLoading: boolean;
  storageSpace: MediaStorageSpace;
  isFileUploading: boolean;
}

export class AddNewVideoPage extends React.Component<
  IAddNewVideoPageProps,
  IAddNewVideoPageState
> {
  private pageRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IAddNewVideoPageProps) {
    super(props);

    this.state = {
      content: AddNewVideoPage.mapTranslation(props.translator),
      metadataPayload: new MediaMetadataPayload(),
      storageSpace: {
        allowedStorageSpace: {
          maxAllowedStoreInSec: 0,
          maxAllowedDisplayInSec: 0,
        },
        usedStorageSpace: {
          usedStoreValueInSec: 0,
          usedDisplayValueInSec: 0,
        },
      },
      isLoading: false,
      isFileUploading: false,
    };
  }

  public render() {
    const { content, metadataPayload, storageSpace } = this.state;

    const breadcrumbsContent: IBreadcrumbsContent =
      this.getBreadcrumbsTranslation();
    const sectionContainerProps = getSectionContainerProps(
      content,
      this.handleCloseClick
    );

    return (
      <div ref={this.pageRef}>
        <Breadcrumbs content={breadcrumbsContent} />
        <div className="g-content-area p-add-new-video-page">
          <div className="g-col-lg-12 p-add-new-video-page__container">
            <SectionContainer {...sectionContainerProps}>
              <div className="p-add-new-video-page__content">
                <NewVideoForm
                  storageSpace={storageSpace}
                  content={content.newVideoForm}
                  metadataPayload={metadataPayload}
                  updateMetadata={this.updateMetadataPayload}
                  saveChanges={this.saveMetadata}
                  showDeletePopup={this.showDeletePopup}
                  preUploadValidation={this.preUploadValidation}
                  disableSaveButton={this.disableSaveButton}
                >
                  {this.renderFormButtons}
                </NewVideoForm>
              </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    );
  }

  public static getDerivedStateFromProps(
    nextProps: IAddNewVideoPageProps,
    prevState: IAddNewVideoPageState
  ) {
    let stateUpdates = {};

    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(prevState.content)
    ) {
      const content = AddNewVideoPage.mapTranslation(nextProps.translator);

      stateUpdates = { content };
    }

    return stateUpdates;
  }

  public async componentDidMount() {
    try {
      const allowedStorageSpace = await mediaService.getAllowedStorageSpace(
        MediaAssetType.Video
      );
      const mediaAssets = await mediaService.getMediaAssets(
        MediaAssetType.Video,
        this.closeGlobalAlert
      );
      const usedStorageSpace = mediaService.getUsedStorageSpace(mediaAssets);

      this.setState({
        storageSpace: {
          allowedStorageSpace: allowedStorageSpace,
          usedStorageSpace: usedStorageSpace,
        },
      });

      GAEvents.trackPageLoad(
        "Add video page is loaded",
        MediaAssetType.Video,
        true
      );
    } catch (error) {
      captureExceptionEvent(error, authorizationService.getDecodedUserToken());
    }
  }

  private renderFormButtons = (submit: () => void) => {
    const { content, isLoading, isFileUploading } = this.state;

    return (
      <div className="p-add-new-video-page__buttons">
        <div className="p-add-new-video-page__button">
          <Button
            type="primary"
            disabled={isFileUploading}
            onClick={() => this.handleSaveClick(submit)}
            text={content.saveButton}
            loading={isLoading}
            loadingText={content.saveButton}
          />
        </div>
        <div className="p-add-new-video-page__button">
          <Button
            type="secondary"
            onClick={this.handleCancelAndCloseClick}
            text={content.cancelButton}
          />
        </div>
      </div>
    );
  };

  private handleSaveClick = (submit: () => void) => {
    GAEvents.addMediaAsset.trackSaveClick(MediaAssetType.Video);
    submit();
  };

  private handleCancelAndCloseClick = async () => {
    GAEvents.addMediaAsset.trackCancelCloseClick(MediaAssetType.Video);
    await this.closePage();
  };

  private handleCloseClick = async () => {
    GAEvents.addMediaAsset.trackCloseClick(MediaAssetType.Video);
    await this.closePage();
  };

  private closeGlobalAlert = () =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.closeAllGlobalAlert
    );

  private closePage = async () => {
    const { content, metadataPayload } = this.state;
    const { globalContext } = this.props;

    const isMetadataPayloadChanged = Boolean(
      metadataPayload.title || metadataPayload.mediaInfoId
    );

    if (isMetadataPayloadChanged) {
      const message = getAlertMessage(
        content,
        PopupTypes.CancelClose,
        this.closeGlobalAlert,
        async () => {
          this.closeGlobalAlert();
          await this.deleteMediaObject(metadataPayload.mediaInfoId);
          this.redirectTo();
        }
      );

      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );
    } else {
      this.redirectTo();
    }
  };

  private showDeletePopup = async (
    metadataPayload: MediaMetadataPayload,
    callback
  ) => {
    const {
      content,
      metadataPayload: { mediaInfoId },
    } = this.state;
    const { globalContext } = this.props;

    const message = getAlertMessage(
      content,
      PopupTypes.Delete,
      this.closeGlobalAlert,
      async () => {
        this.closeGlobalAlert();
        this.updateMetadataPayload(metadataPayload);
        await this.deleteMediaObject(mediaInfoId);
        callback();
      }
    );

    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
  };

  private updateMetadataPayload = (metadataPayload: MediaMetadataPayload) =>
    this.setState({ metadataPayload });

  private saveMetadata = async () => {
    const { metadataPayload } = this.state;

    if (metadataPayload.mediaInfoId) {
      this.setState({ isLoading: true });

      try {
        await mediaService.createMediaMetadata(metadataPayload);
        this.redirectTo();
      } catch (error) {
        this.setState({ isLoading: false });
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    }
  };

  private deleteMediaObject = async (mediaInfoId: string) => {
    if (mediaInfoId) {
      try {
        await mediaService.deleteMediaObjectById(mediaInfoId);
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    }
  };

  private redirectTo = () => {
    scrollRefToTop(this.pageRef);
    this.props.history.push(getLocalUrl(this.state.content.cvEditorLink));
  };

  private static mapTranslation = (translator: Translator) =>
    translator.compileTranslationObject<IAddNewVideoPageContent>(
      IAddNewVideoPageContentKeys,
      "common"
    );

  private getBreadcrumbsTranslation = () =>
    this.props.translator
      .getTranslator("breadcrumbs", true)
      .createTranslationObject<IBreadcrumbsContent>(IBreadcrumbsContentKeys);

  private preUploadValidation = (durationInMs: number) => {
    const { globalContext } = this.props;
    const {
      content,
      storageSpace: {
        usedStorageSpace: { usedStoreValueInSec },
        allowedStorageSpace: { maxAllowedStoreInSec },
      },
    } = this.state;

    const durationInSecAfterAdding =
      transformMillisecToSec(durationInMs) + usedStoreValueInSec;

    if (durationInSecAfterAdding > maxAllowedStoreInSec) {
      const message = getStoreLimitAlert(content.storeLimitAlert, () =>
        this.closeGlobalAlert()
      );
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );

      return false;
    }

    return true;
  };

  private disableSaveButton = (isFileUploading: boolean) =>
    this.setState({ isFileUploading });
}

export default withTranslation(
  withGlobalContext(AddNewVideoPage),
  "addNewVideoPage"
);
