import { IDropdownCheckboxValueItemConfig } from "../../Molecules/DropdownCheckbox/DropdownCheckboxValue";
import { IStat } from "shared-types";

export function mapHeritageAndEthnicityStats(stats: IStat<number>[] = []) {
  return function (value: IDropdownCheckboxValueItemConfig) {
    const stat = stats.find((stat) => +stat.value === +value.value);
    const count = stat?.count || 0;

    return {
      ...value,
      labelPostFix: count,
      disabled: count === 0,
    };
  };
}
