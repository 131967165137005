import React from "react";

/**
 * Attach a reference to an input field to get control
 * over focus and blur events
 */
export const useFocus = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const refAvailable = inputRef.current;

  const focus = () => {
    if (refAvailable) {
      inputRef.current.focus();
    }
  };

  const blur = () => {
    if (refAvailable) {
      inputRef.current.blur();
    }
  };

  return { inputRef, focus, blur };
};
