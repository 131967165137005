import { i18n } from "i18n";

export interface ICountry {
  localisedCountryName: string;
  countryCode: string;
}

export interface Location {
  id: string;
  description: string;
  place_id: string;
  types: string[];
}

export interface ILocationTree {
  id?: string;
  innerLevel?: ILocationTree[];
  levelName: string;
}

export interface IGoogleLocation {
  value: string;
  googlePlaceId: string;
}

export interface IGooglePlaceLocation {
  id: string;
  countryCode: string;
  name: string;
  administrativeAreaLevel2: string;
  administrativeAreaLevel1: string;
}

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const GET_LOCATIONS_BY_ID = "get-locations-by-id";

export default class LocationService {
  private url: string;
  private sessionToken = Math.random().toString(36).substr(2);

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getCountriesAndStates(
    locale: string,
    shouldIncludeStates = false
  ): Promise<ICountry[]> {
    const urlToFetch = new URL(`${this.url}location/countries`);
    urlToFetch.searchParams.set("culture", locale);

    if (shouldIncludeStates) {
      urlToFetch.searchParams.set(
        "inlcludeStates",
        String(shouldIncludeStates)
      );
    }

    return await fetch(urlToFetch).then((res) => res.json());
  }

  public async searchLocation(
    searchText: string,
    langcode: string,
    excludeIds?: string[]
  ): Promise<Location[]> {
    const queryString = new URLSearchParams({
      searchText,
      langcode,
      types: "geocode",
      sessionToken: this.sessionToken,
    }).toString();

    const locations: Location[] = await (
      await fetch(`${this.url}locationsearch?${queryString}`)
    ).json();

    if (excludeIds) {
      return locations.filter(
        (location) => !excludeIds.some((id) => location.place_id === id)
      );
    }

    return locations;
  }

  public async getRoundedLocation(placeId: string, langcode: string) {
    return await fetch(
      `${this.url}location/google/${placeId}?langcode=${langcode}`
    ).then((res) => res.json());
  }

  // returns shared IGooglePlaceLocation type
  public async getLocationByGooglePlaceIds(
    locationId: string,
    langcode: string
  ): Promise<IGooglePlaceLocation> {
    const newLocation = await this.getRoundedLocation(locationId, langcode);

    const nameParts = [
      newLocation.administrativeAreaLevel2,
      newLocation.administrativeAreaLevel1,
      newLocation.country,
    ];

    const name = nameParts.filter(Boolean).join(", ");

    return { ...newLocation, name };
  }

  // returns shared IGoogleLocation type
  public async getSearchAndFiltersLocationByGooglePlaceIds(
    langCode: string,
    locationId: string
  ): Promise<IGoogleLocation> {
    const newLocation = await this.getRoundedLocation(locationId, langCode);

    const nameParts = [
      newLocation.administrativeAreaLevel2,
      newLocation.administrativeAreaLevel1,
      newLocation.country,
    ];

    const description = nameParts.filter(Boolean).join(", ");

    return { googlePlaceId: newLocation.id, value: description };
  }

  public async getLocationsByIds(locationsIds: string[]) {
    return await fetch(
      `${this.url}location/rendertree?culture=${i18n.language}`,
      {
        method: "post",
        body: JSON.stringify(locationsIds),
        headers: { "content-type": "application/json" },
      }
    ).then((res) => res.json());
  }

  public async getGoogleLocationsByIds(
    locationsIds: string[]
  ): Promise<IGoogleLocation[]> {
    const locationsTree = await this.getLocationsByIds(locationsIds);

    return this.convertLocationTreeToList(locationsTree);
  }

  private convertLocationTreeToList = (
    locations: ILocationTree[],
    parentLocations: string[] = []
  ): IGoogleLocation[] => {
    return locations.reduce((acc, location: ILocationTree) => {
      let listOfLocations: IGoogleLocation[] = [];
      const hasInnerLevel = Boolean(location.innerLevel?.length);
      const locationLevels = [location.levelName, ...parentLocations];

      if (location.id) {
        listOfLocations.push({
          googlePlaceId: location.id,
          value: locationLevels.join(", "),
        });
      }

      if (hasInnerLevel) {
        const innerLocations = this.convertLocationTreeToList(
          location.innerLevel!,
          locationLevels
        );
        listOfLocations = [...listOfLocations, ...innerLocations];
      }

      return [...acc, ...listOfLocations];
    }, [] as IGoogleLocation[]);
  };
}
