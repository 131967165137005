import React from "react";
import { Resizable } from "re-resizable";
import cx from "classnames";
import { IBaseInputProps } from "../../Atoms/InputsCommon/BaseInputProps";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import {
  IInputWrapperProps,
  ERROR,
  WARNING,
  InputWrapper,
} from "../../Atoms/InputsCommon/InputWrapper";
import { omit } from "../../../generics/object-manipulation";
import { debounce } from "../../../generics/debounce";
import "./TextArea.scss";

// import Expander from "-!svg-react-loader?name=Expander!src/images/svg/icons/expander.svg";
import { ReactComponent as Expander } from "../../../images/svg/icons/expander.svg";
import {
  addClass,
  removeClass,
  isSmallDeviceWidth,
  isMediumDeviceWidth,
} from "../../../generics/dom-extensions";
import { Spinner } from "../../Atoms/Spinner/Spinner";

// tslint:disable-next-line:no-var-requires
const ownStyles = require("./TextArea.scss");

export interface ITextAreaProps
  extends IBaseInputProps<HTMLTextAreaElement, string> {
  /**
   * Whether the textarea should automatically resize to match the text within
   */
  autoResize?: boolean;
  /**
   * Whether the textarea should automatically resize to match the text within, but only on mobile devices - true by default
   */
  autoResizeMobile?: boolean;
  minLength?: number;
  maxLength?: number;
  /**
   * A "soft" max length which can be exceeded without preventing form submission, but which when exceeded triggers a warning state alerting the user.
   */
  warningLength?: number;
  /**
   * The message to be displayed when in that warning state. Note that these are dependent on the value prop, which is handled externally.
   */
  warningLengthMessage?: string;
  validationMessage?: string;
  /**
   * Whether user should be allowed to exceed the maximum permitted character length.
   * Validation messages should still be shown as specified on a per case basis, but typing/pasting should be allowed.
   */
  allowsOverMaxLength?: boolean;
  /**
   * The message to be displayed when character count excedes maxLength (if user is allowed to type). Validated internally.
   * Takes precedence over external validation. Only works with allowsOverMaxLength true
   */
  overMaxLengthMessage?: string;
  /**
   * Whether the user is allowed to resize the textarea. Textarea should maintain altered size while in focus and return back to original when blurred
   */
  isResizable?: boolean;
  /**
   * Called when size is reset to default after resizing
   */
  resetSizeCallback?: () => void;
  /**
   * Display a [current]/[max] character count in the bottom right-hand corner of the textarea
   */
  showsCharacterCount?: boolean;
  /**
   * Displays loading state with overlayed container and Spinner
   */
  isLoading?: boolean;
  /**
   * Default textAreaHeight for different screen sizes. should be passed in px
   */
  height?: {
    lg?: number; // could remain default if not provided
    md?: number; // if not provided try get lg or default
    sm?: number; // if not provided try get md, if md not provided use lg or finaly default
  };
  /**
   * Whether the textarea should use font size small for input area
   */
  smallFontSize?: boolean;
  whiteInput?: boolean;
}

interface ITextAreaState {
  hasBlurredOnce: boolean;
  isFocused: boolean;
  height: number;
  isResizeAllowed?: boolean;
  isAutoResizeAllowed: boolean;
}

const EXCLUDED_PROPS = [
  "label",
  "invalid",
  "warningLength",
  "warningLengthMessage",
  "validationMessage",
  "onBlur",
  "isFormSubmitted",
  "valueChanged",
  "autoResize",
  "autoResizeMobile",
  "allowsOverMaxLength",
  "overMaxLengthMessage",
  "isResizable",
  "showsCharacterCount",
  "maxLength",
  `isLoading`,
  "resetSizeCallback",
  "height",
];

export class TextArea extends React.Component<ITextAreaProps, ITextAreaState> {
  public static defaultProps: Partial<ITextAreaProps> = {
    autoResizeMobile: true,
  };
  private isResized: boolean = false;
  private componentRef = React.createRef<HTMLDivElement>();
  private textAreaContainerRef = React.createRef<HTMLDivElement>();
  private textAreaRef = React.createRef<HTMLTextAreaElement>();
  private resizableRef = React.createRef<Resizable>();
  private spacerRef = React.createRef<HTMLDivElement>();
  private defaultSize = {
    width: "100%",
    height: parseInt(ownStyles.textAreaHeight),
  };
  private characterCountOffset = parseInt(
    ownStyles.textAreaCharacterCountOffset
  );

  public constructor(props: ITextAreaProps) {
    super(props);

    this.state = {
      hasBlurredOnce: false,
      isFocused: false,
      isResizeAllowed: this.isResizeAllowed(),
      isAutoResizeAllowed: this.isAutoResizeAllowed(),
      height: ownStyles.textAreaHeight,
    };
  }

  public componentDidMount() {
    this.runSizeAdapters();
    window.addEventListener("resize", this.debouncedRunSizeAdapters);
  }

  public componentDidUpdate(prevProps: ITextAreaProps) {
    const { value = "" } = this.props;
    const { value: prevValue = "" } = prevProps;
    const valuesLengthDifference = Math.abs(value.length - prevValue.length);

    if (valuesLengthDifference > 0) {
      this.adaptHeightIfNeeded();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedRunSizeAdapters);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  public render() {
    const WRAPPER_PROPS: IInputWrapperProps = this.makeWrapperProps();
    const isInvalid =
      WRAPPER_PROPS.invalid === "warning" || WRAPPER_PROPS.invalid === "error";

    const textAreaClassNames = cx("c-textarea", this.props.className, {
      "c-textarea--resizable": this.props.isResizable,
      "c-textarea--shows-character-count": this.props.showsCharacterCount,
    });

    return (
      <div className={textAreaClassNames} ref={this.componentRef}>
        <InputWrapper {...WRAPPER_PROPS}>
          <InputLabel
            label={this.props.label}
            annex={this.props.annex}
            htmlFor={this.props.id}
          />
          {this.props.isResizable && this.state.isResizeAllowed
            ? this.renderResizableTextArea(isInvalid)
            : this.renderTextArea(isInvalid)}
        </InputWrapper>
      </div>
    );
  }

  private renderResizableTextArea = (isInvalid: boolean) => {
    return (
      <div className="c-textarea__spacer" ref={this.spacerRef}>
        <Resizable
          ref={this.resizableRef}
          className="c-textarea__resizable-container"
          defaultSize={{
            width: "100",
            height: "auto",
          }}
          bounds="window"
          enable={{ bottomRight: true }}
          onResize={this.onTextareaResize}
          handleComponent={{
            bottomRight: (
              <div className="c-textarea__resize-handle g-hidden-sm">
                <Expander />
              </div>
            ),
          }}
          handleStyles={{
            bottomRight: {
              right: "-1px",
              bottom: "1px",
            },
          }}
        >
          {this.renderTextArea(isInvalid)}
        </Resizable>
      </div>
    );
  };

  private renderTextArea = (isInvalid: boolean) => {
    const TEXTAREA_PROPS: ITextAreaProps = this.makeTextAreaProps();
    const isCharactersCounterCanBeShown =
      this.props.showsCharacterCount && this.props.maxLength;
    const isShowCharacterCount =
      isCharactersCounterCanBeShown && this.state.isFocused;

    const textAreaContainerClassNames = cx("c-textarea__container", {
      "c-textarea__container--focus": this.state.isFocused,
      "c-textarea__container--invalid": isInvalid,
    });

    const textAreaInputClassNames = cx({
      "c-textarea__input--auto-resize": this.state.isAutoResizeAllowed,
      "c-textarea__input--shows-character-count":
        this.props.showsCharacterCount,
      "c-textarea__input--no-scroll":
        !this.state.isAutoResizeAllowed && isSmallDeviceWidth(),
      "c-textarea__input--font-size-small": this.props.smallFontSize,
      "c-textarea__input--white": this.props.whiteInput || this.props.value,
    });

    const isCutTextArea =
      isShowCharacterCount && !this.state.isAutoResizeAllowed;
    const height = this.state.height - 2;
    const textAreaHeight = `${
      isCutTextArea ? height - this.characterCountOffset : height
    }px`;

    return (
      <div
        className={textAreaContainerClassNames}
        ref={this.textAreaContainerRef}
      >
        <textarea
          ref={this.textAreaRef}
          {...TEXTAREA_PROPS}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.handleChange}
          className={textAreaInputClassNames}
          style={{
            height: textAreaHeight,
          }}
        />
        {isCharactersCounterCanBeShown &&
          this.renderCharacterCounter(this.props.maxLength!)}
        {this.props.isLoading && this.renderSpinner()}
      </div>
    );
  };

  private renderSpinner = () => (
    <div className="c-textarea__spinner">
      <Spinner />
    </div>
  );

  private renderCharacterCounter = (maxLength: number) => {
    const shouldShowCountWarning =
      this.props.value &&
      this.props.warningLength &&
      this.props.value.length > this.props.warningLength &&
      this.props.value.length <= maxLength;

    const shouldShowCountError =
      this.props.value && this.props.value.length > maxLength;

    const textAreaCharacterCounterClassNames = cx(
      "c-textarea__character-count",
      {
        "c-textarea__character-count--warning":
          this.state.isFocused && shouldShowCountWarning,
        "c-textarea__character-count--error":
          this.state.isFocused && shouldShowCountError,
        "c-textarea__character-count--font-size-tiny": this.props.smallFontSize,
      }
    );

    const valueLength = (this.props.value && this.props.value.length) || 0;
    const currentLength =
      valueLength > maxLength ? maxLength - valueLength : valueLength;

    return (
      <div className="c-textarea__bottom-controls-container">
        <div className={textAreaCharacterCounterClassNames}>
          {this.state.isFocused && (
            <div className="c-textarea__character-count-text">{`${currentLength} / ${maxLength}`}</div>
          )}
        </div>
      </div>
    );
  };

  private makeWrapperProps = (): IInputWrapperProps => {
    const WRAPPER_PROPS: IInputWrapperProps = {};

    if (
      this.props.allowsOverMaxLength &&
      this.props.maxLength &&
      this.props.value &&
      this.props.maxLength < this.props.value.length &&
      this.props.overMaxLengthMessage
    ) {
      WRAPPER_PROPS.invalid = ERROR;
      WRAPPER_PROPS.validationMesssage = this.props.overMaxLengthMessage;
    } else if (
      this.props.invalid &&
      (this.state.hasBlurredOnce || this.props.isFormSubmitted)
    ) {
      WRAPPER_PROPS.invalid = ERROR;
      WRAPPER_PROPS.validationMesssage = this.props.validationMessage;
    } else if (
      this.props.value &&
      this.props.warningLength &&
      this.props.warningLength < this.props.value.length
    ) {
      WRAPPER_PROPS.invalid = WARNING;
      WRAPPER_PROPS.validationMesssage = this.props.warningLengthMessage;
    }

    return WRAPPER_PROPS;
  };

  private makeTextAreaProps = (): ITextAreaProps => {
    const TEXTAREA_PROPS: ITextAreaProps = {
      ...omit(this.props, EXCLUDED_PROPS),
    };

    if (this.props.maxLength) {
      if (this.props.allowsOverMaxLength) {
        TEXTAREA_PROPS.maxLength = 1000 + this.props.maxLength;
      } else {
        TEXTAREA_PROPS.maxLength = this.props.maxLength;
      }
    }
    return TEXTAREA_PROPS;
  };

  public focus() {
    const textArea = this.textAreaRef.current;
    if (textArea) {
      textArea.focus();
      textArea.setSelectionRange(textArea.value.length, textArea.value.length);
    }
  }

  private onFocus = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ isFocused: true });

    this.props.onFocus && this.props.onFocus(event);
  };

  private onBlur = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState(
      { hasBlurredOnce: true, isFocused: false },
      this.resetResizableContainer
    );

    this.props.onBlur && this.props.onBlur(event);
  };

  private handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.props.onChange && this.props.onChange(event);
    this.props.valueChanged &&
      this.props.valueChanged(event.currentTarget.value);
  };

  private runSizeAdapters = () => {
    this.setState(
      {
        isResizeAllowed: this.isResizeAllowed(),
        isAutoResizeAllowed: this.isAutoResizeAllowed(),
      },
      this.adaptHeightIfNeeded
    );
  };

  private debouncedRunSizeAdapters = debounce(this.runSizeAdapters, 200);

  private adaptHeightIfNeeded = () => {
    const textAreaContainer = this.textAreaContainerRef.current;
    const textArea = textAreaContainer
      ? (textAreaContainer.firstElementChild as HTMLElement)
      : null;
    const resizableContainer = this.resizableRef.current;
    if (textArea) {
      this.defaultSize.height = this.getDefaultHeight();

      if (this.state.isAutoResizeAllowed) {
        textArea.style.height = "";
        const newHeight = Math.max(
          textArea.scrollHeight,
          this.defaultSize.height
        );
        textArea.style.height = newHeight + "px";
        this.setState({ height: newHeight });
      } else if (
        this.props.isResizable &&
        !this.isResized &&
        resizableContainer
      ) {
        this.setState({ height: this.defaultSize.height }, () => {
          resizableContainer.updateSize(this.defaultSize);
        });
      } else {
        this.setState({ height: this.defaultSize.height });
      }
    }
  };

  private onTextareaResize = () => {
    if (!this.isResized) {
      document.addEventListener("click", this.handleOutsideClick);
      addClass(this.componentRef.current!, "c-textarea--is-resized");
      this.isResized = true;
    }

    const textAreaContainer = this.textAreaContainerRef.current;
    const resizableContainer = this.resizableRef.current;

    if (textAreaContainer && resizableContainer) {
      const resizableContainerWidth = parseInt(
        resizableContainer.sizeStyle.width
      );
      const defaultWidth = parseInt(this.defaultSize.width);

      const textAreaContainerHeight = textAreaContainer.offsetHeight;
      const defaultHeight = this.defaultSize.height;

      if (
        resizableContainerWidth <= defaultWidth &&
        textAreaContainerHeight <= defaultHeight
      ) {
        this.resetResizableContainer();
      }
    }
  };

  private handleOutsideClick = (event: any) => {
    const textAreaContainer = this.textAreaContainerRef.current;

    if (textAreaContainer && !textAreaContainer.contains(event.target)) {
      this.resetResizableContainer();
    }
  };

  private resetResizableContainer = () => {
    const resizableContainer = this.resizableRef.current;
    const componentContainer = this.componentRef.current;

    if (resizableContainer && componentContainer) {
      resizableContainer.updateSize(this.defaultSize);

      this.isResized = false;
      document.removeEventListener("click", this.handleOutsideClick);
      removeClass(componentContainer, "c-textarea--is-resized");

      this.props.resetSizeCallback && this.props.resetSizeCallback();
    }
  };

  private isResizeAllowed = (): boolean =>
    Boolean(this.props.isResizable && !isSmallDeviceWidth());

  private isAutoResizeAllowed = (): boolean => {
    const { autoResize, autoResizeMobile } = this.props;
    const isMobile = isSmallDeviceWidth();

    return Boolean(
      !this.isResizeAllowed() && (autoResize || (autoResizeMobile && isMobile))
    );
  };

  private getDefaultHeight = () => {
    const { height } = this.props;
    const defaultStylesHeight = parseInt(ownStyles.textAreaHeight);
    if (height) {
      if (isSmallDeviceWidth()) {
        return height.sm || height.md || height.lg || defaultStylesHeight;
      } else if (isMediumDeviceWidth()) {
        return height.md || height.lg || defaultStylesHeight;
      } else {
        return height.lg || defaultStylesHeight;
      }
    } else {
      return defaultStylesHeight;
    }
  };
}
