import { Helmet } from "react-helmet";
import config from "../../config";

/**
 * Injects the cookie script banner into the head of the document
 * if the cookieScriptSrc is defined and the showCookieScriptQueryParamKey is not set to false
 *
 * CS can be hidden if the showCookieScriptQueryParamKey(param itself is in octo var) is passed and set to false
 *
 * @returns {JSX.Element | null} - The cookie script banner
 */
const InjectedCookieScriptBanner = () => {
  const shouldNotInject =
    !config.cookieScriptSrc || process.env.NODE_ENV !== "production";
  if (shouldNotInject) return null;

  const urlParams = new URLSearchParams(window.location.search);
  const shouldshowCookieScriptQueryParamKey = urlParams.has(
    config.showCookieScriptQueryParamKey
  )
    ? urlParams.get(config.showCookieScriptQueryParamKey)
    : "true";

  if (!JSON.parse(shouldshowCookieScriptQueryParamKey!)) return null;

  return (
    <Helmet>
      <script src={config.cookieScriptSrc} type="text/javascript" />
    </Helmet>
  );
};

export default InjectedCookieScriptBanner;
