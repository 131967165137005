import React from "react";

import { Content } from "../../InboxRow";

import "./InboxRowTags.scss";

export interface IInboxRowTagsProps {
  tags: Content[];
}

export const InboxRowTags: React.FC<IInboxRowTagsProps> = ({ tags }) => (
  <div className="c-inbox-row-tags">
    {tags.map((tag, index) => (
      <div className="c-inbox-row-tags__item" key={index}>
        {tag}
      </div>
    ))}
  </div>
);
