// @ts-nocheck
import React from "react";
import moment from "moment";
import cx from "classnames";
import { v4 as uuid } from "uuid";
import "./SimpleGrid.scss";
import { GridStyles } from "./GridStyles";
import { HeaderCell } from "./HeaderCell";
import { ActionHeaderCell } from "./ActionHeaderCell";
import {
  DEFAULT_COLUMN_RELATIVE_WIDTH,
  HEADER_HEIGHT,
  HIDE,
  WidthRange,
} from "./SimpleGridConfig";
import { IHelperProps } from "../Helper/Helper";
import MobileDataCell from "./MobileDataCell";
import { isSmallDeviceWidth } from "../../../generics/dom-extensions";
import { dataTestIds } from "data-testids";

export interface ISimpleGridColumn<T> {
  /**
   * Type of the column. Date strings get parsed into 'DD/MM/YYYY HH:mm' format and should be passed as ISO8601
   */
  type: "string" | "number" | "date" | "link";
  /**
   * For 'date' types, optional string to pass to Moment.js so it can reformat to the desired spec.
   * Defaults to 'DD/MM/YYYY HH:mm' if not passed.
   */
  format?: string;
  /**
   * Label to show
   */
  label?: string;
  /**
   * Column sort value, if defined
   */
  sortType?: "asc" | "desc";
  /**
   * Integer number specifying the relative width of the column with respect to the others. Default is 1 if not specified.
   */
  width?: WidthRange; // force int up to reasonable granularity. Extend if needed.
  /**
   * Any click event handler
   */
  onClick?: () => void;
  /**
   * Defines format function for cell
   */
  formatCell?: (value: any, row: T) => string | React.ReactNode;
  helper?: IHelperProps;
}

export type ISimpleGridColumns<T> = {
  [K in keyof T]: ISimpleGridColumn<T> | typeof HIDE;
};

export interface ISimpleGridProps<T> {
  /**
   * Data set for the grid to show, of any data model T.
   */
  data: T[];
  /**
   * Header for the grid columns. You MUST import and use ISimpleGridColumns<T> when declaring these.
   * It helps by throwing compile errors when header keys don't match the keys of the data model.
   * Typescript doesn't enforce this properly otherwise.
   * Declaring props with the ISimpleGridProps interface is not enough to enforce this. Please see SimpleGridExample.tsx.
   */
  columns: ISimpleGridColumns<T>;
  /**
   * Maximum height of the grid, e.g. "300px".
   * If the number of result rows exceeds that height, they will scroll within the grid's box, keeping the header fixed to the top of the element.
   */
  maxHeight: string;
  /**
   * Optional callback for scrolling the table
   */
  onScroll?: () => void;
  /**
   * Optional callback for hitting the bottom of the table
   */
  scrolledToBottom?: () => void;
  /**
   * Optional class name for the top-level wrapper
   */
  className?: string;
  /**
   * Styles for customization
   */
  styles?: GridStyles;
  mobileVariant?: "single-column" | "double-column";
}

export class SimpleGrid<T> extends React.Component<ISimpleGridProps<T>> {
  private tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  private tableHeaderRef: React.RefObject<HTMLTableSectionElement>;

  public constructor(props: ISimpleGridProps<T>) {
    super(props);

    this.tableBodyRef = React.createRef<HTMLTableSectionElement>();
    this.tableHeaderRef = React.createRef<HTMLTableSectionElement>();
  }

  private getColumnKeys = () =>
    Object.keys(this.props.columns).filter(
      (k) => this.props.columns[k] !== HIDE
    );

  private getColumnWidthsSum = (): number => {
    return this.getColumnKeys().reduce((accumulator, k) => {
      const columnWidth = this.props.columns[k].width;

      if (columnWidth) {
        return accumulator + columnWidth;
      }

      return accumulator + DEFAULT_COLUMN_RELATIVE_WIDTH;
    }, 0);
  };

  private trackScrolling = () => {
    if (this.props.onScroll) {
      this.props.onScroll();
    }

    const body = this.tableBodyRef.current;

    if (this.props.scrolledToBottom && body && body.children) {
      if (
        Math.floor(
          (
            body.children.item(body.children.length - 1) as HTMLElement
          ).getBoundingClientRect().bottom
        ) <= Math.floor(body.getBoundingClientRect().bottom)
      ) {
        this.props.scrolledToBottom();
      }
    }
  };

  public render() {
    const { styles = new GridStyles(), columns } = this.props;
    const isScreenSizeSmall = isSmallDeviceWidth();

    return (
      <div
        className={cx("c-simple-grid", this.props.className, {
          "c-simple-grid__single-column":
            this.props.mobileVariant === "single-column" && isScreenSizeSmall,
          "c-simple-grid__double-column":
            this.props.mobileVariant === "double-column" && isScreenSizeSmall,
        })}
      >
        <table className="c-simple-grid__table">
          <thead
            className={`c-simple-grid__table-header ${styles.header}`}
            ref={this.tableHeaderRef}
          >
            <tr
              className={`c-simple-grid__table-header-row ${styles.headerRow}`}
              style={{
                gridTemplateColumns: `repeat(${this.getColumnWidthsSum()}, 1fr)`,
              }}
            >
              {this.getColumnKeys().map((k) => (
                <th
                  className={cx(
                    `c-simple-grid__table-header-cell ${styles.headerCell}`,
                    {
                      "c-simple-grid__table-header-cell__clickable":
                        columns[k].onClick,
                    }
                  )}
                  key={uuid()}
                  style={{
                    gridColumn: `span ${
                      columns[k].width || DEFAULT_COLUMN_RELATIVE_WIDTH
                    }`,
                  }}
                >
                  {columns[k].onClick ? (
                    <ActionHeaderCell headerCell={columns[k]} styles={styles} />
                  ) : (
                    <HeaderCell
                      headerCell={columns[k]}
                      styles={styles}
                      helper={columns[k].helper}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className={`c-simple-grid__table-body ${styles.body}`}
            ref={this.tableBodyRef}
            style={{
              maxHeight: `calc(${this.props.maxHeight} - ${HEADER_HEIGHT}px)`,
            }}
            onScroll={this.trackScrolling}
          >
            {this.props.data.map((row) => (
              <tr
                className={`c-simple-grid__table-body-row ${styles.bodyRow}`}
                key={uuid()}
                style={{
                  gridTemplateColumns: `repeat(${this.getColumnWidthsSum()}, 1fr)`,
                }}
                data-testid={
                  dataTestIds.componentLibrary[
                    "Organisms.SimpleGrid.TableBody.Row"
                  ]
                }
              >
                {Object.keys(this.props.columns)
                  .filter((k) => this.props.columns[k] !== HIDE)
                  .map((k) => {
                    let data = row[k];

                    if (data === undefined || data === null) {
                      data = "";
                    } else if (this.props.columns[k].type === "date") {
                      data = moment(data).format(
                        this.props.columns[k].format || "DD/MM/YYYY HH:mm"
                      );
                    } else if (this.props.columns[k].type === "link") {
                      data = <a href={data.href}>{data.text}</a>;
                    }

                    return this.props.mobileVariant && isScreenSizeSmall ? (
                      <MobileDataCell
                        label={this.props.columns[k].label}
                        value={data}
                        className={styles.bodyCell}
                      />
                    ) : (
                      <td
                        className={`c-simple-grid__table-body-row-cell ${styles.bodyCell}`}
                        data-testid={
                          dataTestIds.componentLibrary[
                            "Organisms.SimpleGnrid.TableBody.RowCell"
                          ]
                        }
                        key={uuid()}
                        style={{
                          gridColumn: `span ${
                            this.props.columns[k].width ||
                            DEFAULT_COLUMN_RELATIVE_WIDTH
                          }`,
                        }}
                      >
                        {this.props.columns[k].formatCell
                          ? this.props.columns[k].formatCell(data, row)
                          : data}
                      </td>
                    );
                  })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
