import {
  authAxios as axios,
  authorizationService,
  UserAttributes,
} from "shared-auth";
import { config } from "component-library/src/config";

export default async function getFilmmakersSSOLink(redirectUrl: string) {
  const portalGuid = authorizationService.getUserAttribute(
    UserAttributes.MasterPortalGuid
  );

  const { data } = await axios.get(
    `${config.profileApiUrl}/profiles/${portalGuid}/filmmakers-sso-link`,
    {
      params: {
        redirectUrl,
      },
    }
  );

  return data;
}
