import _map from "lodash/map";
import { ILocation } from "shared-types";
import { IGoogleLocation } from "shared-services";

// everything upper 100 is sub-levels of locations
const FULL_LOCATION_POSITION = 100;

export function getPerformersLocations(
  performerLocations: ILocation[],
  locationsData: IGoogleLocation[]
) {
  const selectedPerformerLocationIds = _map(
    performerLocations.filter(
      (location) => location.position < FULL_LOCATION_POSITION
    ),
    "locationId"
  );
  return _map(
    locationsData.filter((location) =>
      selectedPerformerLocationIds.includes(location.googlePlaceId)
    ),
    "value"
  );
}
