import React from "react";
import {
  INotificationToastProps,
  NotificationToast,
} from "../../Atoms/NotificationToast/NotificationToast";

import "./WithNotificationToast.scss";

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export const WithNotificationToast: React.FC<
  Omit<INotificationToastProps, "stickToComponent">
> = (props) => {
  return (
    <div className="c-with-notification-toast">
      {props.children}
      <NotificationToast {...props} stickToComponent={true} />
    </div>
  );
};
