import React from "react";
import { IMultiSelectItem } from "../MultiSelectItem";
import { ITooltipContent } from "../../../Molecules/Tooltip/Tooltip";
import { IOption } from "../../../../generics/interfaces";
import { DropdownInput } from "../../../Molecules/DropdownInput/DropdownInput";
import { Helper } from "../../Helper/Helper";

export interface IMultiSelectListContent {
  label: string;
  placeholder: string;
  tooltip?: ITooltipContent;
}

interface IMultiSelectDropdownProps<TValue> {
  list: IMultiSelectItem<TValue>[];
  name: string;
  selectedItems: IMultiSelectItem<TValue>[];
  content: IMultiSelectListContent;
  invalid?: boolean;
  validationMessage?: string;
  selectItem: (item: IMultiSelectItem<TValue>) => void;
}

export function MultiSelectDropdown<TValue>(
  props: IMultiSelectDropdownProps<TValue>
) {
  const {
    list,
    name,
    selectedItems,
    content,
    selectItem,
    invalid,
    validationMessage,
  } = props;

  const optionList: Array<IOption<IMultiSelectItem<TValue>>> = list
    .filter(
      (item) => !selectedItems.some((selected) => selected.value === item.value)
    )
    .map((item) => ({ name: item.name, value: item }));

  const compareFn = (
    left: IMultiSelectItem<TValue>,
    right: IMultiSelectItem<TValue>
  ) => left.value === right.value;
  const valueChanged = (value?: IMultiSelectItem<TValue>) =>
    value && selectItem(value);

  return (
    <DropdownInput
      id={`multiSelect${name}`}
      name={name}
      variant="select"
      placeholder={content.placeholder}
      options={optionList}
      invalid={invalid}
      validationMessage={validationMessage}
      valueChanged={valueChanged}
      valueComparer={compareFn}
      truncateOptions={true}
      label={content.label}
      annex={
        content.tooltip && (
          <Helper
            {...{
              tooltip: {
                texts: content.tooltip,
                config: {
                  minimalTopDistance: 50,
                },
              },
            }}
          />
        )
      }
    />
  );
}
