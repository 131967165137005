import React from "react";

import { ReactComponent as ArrowSelect } from "../../../images/svg/icons/arrow-select.svg";
import { ReactComponent as ArrowSelectDown } from "../../../images/svg/icons/arrow-select-down.svg";
import { ReactComponent as ArrowSelectUp } from "../../../images/svg/icons/arrow-select-up.svg";

import { HEADER_HEIGHT } from "./SimpleGridConfig";
import { GridStyles } from "./GridStyles";
import { ISimpleGridColumn } from "./SimpleGrid";

interface IActionHeaderCell<T> {
  /**
   * The column header cell to render
   */
  headerCell: ISimpleGridColumn<T>;
  /**
   * Styles for customization
   */
  styles: GridStyles;
}

export const ActionHeaderCell = <T,>(props: IActionHeaderCell<T>) => {
  const { headerCell, styles } = props;

  const onClickHeaderCell =
    (callback?: () => void) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      callback?.();
    };

  return (
    <a
      className={`c-simple-grid__table-header-cell-clickable-space ${styles.headerLabel}`}
      href="#"
      onClick={onClickHeaderCell(headerCell.onClick)}
      style={{ height: `${HEADER_HEIGHT}px` }}
    >
      <span
        className={`c-simple-grid__table-header-cell-clickable-space-title ${styles.headerTitle}`}
      >
        {headerCell.label}
      </span>
      <ActionHeaderArrow
        sortType={headerCell.sortType}
        arrowStyles={`c-simple-grid__table-header-cell-sort-icon ${styles.headerSortIcon}`}
      />
    </a>
  );
};

interface IActionHeaderArrow {
  /**
   * Column sort value, if defined
   */
  sortType?: "asc" | "desc";
  /**
   * Common styling for each ActionHeaderArrow
   */
  arrowStyles: string;
}

const ActionHeaderArrow = ({ sortType, arrowStyles }: IActionHeaderArrow) => {
  if (sortType && sortType === "desc") {
    return <ArrowSelectDown className={arrowStyles} />;
  } else if (sortType && sortType === "asc") {
    return <ArrowSelectUp className={arrowStyles} />;
  } else {
    return <ArrowSelect className={arrowStyles} />;
  }
};
