import {
  HALManager,
  HALResources,
  SettingManager,
  UserClaimTypes,
  UserManager,
  UserRoleManager,
  UserRoles,
} from "core";
import React from "react";
import { matchPath, RouteComponentProps } from "react-router-dom";

import { parseQuery, parseToUrlQuery } from "shared/utils/http";
import { ICancelablePromise, makeCancelable } from "shared/utils/promise";

import { Profile, Settings, WhoAmI } from "models";

import ProfileService from "services/ProfileService";
import { authorizationService } from "shared-auth";
import { captureExceptionEvent } from "shared-services";

const VIEW_PIN_PATTERN = /\d{4}-\d{4}-\d{4}\/?$/;

const SPOTLIGHT_MEMBER_GROUPS_CLAIM = "spotlight.MemberGroups";

interface IAuthenticationState {
  isLoaded: boolean;
}

export declare type WithUserInfoProps = RouteComponentProps;

export default function withUserInfo<P extends WithUserInfoProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IAuthenticationState> {
    public readonly state: Readonly<IAuthenticationState> = { isLoaded: false };
    private whoAmIPromise: ICancelablePromise<WhoAmI>;
    private profilePromise: ICancelablePromise<Profile>;
    private settingPromise: ICancelablePromise<Settings>;

    public render() {
      const { isLoaded } = this.state;
      return isLoaded ? <WrappedComponent {...this.props} /> : null;
    }

    public async componentDidMount() {
      try {
        await this.initUserData();

        this.setState({ isLoaded: true });
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    }

    public componentWillUnmount() {
      this.whoAmIPromise && this.whoAmIPromise.cancel();
      this.profilePromise && this.profilePromise.cancel();
      this.settingPromise && this.settingPromise.cancel();
    }

    private initUserData = async () => {
      const viewPinMatch = window.location.pathname.match(VIEW_PIN_PATTERN);
      const isCasting = UserRoleManager.isInRole(UserRoles.castingDirector);

      this.extractClaimsFromToken();

      if (!UserManager.userId) {
        try {
          await this.loadUser();
        } catch (error) {
          // if on profilenotfound page then ignore loading user failing
          const currentRoute = this.props.history.location.pathname;
          const matchProfileNotFound = matchPath(currentRoute, {
            path: "/profilenotfound",
          });

          if (!matchProfileNotFound) {
            throw error;
          }
        }
      }

      if (isCasting) {
        this.setAdditionalCastingClaims();
      } else if (!viewPinMatch) {
        await this.loadProfile();
      }
    };

    private loadProfile = async () => {
      this.settingPromise = makeCancelable(ProfileService.getSettings());
      this.profilePromise = makeCancelable(ProfileService.getProfile());

      try {
        const setting = await this.settingPromise.promise;

        SettingManager.settings = setting;
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }

      try {
        const profile = await this.profilePromise.promise;
        this.updateUserProfileData(profile);
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    };

    private loadUser = async () => {
      this.whoAmIPromise = makeCancelable(ProfileService.whoAmI());

      try {
        const user = await this.whoAmIPromise.promise;

        this.setUser(user);
      } catch (error) {
        captureExceptionEvent(
          error,
          authorizationService.getDecodedUserToken()
        );
      }
    };

    private setUser = (user: WhoAmI) => {
      const { type, id } = user;

      UserManager.setClaim(UserClaimTypes.role, type);
      id && UserManager.setClaim(UserClaimTypes.userId, id);

      HALManager.setHalFor(
        HALResources.getProfile,
        HALManager.getProfileByProfileIdUrl(UserManager.userId)
      );
    };

    private updateUserProfileData = (profile: Profile) => {
      const { forename, surname, viewPin, artistType } = profile;

      UserManager.setClaim(
        UserClaimTypes.profileFullName,
        `${forename} ${surname}`
      );
      UserManager.setClaim(UserClaimTypes.viewPin, viewPin);
      artistType &&
        UserManager.setClaim(UserClaimTypes.artistType, artistType.toString());

      HALManager.setHalFor(
        HALResources.getGallery,
        HALManager.getGalleryByProfileIdUrl(UserManager.userId)
      );
      HALManager.setHalFor(
        HALResources.patchGallery,
        HALManager.getGalleryByProfileIdUrl(UserManager.userId)
      );
      HALManager.setHalFor(
        HALResources.getMainPhoto,
        HALManager.getMainPhotoByProfileIdUrl(UserManager.userId)
      );
    };

    private setAdditionalCastingClaims = () => {
      const {
        location: { search, pathname },
        history,
      } = this.props;

      const { disabilities, gender, ...queryObject } = parseQuery(search);

      if (Boolean(disabilities)) {
        UserManager.setClaim(UserClaimTypes.isDisabilitiesVisible, "true");
      }

      if (Boolean(gender)) {
        UserManager.setClaim(UserClaimTypes.isGenderVisible, "true");
      }

      history.replace(pathname + parseToUrlQuery(queryObject));
    };

    private extractClaimsFromToken = () => {
      const userToken = authorizationService.getUserToken();

      if (userToken) {
        const decodedToken = authorizationService.getDecodedUserToken<{
          sub: string;
          profile_id: string;
        }>();

        if (decodedToken) {
          const userGroupClaim = decodedToken[SPOTLIGHT_MEMBER_GROUPS_CLAIM];
          UserManager.setClaim(UserClaimTypes.role, userGroupClaim || "");

          if (decodedToken.profile_id) {
            UserManager.setClaim(
              UserClaimTypes.userId,
              decodedToken.profile_id
            );
          }

          HALManager.setHalFor(
            HALResources.getProfile,
            HALManager.getProfileByProfileIdUrl(UserManager.userId)
          );
        }
      }
    };
  };
}
