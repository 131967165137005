import React, { FC, ReactNode, useState } from "react";
import _uniqBy from "lodash/uniqBy";
import { LocationService } from "shared-services";
import { config } from "../../../config";
import { getUserLocalizationPreference } from "../../Organisms/LocalizationOrganism/LocalizationUtils";
import { IOption } from "../../../generics/interfaces";
import { DropdownInput } from "../DropdownInput/DropdownInput";
import { RecordWithActionControls } from "../RecordWithActionControls/RecordWithActionControls";
import { ActionButton } from "../../Atoms/ActionButton/ActionButton";
import LoadingIndicator from "../../Atoms/LoadingIndicator/LoadingIndicator";

interface Props {
  name: string;
  onChange: (value: IOption<string> | IOption<string>[]) => void;
  value: IOption<string> | IOption<string>[];
  validationMessage?: string;
  isMultiple?: boolean;
  isFormSubmitted?: boolean;
  invalid?: boolean;
  label?: ReactNode;
  placeholder?: string;
  limit?: number;
}

const language = getUserLocalizationPreference().language;
const locationService = new LocationService({
  url: config.locationsApiUrl,
});

const LocationsSearch: FC<Props> = ({
  name,
  onChange,
  value,
  label,
  placeholder,
  validationMessage,
  invalid,
  isFormSubmitted,
  isMultiple,
  limit,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const isMultipleMode = isMultiple && Array.isArray(value);

  const fetchOptions = async (search: string): Promise<IOption<string>[]> => {
    const result = await locationService.searchLocation(search, language);

    return result.map((location) => ({
      name: location.description,
      value: location.place_id,
    }));
  };

  const onSelectItem = async (locationId: string = "") => {
    if (!locationId) return;
    setLoading(true);

    const result = await locationService.getLocationByGooglePlaceIds(
      locationId,
      language
    );

    const resultOption = { value: result.id, name: result.name };

    if (isMultipleMode) {
      onChange(_uniqBy([...value, resultOption], "value"));
    }

    if (!isMultipleMode) {
      onChange(resultOption);
    }

    setLoading(false);
  };

  const onRemoveItem = async (locationId: string = "") => {
    if (isMultipleMode) {
      onChange(value.filter((item) => item.value !== locationId));
    }
  };

  if (isMultipleMode) {
    return (
      <div>
        {((limit && value.length < limit) || !limit) && (
          <DropdownInput
            name={name}
            label={label}
            placeholder={placeholder}
            invalid={invalid}
            validationMessage={validationMessage}
            isFormSubmitted={isFormSubmitted}
            variant="autocomplete"
            options={fetchOptions}
            valueChanged={onSelectItem}
            withSearchIcon
            withRemoveSearchTextIcon
            wrappedOptions
          />
        )}

        <div className="g-bg-secondary">
          {value.map(({ name, value }) => (
            <RecordWithActionControls
              key={value}
              title={name}
              controls={[
                <ActionButton
                  key={`${value}-delete`}
                  icon="delete"
                  onClick={() => onRemoveItem(value)}
                  size="small"
                />,
              ]}
            />
          ))}
          {loading && (
            <RecordWithActionControls>
              <LoadingIndicator variant="tertiary" />
            </RecordWithActionControls>
          )}
        </div>
      </div>
    );
  }

  return (
    <DropdownInput
      name={name}
      label={label}
      placeholder={placeholder}
      invalid={invalid}
      validationMessage={validationMessage}
      isFormSubmitted={isFormSubmitted}
      variant="autocomplete"
      options={fetchOptions}
      valueChanged={onSelectItem}
      selected={value as IOption<string>}
    />
  );
};

export default LocationsSearch;
