import React from "react";
import { FileRejection, useDropzone, DropzoneOptions } from "react-dropzone";
import { v4 as uuid } from "uuid";
import cx from "classnames";
import { InputWrapper, ERROR } from "../../Atoms/InputsCommon/InputWrapper";
import LoadingIndicator from "../../Atoms/LoadingIndicator/LoadingIndicator";
import { ReactComponent as UploadIcon } from "../../../images/svg/icons/upload.svg";
import "./DropzoneUploader.scss";

export interface IDropzoneUploaderTexts {
  dropzoneLabel: string;
}

export interface IDropzoneUploaderFile {
  id: string;
  file: File;
}

export interface IDropzoneUploaderProps extends DropzoneOptions {
  texts: IDropzoneUploaderTexts;
  /**
   * Fired after adding files that have passed internal validation
   * note: internal validation based on props: accept, minSize, maxSize
   */
  onDropAcceptedFiles: (acceptedFiles: IDropzoneUploaderFile[]) => void;
  /** Fired after adding files that have not passed internal validation */
  onDropRejectedFiles: (rejectedFiles: IDropzoneUploaderFile[]) => void;
  /** Hide the component */
  isHidden?: boolean;
  /**
   * Sets the state of validity
   * note: used in conjunction with InputWrapper component
   */
  invalid?: boolean;
  /**
   * Sets the validation message
   * note: used in conjunction with InputWrapper component
   */
  validationMessage?: string;
  /** Additional css class for the topmost div */
  className?: string;
  maxFiles?: number;
  loading?: boolean;
}

export const DropzoneUploader: React.FC<IDropzoneUploaderProps> = (props) => {
  const {
    texts,
    isHidden,
    multiple,
    accept,
    minSize,
    maxSize,
    invalid,
    validationMessage,
    className,
    maxFiles,
    loading,
  } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const onDropAccepted = React.useCallback(
    (files: File[]) => {
      const acceptedFiles: IDropzoneUploaderFile[] = Array.from(files).map(
        (file) => ({ id: uuid(), file })
      );
      props.onDropAcceptedFiles(acceptedFiles);
    },
    [props.onDropAcceptedFiles]
  );

  const onDropRejected = React.useCallback(
    (filesRejections: FileRejection[]) => {
      const rejectedFiles: IDropzoneUploaderFile[] = Array.from(
        filesRejections
      ).map(({ file, errors }) => ({
        id: uuid(),
        file,
        errors,
      }));
      props.onDropRejectedFiles(rejectedFiles);
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple,
    accept,
    minSize,
    maxSize,
    maxFiles,
  });

  return isHidden ? null : (
    <div
      className={cx("c-dropzone-uploader", className)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <InputWrapper
        invalid={invalid ? ERROR : undefined}
        validationMesssage={validationMessage}
      >
        <div
          className={cx("c-dropzone-uploader__dropzone", {
            "c-dropzone-uploader__dropzone--error": invalid,
            "c-dropzone-uploader__dropzone--focus": isFocused,
          })}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            aria-label={texts.dropzoneLabel}
            disabled={loading}
          />
          <div className="c-dropzone-uploader__text">
            {loading ? (
              <LoadingIndicator variant="secondary" />
            ) : (
              <div className="flex items-center gap-2">
                {texts.dropzoneLabel}
                <UploadIcon width={30} height={30} />
              </div>
            )}
          </div>
        </div>
      </InputWrapper>
    </div>
  );
};
