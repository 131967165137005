import React, { FC, useEffect } from "react";
import { useQuery } from "react-query";
import { MediaType } from "shared-types";
import { getMediaData, getMediaDataByArtistRef } from "shared-services";
import { PopUpMediaViewer, SplashSpinner } from "../../../index";

interface Props {
  profileId?: string;
  artistRef?: string;
  mediaType: MediaType;
  onClose: () => void;
}

const MediaViewer: FC<Props> = ({
  profileId,
  artistRef,
  mediaType,
  onClose,
}) => {
  const { data, isLoading, remove } = useQuery(
    ["get-performer-media", profileId, mediaType],
    async () => {
      if (profileId) {
        const { media } = await getMediaData(mediaType, profileId);
        return media;
      }

      if (artistRef) {
        const { media } = await getMediaDataByArtistRef(mediaType, artistRef);
        return media;
      }
    }
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [profileId, mediaType, artistRef]);

  if (isLoading) return <SplashSpinner isOpen />;

  return (
    <PopUpMediaViewer
      isOpen={Boolean(data)}
      mediaType={mediaType}
      mediaList={data || []}
      onClose={onClose}
    />
  );
};

export default MediaViewer;
