import React, { useEffect, useState } from "react";
import { Location } from "history";
import { RouteComponentProps } from "react-router-dom";
import { UserClaimTypes, UserManager } from "core";

import { Head } from "component-library";

const VIEW_PIN_PATTERN = /\d{4}-\d{4}-\d{4}$/;

declare type WithPageTrackingProps = RouteComponentProps;

export default function withPageData<P extends WithPageTrackingProps>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> {
  return (props: P) => {
    const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {
      const setTitle = ({ pathname }: Location<any>) => {
        const clearPath = pathname.replace("/profile", "").replace(/\/$/, "");
        const page = GET_PAGE_TITLE(clearPath);
        const title = page ? page.name : "Profile";
        setPageTitle(title);
      };

      setTitle(props.location);
      props.history.listen(setTitle);
    }, [props.location.pathname]);

    return (
      <>
        <Head>
          <title>{pageTitle}</title>
        </Head>
        <WrappedComponent {...props} />
      </>
    );
  };
}

const GET_PAGE_TITLE = (path) => {
  const fullName = UserManager.getClaimWithDefault(
    UserClaimTypes.profileFullName
  );

  const PAGES = [
    {
      name: `${fullName}`,
      isValid: (path: string) => path === "/" || path === "",
    },
    {
      name: `${fullName}`,
      isValid: (path: string) => path.match(VIEW_PIN_PATTERN),
    },
    {
      name: "Profile - Photo Tool",
      isValid: (path: string) => path === "/photos",
    },
    {
      name: "Profile - Credits",
      isValid: (path: string) => Boolean(path.match("credit")),
    },
    {
      name: "Profile - Basic Information",
      isValid: (path: string) => path === "/details",
    },
    {
      name: "Profile - About Me",
      isValid: (path: string) => path === "/aboutme",
    },
    {
      name: "Profile - Training",
      isValid: (path: string) => path === "/training",
    },
    {
      name: "Profile - Skills",
      isValid: (path: string) => path === "/skills",
    },
    {
      name: "Profile - Edit Contacts",
      isValid: (path: string) => path === "/contacts",
    },
    {
      name: "Profile - Contacts Help",
      isValid: (path: string) => path === "/contacts/help",
    },
    {
      name: "Profile - Add Contact",
      isValid: (path: string) =>
        path === "/contacts/add" || path === "/contacts/new",
    },
    {
      name: "Profile - Edit Links",
      isValid: (path: string) => path === "/links",
    },
    {
      name: "Profile - Link to my profile",
      isValid: (path: string) => path === "/linktoprofile",
    },
    {
      name: "Profile - Edit video",
      isValid: (path: string) => path === "/media/video",
    },
    {
      name: "Profile - Add video clip",
      isValid: (path: string) => path === "/media/video/new",
    },
    {
      name: "Profile - Edit video clip",
      isValid: (path: string) => Boolean(path.match("media/video/edit")),
    },
    {
      name: "Profile - Edit audio",
      isValid: (path: string) => path === "/media/audio",
    },
    {
      name: "Profile - Add audio clip",
      isValid: (path: string) => path === "/media/audio/new",
    },
    {
      name: "Profile - Edit audio clip",
      isValid: (path: string) => Boolean(path.match("media/audio/edit")),
    },
  ];

  return PAGES.find((page) => page.isValid(path));
};
