import React from "react";
import _values from "lodash/values";
import {
  CheckboxGroup,
  ICheckboxGroupValue,
} from "../../../../CheckboxGroup/CheckboxGroup";
import { facetToCheckboxOption } from "../../../../../Functional/converters/facetConverter";
import {
  DropdownCheckbox,
  IDropdownCheckboxValueProps,
} from "../../../../../Molecules/DropdownCheckbox/DropdownCheckbox";
import { IFilterValues, IGetFilterFieldConfig } from "../../../types";
import { getCheckboxFacetTags } from "../../../helpers/getCheckboxFacetTags";

export function getCheckboxGroupFilterConfigWithFacets({
  name,
  facetNames,
  values,
  filterDrawerValues,
  setFilterValues,
  setFilterDrawerValues,
  facets,
  modifiers = {},
  t,
  dataTestId,
}: IGetFilterFieldConfig<IFilterValues>) {
  const fieldValue = values[name] as ICheckboxGroupValue;
  const fieldFacets = (facetNames && facets[facetNames[0]]) || [];
  const fieldOptions = fieldFacets.map(facetToCheckboxOption);
  const filterDrawerValue = filterDrawerValues[name];
  const filterTitle = t(`common:search.filters.title.${name}`);

  const filterControlComponent = modifiers?.showFilterControl ? (
    <DropdownCheckbox
      ValueComponent={
        DropdownCheckbox.getDefaultValueComponent({
          selectionPlaceholder: filterTitle,
          selectionPrefix: "",
        }) as React.ComponentType<IDropdownCheckboxValueProps>
      }
      checkboxGroupConfig={{
        name,
        options: fieldOptions,
      }}
      value={values[name] as ICheckboxGroupValue}
      valueChanged={(value) => {
        setFilterValues({
          [name]: value,
        });
      }}
      key={name}
      data-testid={dataTestId}
    />
  ) : null;

  const filterDrawerConfig = {
    title: filterTitle,
    id: name,
    selected: _values(filterDrawerValue).some((v) => v),
    renderFilterContent: () => (
      <CheckboxGroup
        config={{
          name,
          options: fieldOptions,
        }}
        value={filterDrawerValue as ICheckboxGroupValue}
        valueChanged={(value) => {
          setFilterDrawerValues({
            [name]: value,
          });
        }}
      />
    ),
  };

  const tags = getCheckboxFacetTags({
    name,
    fieldValue,
    fieldFacets,
    getText: (name, tagName) =>
      t(`common:search.filters.tagLabel.${name}`, { tagName }),
    getOnClick: (name, fieldValue, value) => () => {
      setFilterValues({
        [name]: { ...fieldValue, [value]: false },
      });
    },
  });

  return {
    tags,
    filterDrawerConfig,
    filterControlComponent,
  };
}
