import {
  User,
  UserManager as OidcUserManager,
  WebStorageStateStore,
} from "oidc-client";
import { jwtDecode } from "jwt-decode";
import {
  MemberGroups,
  AddOnEntitlements,
  PremiumEntitlements,
} from "shared-types";
import config from "../../config";
import {
  HeapService,
  captureExceptionEvent,
  initBraze,
  InitializeBrazeProps,
} from "shared-services";
import { UserAttributes } from "./constants";
import { checkAndSetPortalCookie } from "../../utils/set-portal-cookie";

const OIDC_SIGNIN_REDIRECT_URL_PATH = "signin-oidc";
const SPOTLIGHT_MEMBER_GROUPS_CLAIM = "spotlight.MemberGroups";

interface IConfiguration {
  oidc: {
    authority: string;
    clientId: string;
    scope: string;
  };
  frontEndUrl: string;
}

class AuthorizationService {
  public user?: User;
  private userManager: OidcUserManager;
  private config: IConfiguration;
  private heap: HeapService;

  constructor(configuration: IConfiguration) {
    const { frontEndUrl, oidc } = configuration;
    this.config = configuration;

    this.userManager = new OidcUserManager({
      authority: oidc.authority,
      client_id: oidc.clientId,
      scope: oidc.scope,
      response_type: "code",
      redirect_uri: `${frontEndUrl}/signin-oidc`,
      post_logout_redirect_uri: frontEndUrl,
      userStore: new WebStorageStateStore({ store: localStorage }),
    });
    this.heap = new HeapService(oidc.authority);

    // Add an event handler in to capture the token expired
    this.userManager.events.addAccessTokenExpired(async () => {
      await this.trySilentSignInThenRedirect();
    });

    this.userManager.events.addAccessTokenExpiring(async () => {
      await this.signInSilent();
    });
  }

  public captureException(e: any, methodName: string) {
    captureExceptionEvent(e, this.getDecodedUserToken(), {
      methodName,
    });
  }

  public async trySilentSignInThenRedirect() {
    try {
      await this.signInSilent();
    } catch (e) {
      this.captureException(e, "trySilentSignInThenRedirect");
      // The silent sign in failed, so lets redirect them to try and sign in again
      this.signInRedirect();
    }
  }

  public isSignInUrl(): boolean {
    const { redirect_uri = "" } = this.userManager.settings;
    return Boolean(redirect_uri.match(window.location.pathname + "$"));
  }

  public signInRedirect(provider?: string) {
    try {
      let params: { [key: string]: string } = {};

      if (provider) {
        params = { ...params, acr_values: `idp:${provider}` };
      }

      this.userManager.signinRedirect(params);

      const currentUrl = window.location.href;
      if (currentUrl.toLowerCase().indexOf(OIDC_SIGNIN_REDIRECT_URL_PATH) < 0) {
        sessionStorage.setItem("oidc.redirectUri", window.location.href);
      }
    } catch (e) {
      this.captureException(e, "signInRedirect");
    }
  }

  public async signinRedirectCallback() {
    try {
      await this.userManager.signinRedirectCallback();
      await this.getUser();

      const redirectUri = sessionStorage.getItem("oidc.redirectUri");
      window.location.assign(redirectUri || this.config.frontEndUrl);
    } catch (e) {
      this.captureException(e, "signinRedirectCallback");
    }
  }

  public async signInSilent() {
    const user = await this.userManager.signinSilent();

    this.setUserInfo(user);
  }

  public async getUser() {
    try {
      const user = await this.userManager.getUser();
      this.setUserInfo(user);

      if (user) {
        this.heap.setUserDetails();
      }
      return user;
    } catch (e) {
      this.captureException(e, "getUser");
    }
  }

  public initBrazeForAuthorizedUser = async () => {
    const user = await this.getUser();

    if (user) {
      const brazeProps: InitializeBrazeProps = {
        memberGroupFromToken: user.profile["spotlight.MemberGroups"],
        portalId: user.profile.sub,
        brazeConfig: {
          apiKey: config.braze.apiKey,
          baseUrl: config.braze.baseUrl,
        },
      };
      initBraze(brazeProps);
    }
  };

  public isAuthenticated() {
    return Boolean(this.user?.access_token && !this.user.expired);
  }

  public getStoredUser(): User | undefined {
    return this.user;
  }

  public signOutRedirect() {
    try {
      this.userManager.signoutRedirect();
      this.userManager.clearStaleState();
      sessionStorage.clear();
    } catch (e) {
      this.captureException(e, "signOutRedirect");
    }
  }

  public getUserToken = () => (this.user ? this.user.access_token : "");

  public getDecodedUserToken = <T>() => {
    const token = this.getUserToken();
    return token ? jwtDecode<T>(token) : ({} as T);
  };

  public getUserAttribute = (attr: UserAttributes) => {
    try {
      if (this.user) {
        return (
          this.getDecodedUserToken<{ [attr: string]: any }>()[attr] ||
          this.getStoredUser()?.profile[attr]
        );
      }
    } catch (e) {
      this.captureException(e, "getUserAttribute");
    }
  };

  public getAuthorizationHeader = () => {
    const token = this.getUserToken();
    return token ? `Bearer ${token}` : "";
  };

  public getUserGroup = () => {
    try {
      if (this.user) {
        const { [SPOTLIGHT_MEMBER_GROUPS_CLAIM]: groupClaim = "" } = jwtDecode<{
          [SPOTLIGHT_MEMBER_GROUPS_CLAIM]: string;
        }>(this.getUserToken());

        const [mainGroup] = String(groupClaim).split(",");

        return mainGroup || MemberGroups.anonymous;
      }
    } catch (e) {
      this.captureException(e, "getUserGroup");
    }

    return MemberGroups.anonymous;
  };

  private setUserInfo = (user: User | null) => {
    try {
      this.user = user!;

      if (user?.access_token) {
        checkAndSetPortalCookie(user.profile.sub);
      }
    } catch (e) {
      this.captureException(e, "setUserInfo");
    }
  };

  public async signoutPortalRedirect() {
    try {
      await this.userManager.clearStaleState();
      await this.userManager.removeUser();
      window.location.assign(config.portalApiLogoutUrl);
    } catch (e) {
      this.captureException(e, "signoutPortalRedirect");
    }
  }

  public doesUserHaveEntitlements(
    feature: PremiumEntitlements | AddOnEntitlements
  ): boolean {
    try {
      const token = this.getUserToken();

      if (!token) {
        return false;
      }

      const decodedToken: any = jwtDecode(token);
      const isAgent = this.getUserGroup() === MemberGroups.agent;

      if (isAgent) {
        return true;
      }

      const userEntitlements = decodedToken["entitlements"];
      const parsedEntitlements = JSON.parse(userEntitlements || "[]");

      if (parsedEntitlements.length === 0) {
        return false;
      }

      return parsedEntitlements.some(
        (entitlement: string) => entitlement === feature
      );
    } catch (e) {
      this.captureException(e, "doesUserHaveEntitlements");
      return false;
    }
  }

  public addUserSignedOutEvent(handler: () => void) {
    this.userManager.events.addUserSignedOut(handler);
  }

  public removeUserSignedOutEvent() {
    this.userManager.events.removeUserSignedOut(() => undefined);
  }

  public hasRequestedScopes(userScopes: string[] | undefined) {
    if (!userScopes) {
      return false;
    }

    const requiredScopesString = this.userManager.settings.scope?.trim() || "";
    const requiredScopes =
      requiredScopesString.length == 0 ? [] : requiredScopesString.split(/ /gi);

    return requiredScopes.every((scope) => userScopes.includes(scope));
  }
}

export default new AuthorizationService({
  oidc: config?.oidc || {},
  frontEndUrl: config.frontEndUrl,
});
