declare const __CONFIG__: {
  oidc: {
    authority: string;
    clientId: string;
    scope: string;
  };
  frontEndUrl: string;
  portalApiLogoutUrl: string;
  whoAmIUrl: string;
  profileApiUrl: string;
  titaniumApiUrl: string;
  cookieScriptSrc: string;
  showCookieScriptQueryParamKey: string;
  launchDarkly: {
    clientSideID: string;
  };
  sentry: {
    dsn: string;
  };
};

export default {
  ...__CONFIG__,
};
