export default class BasePhoto {
  public id: string;
  public url: string;
  public isHidden: boolean;
  public isPreviouslyMain: boolean;
  public userFriendlyId: string;
  public _links?: {
    self?: {
      href: string;
    };
    patchPhoto?: {
      href: string;
    };
    makeMain?: {
      href: string;
    };
    photographers?: {
      href: string;
      templated: boolean;
    };
  };
}
