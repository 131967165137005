import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import cx from "classnames";
import { useRecurly } from "@recurly/react-recurly";
import { RecurlyError } from "@recurly/recurly-js/lib/error";
import { TokenPayload } from "@recurly/recurly-js/lib/token";
import {
  useDynamicWindowWidthFor,
  useExternalScript,
  useMobileKeyboardVisibility,
  useScrollToElement,
} from "shared-hooks";
import { useTranslation } from "i18n";
import SummaryBlock from "../SummaryBlock";
import {
  DISABILITY_DISCOUNT_CODE_ACC_LEVEL,
  RECURLY_JS_URL,
} from "../../constants";
import { Spinner } from "../../../../Atoms/Spinner/Spinner";
import { BillingUser } from "translations/src/models/billing";
import StickyCTABar from "../../../StickyCTABar";
import {
  CheckoutFlow,
  CommonErrorPopup,
  SCREEN_WIDTH_ENUM,
} from "../../../../../index";
import { billingService } from "../../services";
import FailedBanner from "../FailedBanner";
import usePreventLeaveBillingPage from "../../hooks/usePreventLeaveBillingPage";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingContext } from "../../BillingContext";
import { useGetSubscriptions } from "../../hooks/useGetSubscriptions";
import { useGetSummary } from "../../hooks/useGetSummary";
import { ISubscription, ISummary, ITokenPayload } from "../../types";
import useBillingFormValues from "../../hooks/useBillingFormValues";
import useBillingFormValidation from "../../hooks/useBillingFormValidation";
import { useCpaOptIn } from "../../hooks/useCpaOptIn";
import getInvalidCardFields from "../../utils/getInvalidCardFields";
import ThreeDSecurePage from "../ThreeDSecurePage";
import useCouponCodes from "../../hooks/useCouponCode";
import useReinitializeCardForm from "../../hooks/useReinitializeCardForm";
import RecurlyWrapper from "../RecurlyWrapper";
import { getPurchaseType } from "../../utils/getPurchaseType";
import "../SinglePageCheckoutNew/styles.scss";
import CDSubscriptionBlock from "./CDSubscriptionBlock";
import CDBillingForm from "./CDBillingForm";
import { useCanAccessCheckout } from "../../hooks/useCanAccessCheckout";

const TOOLTIP_CONTAINER = "TOOLTIP_CONTAINER";

interface Props {
  subscriptions: ISubscription[];
  summary: ISummary;
}

const getCouponCodeForPurchase = (
  coupons: string[],
  hasAccountDiscount: boolean,
  shouldReturnArray: boolean
) => {
  const hasAccountDiscountAndCoupon = hasAccountDiscount && coupons.length > 1;

  if (hasAccountDiscountAndCoupon) {
    const couponsLessAccountDiscount = coupons.filter(
      (coupon) => coupon !== DISABILITY_DISCOUNT_CODE_ACC_LEVEL
    );
    return shouldReturnArray
      ? couponsLessAccountDiscount
      : couponsLessAccountDiscount[0];
  }

  if (hasAccountDiscount) {
    return shouldReturnArray ? [] : "";
  }

  return shouldReturnArray ? coupons : coupons[0];
};

const SinglePageCheckoutNew: FC<Props> = ({ subscriptions, summary }) => {
  const { t } = useTranslation();
  const {
    postCheckoutCallback,
    successCallback,
    goBack,
    goBackText,
    checkoutText,
    navigateToCompletePage,
    planTypeFilter,
    useExistingPaymentMethod,
    checkoutFlow,
    billingUserType,
  } = useContext(BillingContext);
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const mobileKeyboardIsOpen = useMobileKeyboardVisibility(isMobileView);
  const { ref: containerRef, scrollToElement: scrollToHeaderContainer } =
    useScrollToElement();
  const [plan, setPlan] = useState<ISubscription | undefined>();
  const [isFailed, setIsFailed] = useState<boolean>();
  const [formSubmissionState, setFormSubmissionState] = useState<{
    isSubmitted: boolean;
    isSubmitting: boolean;
  }>({
    isSubmitted: false,
    isSubmitting: false,
  });
  const [recurlyToken, setRecurlyToken] = useState<string>("");
  const [threeDSToken, setThreeDSToken] = useState<string>("");
  const [cardDetailError, setCardDetailError] = useState<
    Record<string, string>
  >({});
  const { mutate: optInCpa } = useCpaOptIn();
  const [isFormOpen, setIsFormOpen] = useState<boolean>(isMobileView);
  const htmlFormRef = useRef<HTMLFormElement>(null);
  const recurly = useRecurly();
  const setPreventLeave = usePreventLeaveBillingPage();
  const {
    texts: {
      common,
      billingForm,
      paymentDetailForm,
      directDebitForm,
      validation,
    },
  } = useBillingTranslations(BillingUser.Common);

  const { formValues, setFieldValue } = useBillingFormValues();
  const validationMessages = useBillingFormValidation(
    formValues,
    { ...billingForm, ...directDebitForm },
    validation,
    plan?.planType
  );

  const isMobileViewAndFormOpen = isMobileView && isFormOpen;

  const {
    price,
    userCouponCodesObject,
    userCouponCodesStrings,
    hasAccountDiscount,
    couponErrors,
    setCoupon,
    removeCoupon,
    isCheckoutPreviewRequestLoading,
  } = useCouponCodes(plan?.id, summary.discounts);
  const { cardFormIsVisible, reinitializeForm } = useReinitializeCardForm();
  const { ref: sectionRef, scrollToElement } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });
  const { ref: errorRef, scrollToElement: scrollToError } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });

  const history = useHistory();
  const [isRenewalLoading, setIsRenewalLoading] = useState(false);
  const [renewalError, setRenewalError] = useState("");

  useEffect(() => {
    if (planTypeFilter) {
      setPlan(undefined);
      setIsFormOpen(false);
    }
  }, [planTypeFilter]);

  const couponBag = {
    hasAccountDiscount,
    userCouponCodesObject,
    setCoupon,
    removeCoupon,
    couponErrors,
    isCheckoutPreviewRequestLoading,
  };

  const formErrors = {
    ...(formSubmissionState.isSubmitted ? validationMessages : {}),
    ...cardDetailError,
  };

  const formWrapperClassName = cx("flex justify-between mb-10", {
    "flex-col": !isFormOpen,
  });

  const failurePurchaseAction = useCallback(() => {
    setThreeDSToken("");
    setIsFailed(true);
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: false,
    });
    scrollToError();
    reinitializeForm();
  }, [setThreeDSToken, setIsFailed, scrollToError, reinitializeForm]);

  const trackingData = {
    memberType: billingUserType,
    purchaseType: getPurchaseType(checkoutFlow),
    paymentPeriod: plan?.code,
    paymentValue: plan?.amount,
    discountValue: price?.discount,
    promoCode: userCouponCodesStrings,
  };

  const purchase = async (token: string, threeDsToken?: string) => {
    try {
      const result = await billingService.purchase({
        token,
        ...(threeDsToken && { threeDsToken }),
        planId: plan?.id || "",
        couponCodes: getCouponCodeForPurchase(
          userCouponCodesStrings,
          hasAccountDiscount,
          true
        ) as string[],
      });

      if (result?.data?.RecurlyError || result?.RecurlyError) {
        failurePurchaseAction();
      }

      if (result?.threeDSActionRequired && result?.actionTokenId) {
        setRecurlyToken(token);
        setThreeDSToken(result.actionTokenId);
        return;
      }

      if (result?.tokenId) {
        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId: plan?.id,
              planCurrency: plan?.currency,
              couponCodes: getCouponCodeForPurchase(
                userCouponCodesStrings,
                hasAccountDiscount,
                true
              ),
            },
            trackingData
          );
        }

        optInCpa(!!plan?.autoRenewEnabled);

        setPreventLeave(false);

        if (successCallback) {
          successCallback();
        }
      }

      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });

      if (result?.tokenId) {
        navigateToCompletePage?.(false);
      }
    } catch (e) {
      failurePurchaseAction();
    }
  };

  const removeInvalidCardError = useCallback(
    (field) => {
      setCardDetailError((values) => _omit(values, field));
    },
    [setCardDetailError]
  );

  const tokenHandler =
    (failedCallback?: () => void) =>
    async (err: RecurlyError | null, token: TokenPayload) => {
      if (err) {
        setFormSubmissionState({
          isSubmitted: true,
          isSubmitting: false,
        });
        setCardDetailError(
          getInvalidCardFields(
            err.fields || [],
            {
              ...paymentDetailForm,
              ...directDebitForm,
              zipLabel: billingForm.zipLabel,
            },
            validation
          )
        );

        failedCallback && failedCallback();
      } else {
        await purchase(token.id);
      }
    };

  const submitForm = async (e?: SyntheticEvent<any>) => {
    setIsFailed(false);
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: true,
    });

    e?.preventDefault();

    if (useExistingPaymentMethod && checkoutFlow === CheckoutFlow.Default) {
      setIsRenewalLoading(true);
      const planId = plan?.id ?? "";
      const couponCodes = getCouponCodeForPurchase(
        userCouponCodesStrings,
        hasAccountDiscount,
        true
      ) as string[];

      try {
        const renewalData = await billingService.renewSubscriptionWithExisting({
          planId,
          couponCodes,
          addOns: [],
        });

        if (!renewalData.accountId) {
          setRenewalError("Something went wrong. Please try again.");
          return;
        }

        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId,
              planCurrency: plan?.currency,
              couponCodes,
              planTypeFilter,
            },
            trackingData
          );
        }

        optInCpa(!!plan?.autoRenewEnabled);

        setIsRenewalLoading(false);

        if (navigateToCompletePage) {
          navigateToCompletePage(false);
          return;
        } else {
          history.push("/myhome");
        }
      } catch {
        setRenewalError("Something went wrong. Please try again.");
        return;
      }
    }

    if (!_isEmpty(validationMessages) || !_isEmpty(cardDetailError)) {
      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });
      return;
    }

    recurly.token(htmlFormRef.current as HTMLFormElement, tokenHandler());
  };

  const submitThreeDSecure = useCallback(
    async (threeDSToken: ITokenPayload) => {
      await purchase(recurlyToken, threeDSToken.id);
    },
    [recurlyToken, purchase]
  );

  const handlePlanSelect = (subscription: ISubscription) => {
    setPlan(subscription);
    setIsFormOpen(true);
  };

  const handleBackButton = () => {
    if (isMobileViewAndFormOpen) {
      setIsFormOpen(false);
      scrollToHeaderContainer();
      return;
    }

    if (goBack) {
      goBack();
    }
  };

  const handleContinueButton = (e: SyntheticEvent<Element, Event>) => {
    if (isMobileView && !!plan && !isFormOpen) {
      setIsFormOpen(true);
      scrollToHeaderContainer();
      return;
    }

    return submitForm(e);
  };

  const getBackBtnText = () => {
    if (isMobileViewAndFormOpen) {
      return common.backMobile;
    }

    if (goBackText) {
      return goBackText;
    }

    return common.back;
  };

  const getCheckoutBtnText = () => {
    if (isMobileView && !isFormOpen) {
      return common.continueMobile;
    }

    if (useExistingPaymentMethod) {
      return t("common:button.label.continue");
    }

    if (checkoutText) {
      return checkoutText;
    }

    return common.submit;
  };

  const closePopUp = () => setRenewalError("");

  if (isRenewalLoading) return <Spinner />;

  if (renewalError) {
    return (
      <CommonErrorPopup
        errorStatusCode={400}
        popupProps={{
          close: closePopUp,
        }}
        alertMessageProps={{
          buttons: [
            {
              name: t("common:button.label.close"),
              type: "primary",
              click: closePopUp,
            },
          ],
        }}
      />
    );
  }

  if (threeDSToken && !isFailed)
    return (
      <ThreeDSecurePage
        threeDSToken={threeDSToken}
        submitThreeDSecure={submitThreeDSecure}
        onThreeDSecureError={failurePurchaseAction}
        threeDSClassName="h-[800px] m-auto w-full md:w-[400px]"
      />
    );

  return (
    <div
      ref={containerRef}
      className="checkout-single-page"
      id={TOOLTIP_CONTAINER}
    >
      {isFailed && <FailedBanner onClick={scrollToElement} ref={errorRef} />}
      {isMobileViewAndFormOpen && plan ? (
        <SummaryBlock
          planName={plan.name}
          summary={summary}
          price={price}
          couponBag={couponBag}
          isMobileView={isMobileView}
          disclaimerMessage="common:billing.checkout.orderSummary.continuousAuthorityAgreement"
        />
      ) : (
        <CDSubscriptionBlock
          subscriptions={subscriptions}
          selectedSubscriptionId={plan?.id}
          selectSubscription={handlePlanSelect}
        />
      )}
      <div className={formWrapperClassName}>
        <CDBillingForm
          isFormOpen={isFormOpen}
          formErrors={formErrors}
          submitForm={submitForm}
          billingFormRef={htmlFormRef}
          sectionRef={sectionRef}
          setFieldValue={setFieldValue}
          isSubmitted={formSubmissionState.isSubmitted}
          tootlipContainerConst={TOOLTIP_CONTAINER}
          formValues={formValues}
          cardDetailError={cardDetailError}
          removeInvalidCardError={removeInvalidCardError}
          isMobileView={isMobileView}
          cardFormIsVisible={cardFormIsVisible}
          planType={plan?.planType}
        />
        {isFormOpen && !isMobileView && plan && (
          <SummaryBlock
            planName={plan.name}
            summary={summary}
            price={price}
            couponBag={couponBag}
            isMobileView={isMobileView}
            disclaimerMessage={
              plan.autoRenewEnabled
                ? "common:billing.checkout.orderSummary.continuousAuthorityAgreement"
                : "common:billing.checkout.orderSummary.nonRenewingContinuousAuthorityAgreement"
            }
          />
        )}
      </div>
      <StickyCTABar
        prev={{
          text: getBackBtnText(),
          onClick: handleBackButton,
        }}
        next={{
          text: getCheckoutBtnText(),
          onClick: handleContinueButton,
          disabled: isMobileView
            ? !plan || formSubmissionState.isSubmitting
            : !isFormOpen || formSubmissionState.isSubmitting,
        }}
        isSticky={!mobileKeyboardIsOpen}
        ctaBarAdditionalClassName="max-w-full"
      />
    </div>
  );
};

export default ({ isRenewalReactivation = false }) => {
  const [loaded] = useExternalScript(RECURLY_JS_URL);
  const { isLoading: canAccessCheckoutLoading } = useCanAccessCheckout(
    isRenewalReactivation,
    "/myhome"
  );

  const { data: summary, isLoading: summaryLoading } = useGetSummary();
  const { data: subscriptions, isLoading: subscriptionLoading } =
    useGetSubscriptions();

  if (!Array.isArray(subscriptions)) return null;

  if (
    !loaded ||
    subscriptionLoading ||
    summaryLoading ||
    canAccessCheckoutLoading
  )
    return <Spinner />;

  return (
    <RecurlyWrapper>
      <SinglePageCheckoutNew subscriptions={subscriptions} summary={summary} />
    </RecurlyWrapper>
  );
};
