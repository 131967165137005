import React from "react";
import { withRouter } from "react-router-dom";

import { withGlobalContextProvider } from "shared/contexts/GlobalContext";
import compose from "shared/utils/compose";

import { withChangesTracker } from "contexts/ChangesTrackingContext";
import { withFlowContextProvider } from "contexts/FlowContext";
import { withOidcAuthContext } from "contexts/OidcAuthContext";

import { withTranslation as withTranslationConsumer } from "./contexts/TranslationContext";
import withAnonymousAccess from "./hoc/withAnonymousAccess";
import withErrorCatcher from "./hoc/withErrorCatcher";
import withGlobalLoader from "./hoc/withGlobalLoader";
import withGoogleAnalytics from "./hoc/withGoogleAnalytics";
import withIdentification from "./hoc/withIdentification";
import withOidcAuthentication from "./hoc/withOIdcAuthentication";
import withPageData from "./hoc/withPageData";
import withQueryActionHandling from "./hoc/withQueryActionHandling";
import withTranslation from "./hoc/withTranslation";
import withUserInfo from "./hoc/withUserInfo";
import MasterPage from "./MasterPage/MasterPage";

import "./App.scss";

export const App: React.FC = () => <MasterPage />;

export default compose(
  withOidcAuthContext,
  withChangesTracker,
  withRouter,
  withGlobalContextProvider,
  withGlobalLoader,
  withTranslation,
  withTranslationConsumer,
  withErrorCatcher,
  withAnonymousAccess,
  withIdentification,
  withOidcAuthentication,
  withUserInfo,
  withFlowContextProvider,
  withGoogleAnalytics,
  withPageData,
  withQueryActionHandling
)(App);
