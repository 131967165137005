import { useState, useCallback } from "react";

export interface IUseModal {
  visible: boolean;
  show: () => void;
  close: () => void;
  toggle: () => void;
}

export default function useModal(): IUseModal {
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(false);
  }, []);

  const toggle = useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  return {
    visible,
    show,
    close,
    toggle,
  };
}
