import React from "react";

const PageContent = ({ children }: { children: React.ReactNode }) => (
  <div className="flex justify-center flex-1">
    <div className="flex flex-col w-full px-2 max-w-7xl md:px-4">
      {children}
    </div>
  </div>
);

export default PageContent;
